import "../style/edicion-forms.css";

import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";

import { MyContext } from "../components/context/Context";
import UserPool from "../userPool";

function EdicionTelefono() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getSession } = useContext(MyContext);
  const [formData, setFormData] = useState({
    telefono: "",
    idUser: sessionStorage.getItem("idUser"),
  });

  const [numeroTelefono, setNumeroTelefono] = useState();
  const [formatoValido, setFormatoValido] = useState(true);
  const [username, setUsername] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (numeroTelefono === 10) {
      console.log(`formato tel valido`);
      setFormatoValido(true);
      updatePhoneNumber(formData.telefono);
    } else {
      console.log(`formato invalido`);
      setFormatoValido(false);
    }
  };

  const updatePhoneNumber = async (newPhoneNumber) => {
    if (username) {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });

      const phoneNumberData = {
        Name: "phone_number",
        Value: `+52${newPhoneNumber}`,
      };
      const phoneNumberAttribute = new CognitoUserAttribute(phoneNumberData);

      cognitoUser.getSession(function (err, session) {
        if (err || !session.isValid()) {
          console.error("La sesión no es válida o no se pudo obtener:", err);
          return;
        }

        cognitoUser.updateAttributes(
          [phoneNumberAttribute],
          async (err, result) => {
            if (err) {
              console.error("Error al actualizar el número de teléfono:", err);
            } else {
              console.log("Número de teléfono actualizado con éxito:", result);
              navigate("/codigo-telefono", {
                state: { telefono: newPhoneNumber },
              });
            }
          }
        );
      });
    } else {
      console.log("El nombre de usuario no está definido.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const nuevoNumero = event.target.value;
    console.log(nuevoNumero.length);
    setNumeroTelefono(nuevoNumero.length);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getSession().then((session) => {
      console.log(session);
      setUsername(session.user.username);
    });
  }, []);

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="form-container">
          <h6>N&uacute;mero celular</h6>
          <form className="form-content" onSubmit={handleSubmit}>
            <label>N&uacute;mero celular</label>
            <input
              id="telefono"
              type="number"
              name="telefono"
              placeholder="10 d&iacute;gitos"
              value={formData.telefono}
              onChange={handleChange}
              onInput={(e) => {
                e.preventDefault();
                const maxLength = 10;
                const inputValue = e.target.value;
                if (inputValue.length > maxLength) {
                  e.target.value = inputValue.slice(0, maxLength);
                }
              }}
            ></input>
            {formatoValido ? null : (
              <p className="error">
                El formato del telefono no es v&aacute;lido
              </p>
            )}
            <button className="form-button" type="submit">
              Guardar
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EdicionTelefono;
