import React from "react";
import { Link } from "react-router-dom";
import cocteles from "../../../images/datosPersonales/cocteles-y-maridajes/receta-cocteles.png";
import maridajes from "../../../images/datosPersonales/cocteles-y-maridajes/receta-maridajes.png";
import "./cocteles-maridajes.css";
import { MyContext } from "../../../components/context/Context";
import { useContext } from "react";
import { NavBar } from "../../../components/NavBar";
import { Footer } from "../../../components/Footer";

function CoctelesyMaridajes() {
  const { registrado } = useContext(MyContext);

  return (
    <>
      <NavBar />
      <div className="datos-envio w-100 d-flex">
        <div className="container container__cocteles_maridajes row justify-content-center">
          <div
            className={
              registrado
                ? "header__cocteles_maridajes"
                : "header__cocteles_maridajes w-75"
            }
          >
            <h3>C&oacute;cteles y maridajes</h3>
            <p>
              {/* texto para cocteles y maridajes */}
            </p>
          </div>
          <Link
            className="container__item col-12 col-md-5 col-lg-5"
            to="/cocteles"
          >
            <img className="img-fluid rounded" src={cocteles} alt="" />
            <p className="overlay-text texto-negro">C&oacute;cteles</p>
          </Link>
          <Link
            className="container__item col-12 col-md-5 col-lg-5"
            to="/maridajes"
          >
            <img className="img-fluid rounded" src={maridajes} alt="" />
            <p className="overlay-text texto-negro">Maridajes</p>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CoctelesyMaridajes;
