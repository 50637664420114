import React, { useState} from 'react';
import styled from "styled-components";


//icons

import restriccion_boton_si from "../images/restriccion/botonSi.png";
//Imagenes
import restriccion_desktop from "../images/restriccion/restriccion-desktop-fondo.png";

import img_logo_dorado from "../images/logo-dorado-black.svg";
//Componentes
const RestriccionEdad = styled.div`
background: url(${restriccion_desktop});

background-size: cover;
position:relative;

`;
const urlTerminos = '/terminos';

export function Restriccion({ setActivo }) {
    const [show, setShow] = useState(true);
    
    const handleModalClose = (e) => {
        setShow(false);
        setActivo(true);
        sessionStorage.setItem("activo", true);
       
    };
    const recarga = (e) => {
        // window.location.reload();
        window.location.href = 'https://www.google.com';
    };

    function dirigirTerminos(e) {
        e.preventDefault();
        window.open(urlTerminos, '_blank');
    }


    return (
        <>
            <RestriccionEdad hidden={!show} className="contenedor-restriccion">
                <div className="modal-background" >
                    <div className="container"  >
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 " >
                                <center>
                                    <img style={{ marginTop: "5%" }} className="logo-restriccion" src={img_logo_dorado} alt="" height="100%" />
                                    <div className="restriccion">
                                        <span className='text-negro titulo-restriccion-dos'>Bienvenido</span><br />
                                        <span className='text-negro titulo-restriccion'>¿Tienes m&aacute;s de 18 años?</span>
                                        <div style={{ marginTop: "50px" }}>
                                            <button className="btn-rectangulo-principal btn bg-negro  texto-blanco"
                                                type="button"
                                                style={{  marginLeft: "20px", height: "45px" }}
                                                onClick={recarga}>
                                                No
                                            </button>
                                            <button className="btn-rectangulo-principal2 btn bg-dorado  texto-blanco"
                                                type="button"
                                                style={{  height: "45px", marginLeft: "40px" }}
                                                onClick={handleModalClose}>
                                                 {/* <input className="img-flecha" type="image" src={restriccion_boton_si} height="35" width="80"/>  */}
                                                Sí
                                            </button>

                                        </div><br />
                                        <div style={{ marginTop: "30px" }}>
                                            <span className='terminos-restriccion'>
                                                Al ingresar a este sitio, acepto los
                                            </span>
                                            <a style={{ marginLeft: "4px" }} href="#" onClick={dirigirTerminos} >
                                                <span className='texto-dorado terminos-restriccion'>
                                                    T&eacute;rminos y Condiciones.
                                                </span>
                                            </a>
                                        </div>

                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </RestriccionEdad>

        </>)

}