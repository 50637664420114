import React, { useEffect, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import UserPool from "../../userPool";
import showPassword from "../../images/showPassword.svg";
import hidePassword from "../../images/hidePassword.svg";

import "./style.css";

function Signup() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    caracteresEspeciales: false,
  });
  const [validationMessage, setValidationMessage] = useState(false);
  const [passTest, setPassTest] = useState();
  const [passAllTests, setPassAllTests] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  function onSubmit(data) {
    console.log(data);
    const fechaNacimiento = `${data.anioNacimiento}-${data.mesNacimiento}-${data.diaNacimiento}`;
    // Validar que sea mayor de edad (18 años)
    const fechaNacimientoDate = new Date(fechaNacimiento);
    const hoy = new Date();
    let edad = hoy.getFullYear() - fechaNacimientoDate.getFullYear();
    const mes = hoy.getMonth() - fechaNacimientoDate.getMonth();

    if (
      mes < 0 ||
      (mes === 0 && hoy.getDate() < fechaNacimientoDate.getDate())
    ) {
      edad--;
    }

    if (edad <= 17) {
      setError("Debes ser mayor de 18 años para registrarte");
      return;
    }

    signUp(data);
  }

  function registrarAmigo(data) {
    const URL =
      "https://api.mezcaldonhesiquio.com/altaAmigoDonHesiquio";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        nombres: data.nombres,
        apellidos: data.apellidos,
        fechaNacimiento: `${data.anioNacimiento}-${data.mesNacimiento}-${data.diaNacimiento}`,
        email: data.email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.access == true) {
          navigate("/ConfirmarCodigo", {
            state: { email: data.email, password: password },
          });
        }
      })
      .catch((err) => {
        console.log(
          "Hubo un problema al obtener sus datos, favor de contactar al equipo de soporte"
        );
        console.log(err);
      });
  }

  function signUp(data) {
    UserPool.signUp(data.email, password, [], null, (err, result) => {
      if (err) {
        err.code === "UsernameExistsException" ? setError("Ya existe una cuenta con este correo electrónico") : setError("Por favor valide los datos del formulario")
        console.error(err);
        
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        return;
      }
      registrarAmigo(data);
    });
  }

  function handleChangePromociones(e) {
    console.log(e.target.checked);
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Establecer los criterios de la contraseña
    setPasswordCriteria({
      length: newPassword.length >= 8 && newPassword.length <= 64,
      lowercase: /[a-z]/.test(newPassword),
      uppercase: /[A-Z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      caracteresEspeciales: /[@#$&?*]/.test(newPassword),
    });
  };

  useEffect(() => {
    console.log(passwordCriteria);
    let passBool = [];
    for (let propiedad in passwordCriteria) {
      // console.log(propiedad, passwordCriteria[propiedad])
      if (passwordCriteria[propiedad] == true) {
        passBool.push(passwordCriteria[propiedad]);
        setPassTest(passBool);
        if (passBool.length === 5) {
          setPassAllTests("✅");
          console.log("Pass all tests");
        } else {
          setPassAllTests(
            <p className="text-danger">
              La contrase&ntilde;a no cumple con los criterios
            </p>
          );
        }
      }
    }
    console.log(passBool);
  }, [passwordCriteria]);

  return (
    <>
      <NavBar />
      <div className="datos-envio p-4">
        <div className="container">
          <div className="row justify-content-center">
            <h3 className="unete-amigos">&Uacute;nete a los amigos</h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="login-form_container col-lg-4 col-xs-12 p-4 "
            >
              {error && (
                <div className="alert alert-danger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-exclamation-circle alert-icon"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0" />
                  </svg>
                  <span className="alert-text">{error}</span>
                </div>
              )}
              <label className="">Nombre(s)</label>
              <input
                type="text"
                className="form-control"
                {...register("nombres", {
                  required: "Ingresa tu(s) nombre(s)",
                })}
              />
              {errors.nombres && (
                <p className="text-danger">{errors.nombres.message}</p>
              )}
              <label className="">Apellido(s)</label>
              <input
                type="text"
                className="form-control"
                {...register("apellidos", {
                  required: "Ingresa tu(s) apellido(s)",
                })}
              />
              {errors.apellidos && (
                <p className="text-danger">{errors.apellidos.message}</p>
              )}
              <label className="">Fecha de nacimiento</label>
              <div className="d-flex gap-4 align-items-center">
                <input
                  type="text"
                  placeholder="DD"
                  className="form-control text-center"
                  {...register("diaNacimiento", {
                    required: true,
                  })}
                />
                <span>/</span>
                <input
                  type="text"
                  placeholder="MM"
                  className="form-control text-center"
                  {...register("mesNacimiento", {
                    required: true,
                  })}
                />
                <span>/</span>
                <input
                  type="text"
                  placeholder="AAAA"
                  className="form-control text-center"
                  {...register("anioNacimiento", {
                    required: true,
                  })}
                />
              </div>
              {errors.diaNacimiento &&
                errors.mesNacimiento &&
                errors.anioNacimiento && (
                  <p className="text-danger">Ingresa tu fecha de nacimiento</p>
                )}
              <label>Correo electr&oacute;nico</label>
              <input
                type="email"
                className="form-control"
                {...register("email", {
                  required: `Ingresa tu correo electronico`,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: `Ingresa un correo electronico valido`,
                  },
                })}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
              <label className="">Contrase&ntilde;a</label>
              <div className="d-flex align-items-center col-12 contenedor-password">
                <input
                  onChange={handlePasswordChange}
                  type={viewPassword ? "text" : "password"}
                  className="form-control"
                  onFocus={() => setValidationMessage(true)}
                  onBlur={() => setValidationMessage(false)}
                />
                {viewPassword ? (
                  <img
                    src={showPassword}
                    style={{ transform: "translateY(2px)" }}
                    className="view-password"
                    onClick={handleViewPassword}
                  />
                ) : (
                  <img
                    src={hidePassword}
                    alt=""
                    className="view-password"
                    onClick={handleViewPassword}
                  />
                )}
              </div>
              {validationMessage && (
                <div className="row contenedor-texto-validacion">
                  <p className="texto-validacion">
                    Tu contrase&ntilde;a debe contener lo siguiente:
                  </p>
                  <p className="texto-validacion">
                    Entre 8 y 64 caracteres{" "}
                    {passwordCriteria.length ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo una minúscula{" "}
                    {passwordCriteria.lowercase ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo una mayúscula{" "}
                    {passwordCriteria.uppercase ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo 1 número {passwordCriteria.number ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo un caracter especial (@, #, $, &, ?, *){" "}
                    {passwordCriteria.caracteresEspeciales ? "✅" : "❌"}
                  </p>
                </div>
              )}
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
              {passAllTests}
              <div className="row align-items-center gap-2 mt-2 col-12 mx-auto">
                <input
                  type="checkbox"
                  className="col-1"
                  {...register("avisoPrivacidad", {
                    required: "Es necesario aceptar el aviso de privacidad",
                  })}
                />
                <label className="texto-oro col-10">
                  Acepto el aviso de privacidad
                </label>
              </div>
              {errors.avisoPrivacidad && (
                <p className="text-danger">{errors.avisoPrivacidad.message}</p>
              )}
              <div className="row col-12 gap-2 mx-auto mt-2">
                <input
                  type="checkbox"
                  className="col-1"
                  defaultChecked
                  onChange={handleChangePromociones}
                />
                <label className="col-10">
                  Quiero recibir promociones en mi correo
                </label>
              </div>

              <button type="submit" className="mx-auto">
                Crear cuenta
              </button>
            </form>
            <Link to="/login" className="text-center my-4">
              <span className="texto-oro ">
                ¿Ya tienes cuenta? Inicia sesión
              </span>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
