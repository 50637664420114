import React from "react";
import { Modal, CircularProgress, Box } from "@mui/material";
import Loader from "../../images/loader/Rolling-1s-200px-gold.svg";

function ModalCarga({ open }) {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          height: 200,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
        }}
      >
        <img
          src={Loader}
          alt="Descripción de la imagen"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>
    </Modal>
  );
}

export default ModalCarga;
