import React, { createContext, useState, useEffect } from "react";
import { costoEspadin, costoTobala, costoProductoInventario2, costoProductoInventario4, costoProductoInventario3, costoProductoInventario1 } from "../../assets/constants";
import { calculateShippingCost } from "../../assets/functions";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../userPool";

export const MyContext = createContext();

export const MyProvider = ({ children }) => {


  const [habilitoPago, setHabilitoPago] = useState(true);

  const [registrado, setRegistrado] = useState(
    sessionStorage.getItem("loggedIn") || false
  );
  const [itemsMezcal, setItemsMezcal] = useState(
    localStorage.getItem("totalItems")
  );
  const [itemsCarrito, setItemsCarrito] = useState(
    0 || localStorage.getItem("totalItems")
  );
  const [puntosActuales, setPuntosActuales] = useState(
    sessionStorage.getItem("puntos") || 0
  );
  const [checked, setChecked] = useState(
    localStorage.getItem("checked") == true || false
  );
  const [isLocal, setIsLocal] = useState(true);
  const [productFree, setProductFree] = useState(false);
  const [chargueWithPoints, setChargueWithPoints] = useState(false);

  const [total, setTotal] = useState(0);
  const [costoEnvio, setCostoEnvio] = useState(0);
  const [montoDescuento, setMontoDescuento] = useState(0);
  const [montoEnvio, setMontoEnvio] = useState(0);
  const [montoProductos, setMontoProductos] = useState(0);
  
  
  const [descuentoPuntos, setDescuentoPuntos] = useState(0);
  const [userRegistroPuntos, setUserRegistroPuntos] = useState(false);
  const [openCarritoModal, setOpenCarritoModal] = useState(false);

  const getItemsCount = () => {
    const espadinCount = parseInt(localStorage.getItem("1")) || 0;
    const tobalaCount = parseInt(localStorage.getItem("2")) || 0;
    const calaverita = parseInt(localStorage.getItem("4")) || 0;
    const chapulines = parseInt(localStorage.getItem("6")) || 0;
    const jicara = parseInt(localStorage.getItem("3")) || 0;
    const gusanos = parseInt(localStorage.getItem("5")) || 0;
    return {
      espadinCount,
      tobalaCount,
      calaverita,
      chapulines,
      jicara,
      gusanos,
    };
  };

  const applyDiscountWithPoints = () => {
    const valorPorPunto = 0.4;

    const discount = puntosActuales * valorPorPunto;

    return discount;
  };

  useEffect(() => {
    const { espadinCount, tobalaCount, calaverita, chapulines, gusanos, jicara } = getItemsCount();
    let puntosFinales=0;
    let newTotal =0;
    const shippingCost = isLocal
      ? 0
      : calculateShippingCost(espadinCount + tobalaCount + calaverita + chapulines + gusanos + jicara);
    const costoItemsTobala = Math.round(tobalaCount * costoTobala * 100) / 100;
    const costoItemsEspadin =
      Math.round(espadinCount * costoEspadin * 100) / 100;
    const costoItemsCalaverita = Math.round(calaverita * costoProductoInventario2 * 100) / 100;
    const costoItemsChapulines = Math.round(chapulines * costoProductoInventario4 * 100) / 100;
    const costoItemsGusanos = Math.round(gusanos * costoProductoInventario3 * 100) / 100;
    const costoItemsJicara = Math.round(jicara * costoProductoInventario1 * 100) / 100;
    
    const subTotal = costoItemsTobala + costoItemsEspadin + costoItemsCalaverita + costoItemsChapulines + costoItemsGusanos + costoItemsJicara;

    // Aplica el descuento con puntos si el switch está activado (puntos a pesos)
    const discount = checked
      ? applyDiscountWithPoints(espadinCount, tobalaCount, calaverita, chapulines, gusanos, jicara)
      : 0;
 

    const totalSinDescuento = subTotal + shippingCost;
    newTotal = discount>0 ? (totalSinDescuento - discount):totalSinDescuento;
    
    if (newTotal > 0 && discount > 0){
      puntosFinales =0;
      setProductFree(false);
      setChargueWithPoints(true);
    }
    else if (newTotal <= 0 && discount > 0){
      puntosFinales =  Math.round((Math.abs(newTotal)/0.4));
      newTotal=0;
      setProductFree(true);
      setChargueWithPoints(false);

    }
    else{
      setProductFree(false);
      setChargueWithPoints(false);
      puntosFinales=0;
    }



    if (checked && productFree)
      {
        setHabilitoPago(false)
      }
    else if ( !checked && productFree)
        {
          setHabilitoPago(true)
        }
    else if (checked && chargueWithPoints) {
      setHabilitoPago(true)
    }
    else if ( checked && !chargueWithPoints)
        {
          setHabilitoPago(true)
        }
        else{
          setHabilitoPago(true)
        }


    setDescuentoPuntos(puntosFinales);
    setTotal(newTotal);
    setMontoProductos(subTotal);
    setMontoEnvio(shippingCost);
    setMontoDescuento(discount);
  }, [checked, isLocal, puntosActuales, itemsMezcal, itemsCarrito, descuentoPuntos,chargueWithPoints ,habilitoPago ,montoDescuento ,montoProductos + montoEnvio]);

  useEffect(() => {
    if (checked) {
      localStorage.setItem("checked", true);
    } else {
      localStorage.setItem("checked", false);
    }
  }, [checked]);




  const onLogin = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      console.log(authDetails);

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          sessionStorage.setItem("authEmail", authDetails.username);
          resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          //console.log("newPasswordRequired:", data);
          resolve(data);
        },
      });
    });

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              headers: {
                Authorization: token,
              },
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setRegistrado(false);
      localStorage.clear();
      window.location.pathname = "/";
    }
  };

  const value = {
    totalItemsCount: getItemsCount(),
    onLogin,
    getSession,
    logout,
    registrado,
    setRegistrado,
    itemsCarrito,
    setItemsCarrito,
    itemsMezcal,
    setItemsMezcal,
    descuentoPuntos, 
    setDescuentoPuntos,
    puntosActuales,
    setPuntosActuales,
    checked,
    setChecked,
    isLocal,
    setIsLocal,
    total,
    productFree,
    setProductFree,
    chargueWithPoints,
    setChargueWithPoints,
    habilitoPago,
    setHabilitoPago,
    setMontoDescuento,
    montoDescuento ,
    setMontoProductos,
    montoProductos ,
    setMontoEnvio,
    montoEnvio,
    userRegistroPuntos,
    setUserRegistroPuntos, 
    openCarritoModal,
    setOpenCarritoModal
  };
  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};
