import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import img_logo_dorado from "../images/logo-dorado-black.svg";
import casa from "../images/home.svg";
import homeFFF from "../images/homeFFF.svg";
import carritoIcon from "../images/carrito.svg";
import menuButton from "../images/bars-solid-black.svg";
import closeButton from "../images/close-blanco.svg";
import "./StyleNavBar.css";
import { useModalContact } from "../hooks/useModalContact";
import { MyContext } from "./context/Context";


export const NavBarAyuda = ({ carrito = true, activeModal = false }) => {
	const {itemsCarrito} = useContext(MyContext)
	const [ModalContact, openModal, closeModal] = useModalContact();
	const [menuMovil, setMenuMovil] = useState(false);
	const navigate = useNavigate();
	const totalItems = localStorage.getItem("totalItems");

	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);

	const handleScroll = () => {
		const currentScrollPos = window.scrollY;
		if (currentScrollPos < 140) {
			return false;
		} else {
			const isVisible = prevScrollPos > currentScrollPos;
			setVisible(isVisible);
			setPrevScrollPos(currentScrollPos);
		}
	};

	useEffect(() => {
		if (activeModal) {
			openModal();
		}
	}, []);

	return (
		<>
			<header className={`header--nav d-flex align-items-center`} >
				<ModalContact />
				<section className="menu-navegacion--container">
					<nav id="menu-navegador" className="menu-navegacion d-flex justify-content-between">
						<div className="menu-navegacion__img-container">
							<Link to="/">
								<img
									id="logo-inicio"
									src={img_logo_dorado}
									alt="Logo Don Hesiquio"
								/>
							</Link>
						</div>
						<div
							className={`menu-navegacion__links${menuMovil ? " active" : ""}`}
						>
							{menuMovil ? (
								<div className="menu-navegacion__img-container">
								<Link to="/">
									<img
										id="logo-inicio"
										src={img_logo_dorado}
										alt="Logo Don Hesiquio"
									/>
								</Link>
							</div>
							) : null}
							<NavLink className="nav-link" to="/Contacto">
								<span>Ayuda</span>
							</NavLink>
						</div>
					</nav>
				</section>
			</header>
		</>
	);
};
