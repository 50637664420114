import moment from "moment-timezone";

export function formatPrice(price) {
  return price.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  });
}

export function calculateShippingCost(amount) {
  let cost = 0;
  return cost;
}

export function generaIdOrden() {
  moment.tz.setDefault("America/Mexico_City");
  const fechaActual = moment();
  const numeroAleatorio = Math.floor(Math.random() * 1000);
  const fechaFormateada = fechaActual.format("YYYYMMDDTHHmmssSSS");
  const numeroOrden = `MEZ${fechaFormateada}${numeroAleatorio}`;
  console.log(numeroOrden);
  return numeroOrden;
}
export function getDateForValidate() {
  moment.tz.setDefault("America/Mexico_City");
  const fechaActual = moment();
  const fechaFormateada = fechaActual.format("MMYY");
  return fechaFormateada;
}

export function getStatusFactura(estado) {
  return estado ? "SI" : "NO";
}
export function generaDescripcion(compra) {
  /* 
  es importante modificar los nombres de estas variables si se llegan a actualizar/cambiar los productos generales del aplicativo,
  ya que estas definen la descripcion de productos comprados en pantalla de exito, asi como, plantilla de correo para 
  compra exitosa hacia el usuario
  */
  const descripcionItem3 = " Tobalá + Espadín + Kit de coctelería";
  const descripcionItem4 = " Tobalá + Kit de coctelería";
  const descripcionItem5 = " Gorra";
  const descripcionItem6 = " Kit de Coctelería";

  console.log(compra);
  var txtDescripcion = "";
  if (compra.tobala.tobalaItems > 0) {
    txtDescripcion = compra.tobala.tobalaItems + " Mezcal Don Hesiquio Tobalá ";
  }
  if (compra.tobala.tobalaItems > 0 && compra.espadin.espadinItems > 0) {
    txtDescripcion = txtDescripcion + " + " + compra.espadin.espadinItems + " Mezcal Don Hesiquio Espadín ";
  }

  if (compra.tobala.tobalaItems == 0 && compra.espadin.espadinItems > 0) {
    txtDescripcion = txtDescripcion + compra.espadin.espadinItems + " Mezcal Don Hesiquio Espadín ";
  }

  if (compra.item4.items4 > 0) {
    txtDescripcion = txtDescripcion + " + " + compra.item4.items4 + descripcionItem4; 
  }
  if (compra.item6.items6 > 0) {
    txtDescripcion = txtDescripcion + " + " + compra.item6.items6 + descripcionItem6;
  }
  if (compra.item5.items5 > 0) {
    txtDescripcion = txtDescripcion + " + " + compra.item5.items5 + descripcionItem5;
  }
  if (compra.item3.items3 > 0) {
    txtDescripcion = txtDescripcion + " + " + compra.item3.items3 + descripcionItem3;
  }
  txtDescripcion=txtDescripcion.trim();
  if (txtDescripcion.charAt(0) === '+')
  {
      txtDescripcion =txtDescripcion.slice(1);
  }

  console.log(txtDescripcion)
  return txtDescripcion.trim();
}

export function getMensajeError(codigoError) {
  const mensajeErrorSoporte =
    "Error en la transacción * Comunicarse con soporte tecnico * Correo: contacto@mezcaldonhesiquio.com * Teléfono: 5634580002 ";
  const mensajeErrorTarjeta = "Error en los datos de la tarjeta * Favor de validar";
  const mensajeErrorBanco = "Tarjeta no valida";
  const mensajeError3dSecure = "Autenticación 3D Secure fallida.";
  const catalogoErroresSoporte = {
    1000: mensajeErrorSoporte,
    1002: mensajeErrorSoporte,
    1003: mensajeErrorSoporte,
    1004: mensajeErrorSoporte,
    1005: mensajeErrorSoporte,
    1006: mensajeErrorSoporte,
    1007: mensajeErrorSoporte,
    1008: mensajeErrorSoporte,
    1009: mensajeErrorSoporte,
    1010: mensajeErrorSoporte,
    1011: mensajeErrorSoporte,
    1012: mensajeErrorSoporte,
    1013: mensajeErrorSoporte,
    1014: mensajeErrorSoporte,
    1015: mensajeErrorSoporte,
    1016: mensajeErrorSoporte,
    1017: mensajeErrorSoporte,
    1018: mensajeErrorSoporte,
    1020: mensajeErrorSoporte,
    1023: mensajeErrorSoporte,
    1024: mensajeErrorSoporte,
    1025: mensajeErrorSoporte,
    2007: mensajeErrorSoporte,
    2010: mensajeErrorTarjeta,
  };
  const catalogo3dSecure = {
    2010: mensajeError3dSecure,
    15000: mensajeError3dSecure,
  };

  const catalogoErroresTarjeta = {
    1001: mensajeErrorTarjeta,
    2004: mensajeErrorTarjeta,
    2005: mensajeErrorTarjeta,
    2006: mensajeErrorTarjeta,
    2009: mensajeErrorTarjeta,
  };
  const catalogoErroresBanco = {
    2008: mensajeErrorBanco,
    2011: mensajeErrorBanco,
    3001: mensajeErrorBanco,
    3002: mensajeErrorBanco,
    3003: mensajeErrorBanco,
    3004: mensajeErrorBanco,
    3005: mensajeErrorBanco,
    3006: mensajeErrorBanco,
    3009: mensajeErrorBanco,
    3010: mensajeErrorBanco,
    3011: mensajeErrorBanco,
    3012: mensajeErrorBanco,
    3201: mensajeErrorBanco,
    3203: mensajeErrorBanco,
    3204: mensajeErrorBanco,
    3205: mensajeErrorBanco,
  };

  if (codigoError in catalogo3dSecure) {
    return catalogo3dSecure[codigoError];
  }

  if (codigoError in catalogoErroresSoporte) {
    return catalogoErroresSoporte[codigoError];
  }
  if (codigoError in catalogoErroresTarjeta) {
    return catalogoErroresTarjeta[codigoError];
  }
  if (codigoError in catalogoErroresBanco) {
    return catalogoErroresBanco[codigoError];
  }
  return "Ocurrió un error desconocido.";
}
