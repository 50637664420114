import React, { useEffect } from "react";
import { productos, productos2 } from "../assets/constants";
import { NavLink } from "react-router-dom";
import blueCheck from "../images/mezcales/blue-check.svg";
import "../style/nuestros-mezcales.css";

export default function ListaProductos() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <div className="">
        <div className="lista-productos-main-container ">
          <div className="lista-productos__container">
            {productos &&
              productos.map((producto) => (
                <div key={producto.id} className="container-item">
                  <div className="container-item--producto">
                    <NavLink
                      to="/Comprar"
                      state={{
                        producto: producto.id,
                        origen: "/Mezcales",
                        rutaPrevia: window.location.pathname,
                      }}
                    >
                      <div className="">
                        <img
                          src={producto.imagenMezcal}
                          alt=""
                          height="100%"
                          width="100%"
                        />
                        <div>
                          <img
                            className="medalla-mezcal"
                            src={producto.imagenMedalla}
                          />
                        </div>
                      </div>
                    </NavLink>
                    <div className="row justify-content-center">
                      <h2>{producto.nombreCorto}</h2>
                      <div>
                        <p>{producto.descripcionImagen}</p>
                      </div>
                      <div>
                        <ul
                          className="lista-descripcion-sabor d-flex flex-wrap"
                          style={{ padding: "0", fontSize: "0.7rem" }}
                        >
                          {producto.caracteristicasSaborMezcal.map(
                            (descripcion) => (
                              <li
                                className="list-desripcion-sabor"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={descripcion.icon}
                                  style={{
                                    width: "100%",
                                    marginBottom: "1.2rem",
                                  }}
                                  height="30px"
                                />
                                {descripcion.desc}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      {/* <span
                        style={{
                          color: "gray",
                          fontSize: "1.4rem",
                          textDecoration: "line-through",
                          marginBottom: "0.5rem",
                        }}
                      >
                        ${producto.costoSinDescuento.toLocaleString("en-US")}.00
                      </span> */}
                      {/* <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#20c997",
                          width: "auto",
                          height: "1.4rem",
                          borderRadius: "5px",
                          color: "#fff",
                          fontSize: "0.8rem",
                          padding: "0 1rem",
                          marginBottom: "1rem",
                          fontFamily: "Libre Baskerville !important",
                        }}
                      >
                        <p className="mb-0 align-center">
                          -{producto.descuentoActual}%
                        </p>
                      </div> */}
                      <h6 style={{ marginBottom: "0" }}>
                        ${producto.costo.toLocaleString("en-US")}
                      </h6>
                      <p style={{ color: "#5b5b5b" }}>
                        O hasta con {producto.puntos} puntos
                      </p>
                      <div className="puntos-obtenidos-container">
                        <p style={{ marginTop: "1.8rem" }}>
                          <img
                            src={blueCheck}
                            style={{ marginRight: "10px" }}
                          />
                          Te da{" "}
                          <span className="fw-bold">
                            {producto.puntosObtenidos} puntos
                          </span>{" "}
                          por botella
                        </p>
                      </div>
                    </div>
                    <div>
                      <NavLink
                        to="/Comprar"
                        state={{
                          producto: producto.id,
                          origen: "/Mezcales",
                          rutaPrevia: window.location.pathname,
                        }}
                      >
                        <button
                          className="boton-dorado align-self-center"
                          style={{ width: "8rem" }}
                        >
                          Comprar
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
