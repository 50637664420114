import "./style/historial-compra.css";
import React from "react";

// hooks
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// assets
import shoppingBag from "./assets/shopping_bag.svg";

function HistorialCompra() {
  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getHistorialPedidos";

  const [historialData, setHistorialData] = useState();
  const [loading, setLoading] = useState(true);

  const getHistorialPedidos = () => {
    fetch(urlLambda, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        idUser: sessionStorage.getItem("idUser"),
        // idUser: 10,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const dataMessage = data.message;
        console.log(data.message);

        setHistorialData(dataMessage);
        console.log("eeifjie" + JSON.stringify(historialData));

        setLoading((prev) => !prev);
      });
  };

  useEffect(() => {
    getHistorialPedidos();
  }, []);

  return (
    <>
      {loading ? (
        <div className="main-container">
          <div className="container pt-5 vh-100">
            <h3>Historial de compra</h3>
            <p>
              Consulta todas tus compras registradas y el movimiento de tus
              puntos
            </p>
            <h3>Cargando...</h3>
          </div>
        </div>
      ) : (
        <>
          {historialData?.length > 0 ? (
            <div className="main-container-historial">
              <div>
                <h3>Historial de compra</h3>
                <p>
                  Consulta todas tus compras registradas y el movimiento de tus
                  puntos
                </p>
                <div>
                  <ul className="table-pedidos-title row d-none d-sm-flex">
                    <li className="col-sm">FECHA</li>
                    <li className="col-sm">TIENDA</li>
                    <li className="col-sm">PEDIDO</li>
                    <li className="col-sm">PRODUCTO</li>
                    <li className="col-sm">PUNTOS OBTENIDOS</li>
                    <li className="col-sm">PUNTOS GASTADOS</li>
                  </ul>
                </div>
                <div className="table-container d-flex flex-wrap d-sm-block">
                  {historialData?.map((producto) => (
                    <div className="table-container">
                      <ul className="d-xs-block d-sm-none d-md-none d-lg-none row">
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Fecha
                        </li>
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Tienda
                        </li>
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Pedido
                        </li>
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Producto
                        </li>
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Puntos Obtenidos
                        </li>
                        <li className="column-pedidos col-sm  text-center text-secondary">
                          Puntos Gastados
                        </li>
                      </ul>
                      <ul className="row">
                        <li className="column-pedidos col-sm">
                          {producto.fecha}
                        </li>
                        <li className="column-pedidos col-sm">
                          Tienda en l&iacute;nea Don Hesiquio
                        </li>
                        <li className="column-pedidos col-sm overflow-hidden">
                          {producto.pedido}
                        </li>
                        <li className="column-pedidos col-sm overflow-scroll">
                          {producto.productos}
                        </li>
                        <li
                          className={`column-pedidos col-sm ${
                            producto.puntosObtenidos.includes("N/A")
                              ? ""
                              : "text-success"
                          }`}
                        >
                          {producto.puntosObtenidos}
                        </li>
                        <li
                          className={`column-pedidos col-sm ${
                            producto.puntosGastados.includes("N/A")
                              ? ""
                              : "text-danger"
                          }`}
                        >
                          {producto.puntosGastados}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
                <ul className="row">
                  <li className="col-sm d-none d-sm-block"></li>
                  <li className="col-sm d-none d-sm-block"></li>
                  <li className="column-pedidos col-sm">Total</li>
                  <li
                    className="column-pedidos col-sm"
                    style={{ color: "#a78721", fontSize: "1.3rem" }}
                  >
                    {historialData[0].totalPuntos} puntos
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="main-container" style={{ padding: "0.5rem"}}>
              <div className="container pt-5">
                <h3>Historial de compra</h3>
                <p>
                  Consulta todas tus compras registradas y el movimiento de tus
                  puntos
                </p>
              </div>
              <div className="mis-pedidos-vacio-main-container container">
                <div className="mis-pedidos-vacio-container">
                  <img
                    className="container mx-auto d-flex align-self-center pt-5"
                    src={shoppingBag}
                  />
                  <h2 className="container text-center pt-5">
                    ¡Empieza a comprar!
                  </h2>
                  <p className="text-center pt-5">
                    Agrega las botellas que te quieras llevar
                  </p>
                  <div className="container d-flex justify-content-center">
                    <Link to="/Mezcales">
                      <button
                        className="btn-lg d-flex align-self-center text-white"
                        style={{ backgroundColor: "#a78721" }}
                      >
                        Conocer Mezcales
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HistorialCompra;
