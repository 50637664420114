import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import "./tienda.css";
import ListaProductos from "../ListaProductos";
import { NavBar } from "../../components/NavBar";
import { NavLink } from "react-router-dom";
import { Footer } from "../../components/Footer";
import calaverita from "../../images/tienda/calaverita.png";
import chapulines from "../../images/tienda/chapulines.png";
import gusanos from "../../images/tienda/gusanos.png";
import jicara from "../../images/tienda/jicara.png";

import { formatPrecio } from "../../assets/constants";

// importaciones para imagenes de productos de inventario (no mezcales)
import imagenProductoInventario1 from "../../images/mezcales-products/tobala-espadin-kitcocteleria.jpg";
import imagenProductoInventario2 from "../../images/mezcales-products/tobala-kitcocteleria.jpg";
import imagenProductoInventario3 from "../../images/mezcales-products/gorra.jpg";
import imagenProductoInventario4 from "../../images/mezcales-products/kitcocteleria.jpg";

function Tienda() {
  const [productosDegustar, setProductosDegustar] = useState([]);

  useEffect(() => {
    async function fetchAndSetProducts() {
      let localProducts = sessionStorage.getItem("productosDegustar");
      if (localProducts) {
        setProductosDegustar(JSON.parse(localProducts));
      } else {
        const response = await fetch(
          "https://api.mezcaldonhesiquio.com/getProductos"
        );
        const data = await response.json();
        console.log(data);
        const productosParaDegustar = data.message.filter(
          (producto) => producto.idCategoria == 2
        );
        const productosMezcal = data.message.filter(
          (mezcal) => mezcal.idCategoria == 1
        );
        sessionStorage.setItem(
          "productosDegustar",
          JSON.stringify(productosParaDegustar)
        );
        sessionStorage.setItem(
          "productosMezcal",
          JSON.stringify(productosMezcal)
        );
        setProductosDegustar(productosParaDegustar);
      }
    }

    fetchAndSetProducts();
  }, []);

  useEffect(() => {
    ReactPixel.init("770394035103308", {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();  
    ReactPixel.track('StoreVisit', {
     page: "/ListaProductos",
     message: "Un usuario ha visitado la tienda"
   })

  }, [])

  return (
    <>
      <NavBar />
      <div className="mezcales-container">
        <h3 className="mezcales-titulo">Mezcales</h3>
        <ListaProductos />
        <h3 className="text-center fs-1">Para degustar</h3>
        <div className="container d-flex justify-content-center">
          <div className="d-flex row lista-productos-degustar">
            {productosDegustar.length > 0 ? (
              productosDegustar.map((producto2) => (
                <div
                  key={producto2.idProducto}
                  className="container-item2 col-md-6 col-lg-4 flex-wrap"
                >
                  <NavLink
                    to="/Comprar"
                    state={{
                      producto: producto2,
                      origen: "/ListaProductos",
                      rutaPrevia: window.location.pathname,
                    }}
                  >
                    <div className="container-item--producto2 container-blanco">
                      <div>
                        <img
                          className=""
                          style={{ objectFit: "cover", maxWidth: "20rem", borderRadius: "5px"  }}
                          src={
                            producto2.idProducto == 4
                              ? imagenProductoInventario2
                              : producto2.idProducto == 5
                              ? imagenProductoInventario3
                              : producto2.idProducto == 6
                              ? imagenProductoInventario4
                              : imagenProductoInventario1
                          }
                          alt=""
                        />
                      </div>
                      <div className="row text-start px-2" style={{ height: "15rem", alignItems: "start"}}>
                        <h2 className="texto-negro fs-5">
                          {producto2.nombreProducto}
                        </h2>
                        <p className="fs-6">{producto2.descripcion}</p>
                        <div style={{ alignSelf: "baseline"}}>
                        <h6
                          style={{ marginBottom: "0" }}
                          className="texto-negro text-end fs-5"
                        >
                          ${formatPrecio(producto2.precio)}
                        </h6>
                        <p
                          style={{ color: "#5b5b5b" }}
                          className="texto-transparente text-end mt-2"
                        >
                          O hasta con{" "}
                          <span className="fw-bold">
                            {producto2.conversionPuntos}
                          </span>{" "}
                          puntos
                        </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))
            ) : (
              <span>cargando</span>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tienda;
