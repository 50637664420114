import { useMemo, useContext } from "react";
import {
  costoEspadin,
  costoTobala,
  costoProductoInventario2,
  costoProductoInventario4,
  costoProductoInventario3,
  costoProductoInventario1,
  productos,
} from "../../../assets/constants";
import { calculateShippingCost } from "../../../assets/functions";
import { MyContext } from "../../../components/context/Context";

export const useCost = (isLocal) => {
  const { total } = useContext(MyContext);
  console.log(total);
  const tobalaItems = parseInt(localStorage.getItem("2"))
    ? parseInt(localStorage.getItem("2"))
    : 0;
  const espadinItems = parseInt(localStorage.getItem("1"))
    ? parseInt(localStorage.getItem("1"))
    : 0;
  const item4 = parseInt(localStorage.getItem("4"))
    ? parseInt(localStorage.getItem("4"))
    : 0;
  const item6 = parseInt(localStorage.getItem("6"))
    ? parseInt(localStorage.getItem("6"))
    : 0;
  const item5 = parseInt(localStorage.getItem("5"))
    ? parseInt(localStorage.getItem("5"))
    : 0;
  const item3 = parseInt(localStorage.getItem("3"))
    ? parseInt(localStorage.getItem("3"))
    : 0;

  const initialState = {
    DatosContactoObj: {
      nombre: "",
      apellidos: "",
      celular: "",
      email: "",
    },
    DireccionEnvioObj: {
      calle: "",
      numExt: "",
      numInt: "",
      entreCalle: "",
      ref: "",
      cp: "",
      colonia: "",
      ciudad: "",
      estado: "",
      foraneo: false,
      costoEnvio: "",
    },
    Costs: {
      tobala: { id: 2, tobalaItems, costo: 0 },
      espadin: { id: 1, espadinItems, costo: 0 },
      subTotal: {
        totalItems: 0,
        costo: 0,
      },
      shippingCost: 0,
      total: 0,
    },
  };

  const costos = JSON.parse(localStorage.getItem("datosEnvio"));
  console.log(costos);
  if (costos == null || costos == undefined) {
    localStorage.setItem("datosEnvio", JSON.stringify(initialState));
  }
  console.log(costos.Costs.total);
  costos.Costs.total = total;
  localStorage.setItem("datosEnvio", JSON.stringify(costos));

  const totalItems = parseInt(localStorage.getItem("totalItems")) ?? 0;
  console.log(totalItems)
  const shippingCost = isLocal ? 0 : calculateShippingCost(totalItems);

  const costoItemsTobala = Math.round(tobalaItems * costoTobala * 100) / 100;
  const costoItemsEspadin = Math.round(espadinItems * costoEspadin * 100) / 100;
  const costoItem4 = Math.round(item4 * costoProductoInventario2 * 100) / 100;
  const costoItem6 = Math.round(item6 * costoProductoInventario4 * 100) / 100;
  const costoItem5 = Math.round(item5 * costoProductoInventario3 * 100) / 100;
  const costoItem3 = Math.round(item3 * costoProductoInventario1 * 100) / 100;

  const subTotal =
    Math.round((costoItemsTobala + costoItemsEspadin + costoItem4 + costoItem6 + costoItem5 + costoItem3) * 100) / 100;

    console.log(subTotal)

  const costs = useMemo(() => {
    return {
      tobala: { id: 2, tobalaItems, costo: costoItemsTobala },
      espadin: { id: 1, espadinItems, costo: costoItemsEspadin },
      item4: { id: 4, items4: parseInt(localStorage.getItem("4")), costo: costoItem4 },
      item6: { id: 6, items6: parseInt(localStorage.getItem("6")), costo: costoItem6 },
      item5: { id: 5, items5: parseInt(localStorage.getItem("5")), costo: costoItem5 },
      item3: { id: 3, items3: parseInt(localStorage.getItem("3")), costo: costoItem3 },
      subTotal: {
        totalItems,
        costo: subTotal,
      },
      shippingCost,
      total: total,
    };
  }, [
    costoItemsEspadin,
    costoItemsTobala,
    espadinItems,
    shippingCost,
    subTotal,
    tobalaItems,
    total,
    totalItems,
  ]);

  return { costs };
};
