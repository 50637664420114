import React, { useContext, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import { MyContext } from "../../components/context/Context";
import UserPool from "../../userPool";
import ModalCarga from "../components/ModalCarga";

import "./style.css";
import { set } from "react-hook-form";

function ResetPassword() {
  const { getSession, onLogin } = useContext(MyContext);
  const [correo, setCorreo] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const enviarCorreo = (token) => {
    const URL2 =
      "https://api.mezcaldonhesiquio.com/enviarCorreoResetPassword";
    fetch(URL2, {
      method: "POST",
      body: JSON.stringify({
        email: correo,
        token: token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((response2) => {
        if (response2.access == true) {
          setLoading(false);
          console.log("correo enviado");
          console.log(response2);
          if (correo) {
            navigate(`/correoEnviado`, { state: { email: correo } });
          } else {
            console.error("Correo undefined");
          }
          // navigate(`/correoEnviado`, { state: { email } });
        } else {
          setLoading(false);
          console.log("no se envio el correo");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setErrorMessage("Ocurrio un error, intenta de nuevo.");
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    console.log(correo);
    const URL =
      "https://api.mezcaldonhesiquio.com/validarCorreo";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        email: correo,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const email = response.message[0].email;
        console.log(response);
        if (response.access == true) {
          crearTokenPassword(email);
        } else {
          setLoading(false);
          console.log("no existe");
          setErrorMessage("El correo que ingresaste no existe");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setErrorMessage("Ocurrio un error, intenta de nuevo.");
      });
  }

  function crearTokenPassword(email) {
    console.log(email);
    const URL =
      "https://api.mezcaldonhesiquio.com/crearTokenPassword";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.access == true) {
          console.log('token creado con exito');
          const token = response.token;
          console.log(token)
          enviarCorreo(token);
        } else {
          setLoading(false);
          console.log("no existe");
          setErrorMessage("Ya se ha enviado un correo para restablecer la contraseña. Revisa tu bandeja de entrada o spam.");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setErrorMessage("Ocurrio un error, intenta de nuevo.");
      });
  }

  return (
    <>
      <NavBar />
      <div className="datos-envio contenedor_reset_password">
        <div className="container">
          <div className="headers_password-reset text-center">
            <h3>Restablecer contrase&ntilde;a</h3>
            <p>
              Te enviaremos un correo electrónico para restablecer su
              contraseña.
            </p>
          </div>
          <div className="row justify-content-center mt-4">
            <form
              onSubmit={handleSubmit}
              className="container-blanco col-5 p-4 d-flex flex-column gap-4"
              style={{ width: "20rem"}}
            >
              <div className="form-group formulario-reset-password">
                <label htmlFor="email">Correo electr&oacute;nico</label>
                <input
                  onChange={(event) => setCorreo(event.target.value)}
                  type="email"
                  className="form-control custom-input mt-2"
                  id="email"
                  aria-describedby="emailHelp"
                />
                {errorMessage && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {errorMessage}
                  </div>
                )}
              </div>
              <div className="row">
                <button
                  type="submit"
                  className="col-5 mx-auto boton-enviar-resetpassword"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalCarga open={loading} />
    </>
  );
}

export default ResetPassword;
