import { Footer } from "../components/Footer";
import { HeaderWhite } from "../components/MenuBlanco";

//imagenes
import openPay from "../images/open-pay.png";
import closeNegro from "../images/close-negro.svg";
import europea from "../images/europea.svg";
import amazon from "../images/amazon.svg";
import blueCheck from "../images/mezcales/blue-check.svg";
import calaverita from "../images/tienda/calaverita.png";
import gusanos from "../images/tienda/gusanos.png";
import chapulines from "../images/tienda/chapulines.png";
import jicara from "../images/tienda/jicara.png";

import carrito from "../images/icono-carrito-oro.svg";

import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

import { productos, productos2 } from "../assets/constants";
import { formatPrice } from "../assets/functions";
import { MyContext } from "../components/context/Context";
import { formatPrecio } from "../assets/constants";

import ModalItemCarrito from "../components/Modal/ModalItemCarrito";

// importaciones para imagenes de productos de inventario (no mezcales)
import imagenProductoInventario1 from "../images/mezcales-products/tobala-espadin-kitcocteleria.jpg";
import imagenProductoInventario1Mobile from "../images/mezcales-products/tobala-espadin-kitcocteleria2.jpg";
import imagenProductoInventario2 from "../images/mezcales-products/tobala-kitcocteleria.jpg";
import imagenProductoInventario3 from "../images/mezcales-products/gorra.jpg";
import imagenProductoInventario4 from "../images/mezcales-products/kitcocteleria.jpg";

//Componentes

const urlAmazon = "https://www.amazon.com.mx/s?me=A3G4UIDIYI991K&ref=sf_seller_app_share_new";
const urlEuropea = "https://www.laeuropea.com.mx/catalogsearch/result/?q=don+hesiquio+";

export default function Comprar() {
  let location = useLocation();
  const categoria = location.state.producto.idCategoria;
  const [origen, setOrigen] = useState("/");
  const [producto, setProducto] = useState({});
  const [costo, setCosto] = useState(0);
  const [numeroItems, setNumeroItems] = useState(1);
  const [modalProximo, setModalProximo] = useState(false);
  const [modalAvisoItems, setModalAvisoItems] = useState(false);
  const [costoSinDescuento, setCostoSinDescuento] = useState(0);
  const { itemsCarrito, setItemsCarrito, openCarritoModal, setOpenCarritoModal } = useContext(MyContext);
  const [nombreProductoModal, setNombreProductoModal] = useState();
  const [cantidadProductosModal, setCantidadProductosModal] = useState();
  const navigate = useNavigate();

  // Toggle for Modal
  const toggleProximo = (e) => {
    e.preventDefault();
    setModalProximo(!modalProximo);
  };

  const toggleAvisoItems = (e) => {
    e.preventDefault();
    setModalAvisoItems(!modalAvisoItems);
  };

  const minusButtonClick = (e) => {
    e.preventDefault();
    if (numeroItems > 1) setNumeroItems((numeroItems) => numeroItems - 1);
  };

  const plusButtonClick = (e) => {
    e.preventDefault();
    var totalItems = localStorage.getItem("totalItems");
    if (totalItems == null || totalItems === undefined) {
      totalItems = 0;
    } else {
      totalItems = parseInt(totalItems);
    }
    totalItems += numeroItems + 1;
    setNumeroItems((numeroItems) => numeroItems + 1);
  };

  function dirigirEuropea(e) {
    e.preventDefault();
    window.open(urlEuropea, "_blank");
  }

  function dirigirAmazon(e) {
    e.preventDefault();
    //toggleProximo(e);
    window.open(urlAmazon, "_blank");
  }

  // para abrir modal de carrito
  const modalItemAgregado = () => {
    setOpenCarritoModal(true)
    console.log(producto.nombreLargo)
    if(location.state.producto.idCategoria == 2) {
      setNombreProductoModal(location.state.producto.nombreProducto)
    } else {
      setNombreProductoModal(producto.nombreLargo)
    }
  }

  function agregarCarrito() {
    // window.scrollTo({ top: 0, behavior: "smooth"})
    modalItemAgregado();
    if (numeroItems > 0) {
      let totalItems = parseInt(localStorage.getItem("totalItems")) ? parseInt(localStorage.getItem("totalItems")) : 0;
      let itemsProducto = parseInt(localStorage.getItem(producto.id)) ? parseInt(localStorage.getItem(producto.id)) : 0;
      let itemsProducto2 = parseInt(localStorage.getItem(producto.idProducto)) ? parseInt(localStorage.getItem(producto.idProducto)) : 0;
      itemsProducto += numeroItems;
      itemsProducto2 += numeroItems;
      if (categoria == 2) {
        localStorage.setItem(producto.idProducto, itemsProducto2); // posible falla
      } else {
        localStorage.setItem(producto.id, itemsProducto);
      }
      totalItems += numeroItems;
      localStorage.setItem("totalItems", totalItems);
      
      const productosAgregadosAlCarrito = itemsProducto;
      console.log(
        "🚀 ~ file: Comprar.js:102 ~ agregarCarrito ~ productosAgregadosAlCarrito:",
        productosAgregadosAlCarrito
      );
      setCantidadProductosModal(numeroItems)
      console.log(cantidadProductosModal)
      setItemsCarrito(totalItems);
    }
  }

  // function agregarCarrito() {
  //   if (numeroItems > 0) {
  //     // obtener el total de ítems del carrito desde localStorage y asegurarse de que es un número
  //     let totalItems = parseInt(localStorage.getItem("totalItems") || 0);
  
  //     // incrementar y guardar correctamente los ítems basado en la categoría del producto
  //     if (categoria == 2) {
  //       // tratar específicamente los productos de tipo 2
  //       let itemsProducto2 = parseInt(localStorage.getItem(producto.idProducto) || 0);
  //       itemsProducto2 += numeroItems;
  //       localStorage.setItem(producto.idProducto, itemsProducto2);
  //       console.log("🚀 ~ file: Comprar.js:117 ~ agregarCarrito ~ itemsProducto2:", itemsProducto2);
  //     } else {
  //       // tratar todos los demás tipos de productos
  //       let itemsProducto = parseInt(localStorage.getItem(producto.id) || 0);
  //       itemsProducto += numeroItems;
  //       localStorage.setItem(producto.id, itemsProducto);
  //       console.log("🚀 ~ file: Comprar.js:102 ~ agregarCarrito ~ itemsProducto:", itemsProducto);
  //     }
  
  //     // incrementar el total de ítems en el carrito y guardar el nuevo total en localStorage
  //     totalItems += numeroItems;
  //     localStorage.setItem("totalItems", totalItems);
  
  //     // actualizar el estado del carrito en la interfaz de usuario (si aplicable)
  //     setItemsCarrito(totalItems);
  //   }
  // }


  function comprarAhora() {
    const existeProducto = localStorage.getItem(producto.id) || localStorage.getItem(producto.idProducto);
    if (existeProducto > 0) {
      navigate("/Carrito");
      return;
    }

    agregarCarrito();
    navigate("/Carrito");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location.state.producto);
    if (location.state.producto.idCategoria == 2) {
      console.log("entra en if");
      console.log(location.state.producto)
      setProducto(location.state.producto);
    }
    let productoTmp = productos.find((x) => x.id === location.state.producto);
    console.log(productoTmp)
    if (productoTmp != null && productoTmp !== undefined) {
      setProducto(productoTmp);
      setCosto(productoTmp.costo);
      setCostoSinDescuento(productoTmp.costoSinDescuento);
    }
    setOrigen(location.state.origen);
  }, []);

  useEffect(() => {
    console.log("estado de openCarritoModal: " + openCarritoModal)
  }, [openCarritoModal])

  return (
    <>
      <HeaderWhite />
      <div className="comprar-producto">
        <div className="comprar-producto__main--volver">
          <button
            className="texto-dorado menu-letra"
            onClick={() => navigate(-1)}
          >
            {location.state.rutaPrevia == "/Mezcales"
              ? "Volver a Nuestros Mezcales"
              : "Volver a productos"}
          </button>
        </div>
        <p className="texto-negro title--movil">
          {categoria == 2 ? producto.nombreProducto : producto.nombreTienda}
        </p>
        <ModalItemCarrito 
        show={openCarritoModal} 
        productoAgregado={nombreProductoModal} 
        totalItems={cantidadProductosModal}
        />
        <div className="comprar-producto__container container">
          <div className="comprar-producto__caracteristicas">
            {categoria == 2 ? (
              <div className="comprar-producto__caracteristicas--img">
                <img
                  src={
                    producto.idProducto == 4 ? imagenProductoInventario2 :
                    producto.idProducto == 5 ? imagenProductoInventario3 :
                    producto.idProducto == 6 ? imagenProductoInventario4 : 
                    imagenProductoInventario1
                  }
                  
                  alt={`imagen de ${producto.nombre}`}
                  className="img-fluid d-none d-md-block"
                />

                <img
                  src={
                    producto.idProducto == 4 ? imagenProductoInventario2 :
                    producto.idProducto == 5 ? imagenProductoInventario3 :
                    producto.idProducto == 6 ? imagenProductoInventario4 : 
                    imagenProductoInventario1Mobile
                  }
                  
                  alt={`imagen de ${producto.nombre}`}
                  className="d-block d-md-none"
                />
              </div>
            ) : (
              <>
              {/* se muestra con vista en desktop */}
              <div className="comprar-producto__caracteristicas--img d-none d-md-block">
                <img src={producto.imagenItem} alt="" />
              </div>

              {/* se muestra con vista en mobile */}
              <div className="comprar-producto__caracteristicas--img d-block d-md-none ">
               <img src={producto.imagenItemMobile} alt="" />
             </div>
             </>
            )}
            <div className="row" style={{ padding: "1rem 3rem" }}>
              <hr />
            </div>
            <div className="comprar-producto__detalles__caracteristicas descripcio_desktop">
              {categoria == 2 ? null : (
                <div className="row">
                  <span className="titulo-oro-comprar">
                    Caracter&iacute;sticas del mezcal
                  </span>
                </div>
              )}
              <div className="row">
                <section className="comprar__descripcion descripcio_desktop">
                  {producto.caracteristicas &&
                    producto.caracteristicas.map((caracteristica, index) => (
                      <span
                        className="comprar-producto__detalles__caracteristicas_item"
                        key={"caracteriscita-" + index}
                      >
                        <b className="fw-bold">{caracteristica.title}: </b>
                        {caracteristica.desc}
                      </span>
                    ))}
                </section>
              </div>
            </div>
          </div>
          <div className="detalle-compra__cajas">
            <div className="row caja-compra">
              <div className="container comprar-producto__detalles">
                <div className="row">
                  <span
                    className="texto-negro title--desktop "
                    style={{ fontSize: "20px", fontFamily: "Source Sans Pro" }}
                  >
                    {categoria == 2
                      ? producto.nombreProducto
                      : producto.nombreTienda}
                  </span>
                </div>
                <div className="row">
                  {categoria == 2 ? (
                    <>
                    <span style={{ fontSize: "1.2rem", paddingBottom: "1rem"}}>{producto.nombreProducto}</span>
                    <span className="texto-oro-precio">${formatPrecio(producto.precio)}</span>
                    </>
                  ) : (
                    <>
                      {/* <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                        <span
                          style={{
                            color: "gray",
                            fontSize: "1.4rem",
                            textDecoration: "line-through",
                          }}
                        >
                          {formatPrice(costoSinDescuento)}
                        </span>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "#20c997",
                            width: "auto",
                            height: "1.4rem",
                            borderRadius: "5px",
                            color: "#fff",
                            fontSize: "0.8rem",
                            padding: "0 3px",
                            marginLeft: "1rem",
                            fontFamily: "Libre Baskerville !important "
                          }}
                        >
                          <p className="mb-0 align-center">
                            -{producto.descuentoActual}%
                          </p>
                        </div>
                      </div> */}
                      <span style={{ fontSize: "1.2rem", paddingBottom: "1rem"}}>{producto.nombreLargo}</span>
                      <span className="texto-oro-precio">
                        {formatPrice(costo)}
                      </span>
                    </>
                  )}
                  <span className="texto-transparente">
                    O hasta con{" "}
                    <span className="fw-bold">
                      {categoria == 2 ? producto.conversionPuntos : producto.puntos}
                    </span>{" "}
                    puntos{" "}
                  </span>
                </div>
                <div className="row">
                  {categoria == 2 ? null : (
                    <span>
                      <img src={blueCheck} style={{ marginRight: "10px" }} />
                      Te da{" "}
                      <span className="fw-bold">
                        {producto.puntosObtenidos} puntos
                      </span>{" "}
                      por botella
                    </span>
                  )}
                  <span
                    className="texto-negro-sans"
                    style={{
                      fontSize: "16px",
                      paddingTop: "20px",
                      paddingLeft: "15px",
                    }}
                  >
                    <img
                      src={carrito}
                      style={{ marginRight: "5px" }}
                      width={"20px"}
                      alt="carrito"
                    />
                    Entrega gratis en la Ciudad de México
                  </span>
                </div>
                <div className="row">
                  <span
                    className="texto-negro-sans"
                    style={{ fontSize: "16px" }}
                  >
                    Stock disponible
                  </span>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center justify-content-md-start"
                  style={{ marginBottom: "1rem" }}
                >
                  <div
                    className="d-flex flex-row"
                    style={{ border: "1px solid #E0E0E0" }}
                  >
                    <div className="p-1">
                      <button
                        onClick={(e) => minusButtonClick(e)}
                        className="boton-cantidad-items"
                        style={{ marginLeft: "5px" }}
                      >
                        −
                      </button>
                    </div>
                    <div className="p-1">
                      <input
                        type="input"
                        value={numeroItems}
                        className="input-cantidad-items"
                        readOnly
                      />
                    </div>
                    <div className="p-1">
                      <button
                        onClick={(e) => plusButtonClick(e)}
                        className="boton-cantidad-items"
                        style={{ marginRight: "5px" }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button
                    style={{
                      borderStyle: "none",
                      backgroundColor: "white",
                    }}
                    onClick={agregarCarrito}
                  >
                    <p className="agregar-carrito">Agregar al carrito</p>
                  </button>
                </div>
                <div className="row">
                  <button
                    style={{
                      borderStyle: "none",
                      backgroundColor: "white",
                    }}
                    onClick={comprarAhora}
                  >
                    <p className="comprar-ahora">Comprar ahora</p>
                  </button>
                </div>
                <div className="row justify-content-center">
                  <img src={openPay} alt="" height="100%" width="100%" />
                </div>
              </div>
            </div>
            {categoria == 2 ? null : (
            <div className="comprar-producto__detalles__caracteristicas descripcio_mobile">
              <div className="row">
                <span className="titulo-oro-comprar">
                  Caracter&iacute;sticas del mezcal
                </span>
              </div>
              <div className="row">
                <section className="comprar__descripcion">
                  {producto.caracteristicas &&
                    producto.caracteristicas.map((caracteristica, index) => (
                      <span
                        className="comprar-producto__detalles__caracteristicas_item"
                        key={"caracteriscita-" + index}
                      >
                        <b>{caracteristica.title}: </b>
                        {caracteristica.desc}
                      </span>
                    ))}
                </section>
              </div>
            </div>
            )}
            {categoria == 2 ? null : (
              <div className="row caja-compra caja-compra--comprar">
                <div className="container">
                  {/* <div className="row">
                    <span className="texto-negro-sans" style={{ fontSize: "16px" }}>
                      Disponible en l&iacute;nea tambi&eacute;n:
                    </span>
                  </div>
                  <div className="amazon-icon row justify-content-center">
                    <div className="col-9" style={{ marginTop: "1rem" }}>
                      <NavLink to="#" style={{ cursor: "hand" }} title="Próximamente..." onClick={dirigirAmazon}>
                        <img src={amazon} style={{ paddingLeft: "2rem" }} width="80%" height="80%" alt="link Amazon" />
                      </NavLink>
                    </div>
                  </div> */}
                  <div className="row">
                    <span
                      className="texto-negro-sans"
                      style={{ fontSize: "16px" }}
                    >
                      Disponible físicamente en los almacenes La Europea:
                    </span>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-9" style={{ marginTop: "1rem" }}>
                      <NavLink
                        to="#"
                        style={{ cursor: "hand" }}
                        onClick={dirigirEuropea}
                      >
                        <img
                          src={europea}
                          width="100%"
                          height="100%"
                          alt="link Europea"
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {categoria == 2 ? null : (
      <div className="description-mezcal">
        <div className="description-mezcal-container container"
        style={{ display: "none"}}>
          <div className="description-mezcal-img-container">
            <img src={producto.imagenDescripcion} />
          </div>
          <p>
            {producto.id == 1 ? (
              <span className="description-productos">
                Don Hesiquio® Espadín, tiene como principal característica un
                proceso de destilación tan noble y puro que te ofrecerá una
                experiencia organoléptica única A TU PALADAR.
                <br />
                <br />
                Cristalino y con cuerpo, ofrece suaves notas de aroma a agave
                cocido y sutil sabor ahumado, cuenta con matices cítricos, ideal
                para tomarlo derecho o en un cóctel de altura. Su sabor es
                definido y no lastima ni produce aquella sensación de “quemar”
                al pasar por tu garganta, otorgándote un retrogusto agradable y
                persistente.
              </span>
            ) : (
              <span className="description-productos">
                Don Hesiquio® Tobalá presenta un color cristalino, al ser un
                agave silvestre poco común, nos brinda exquisitas notas ligeras
                de frutos maduros del campo y un aroma delicioso de mieles y
                ahumados sutiles. <br />
                <br /> Nuestro mezcal ofrece una experiencia organoléptica
                holística que en sabor y aroma atrapará tu paladar por su
                equilibrio y suavidad.
              </span>
            )}
          </p>
        </div>
      </div>

      )}
      <Footer />
      <Modal
        isOpen={modalProximo}
        toggle={toggleProximo}
        modalTransition={{ timeout: 500 }}
      >
        <ModalBody>
          <p align="right">
            <NavLink to="#" style={{ cursor: "hand" }} onClick={toggleProximo}>
              <img src={closeNegro} width="6%" height="6%" alt="icon cerrar" />
            </NavLink>
          </p>
          <center>
            <div style={{ width: "65%" }}>
              <span className="titulo-proximo">Pr&oacute;ximamente...</span>
            </div>
            <br />
            <br />
            <button
              className="btn-rectangulo-principal btn bg-dorado contactano  texto-blanco-proximo"
              type="button"
              style={{ height: "45px" }}
              onClick={toggleProximo}
            >
              Aceptar
            </button>
          </center>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalAvisoItems}
        toggle={toggleAvisoItems}
        modalTransition={{ timeout: 500 }}
      >
        <ModalBody>
          <p align="right">
            <NavLink
              to="#"
              style={{ cursor: "hand" }}
              onClick={toggleAvisoItems}
            >
              <img src={closeNegro} width="6%" height="6%" alt="icono cerrar" />
            </NavLink>
          </p>
          <center>
            <div style={{ width: "65%" }}>
              <span className="texto-negro-sans">
                Para compras mayores a 12 botellas favor de llamar al 5634580002
              </span>
            </div>
            <br />
            <br />
            <button
              className="btn-rectangulo-principal btn bg-dorado contactano  texto-blanco-proximo"
              type="button"
              style={{ height: "45px" }}
              onClick={toggleAvisoItems}
            >
              Aceptar
            </button>
          </center>
        </ModalBody>
      </Modal>
    </>
  );
}
