import React from "react";
import { NavBar } from "../../../../components/NavBar";
import { Footer } from "../../../../components/Footer";
import { Link } from "react-router-dom";

import reproductor from "../../../../images/datosPersonales/cocteles-y-maridajes/b27d13565fbf48ffc4d24253a582846a.png";
import bannerMezcal from "../../../../images/cocteles/coctel_1a.jpg";
import { nombreCoctel1 } from "../../../../assets/constants";
import videoCoctel1 from "../../../../images/cocteles/mezc-old-fashioned.mp4";
import VideoPlayer from "../../../components/VideoPlayer";

function Coctel1() {
  return (
    <>
      <NavBar />
      <div className="datos-envio">
        <div className="container">
          <div className="row">
            <Link to="/cocteles-y-maridajes">
              <p className="fs-5 texto-oro">
                Volver a C&oacute;cteles y maridajes
              </p>
            </Link>
          </div>

          <div className="row container__imagen_coctel">
            <img src={bannerMezcal} alt="" />
            <p className="texto-negro">{nombreCoctel1}</p>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">INGREDIENTES</h3>
              <ul className="text-start list-container-group">
                <li className="list-item-cocteles">
                  2 oz MEZCAL ESPADÍN DON HESIQUIO
                </li>
                <li className="list-item-cocteles">
                  1 cubo de azúcar o ¾ oz de jarabe natural
                </li>
                <li className="list-item-cocteles">
                  3 gotas de chocolate bitters
                </li>
                <li className="list-item-cocteles">
                  Cascara de naranja para decorar
                </li>
                <li className="list-item-cocteles">Hielo</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">PREPARACI&Oacute;N</h3>
              <ul
                className="list-group list-group-numbered"
                style={{ textWrap: "balance" }}
              >
                <li className="p-0 ms-4 text-start">
                En un shaker agregue MEZCAL ESPADÍN DON HESQIUIO, azúcar o gotas de chocolate, y las gotas de chocolate, Bitters, agregue hielo y agite 10 segundos
                </li>
                <li className="p-0 ms-4 text-start">
                  Con un colador vierta en un vaso corto u old fashioned sobre
                  hielo fresco y decore con la cascara de naranja.
                </li>
              </ul>
            </div>
          </div>
          <div className="container_video_coctel">
            <div className="row justify-content-center mt-4">
              <div className="col-">
                <VideoPlayer
                  videoUrl="https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/DonHesiquio2.0/videos/Mezc-Old+Fashioned+3.0.mp4"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Coctel1;
