// componentes
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import SumarPuntosComponent from "../DatosPersonales/components/SumarPuntosComponent";

export default function SumarPuntos() {
  return (
    <>
      <NavBar />
      <SumarPuntosComponent />
      <Footer />
    </>
  );
}
