import React from "react";
import { NavBar } from "../../../../components/NavBar";
import { Footer } from "../../../../components/Footer";
import { Link } from "react-router-dom";

import reproductor from "../../../../images/datosPersonales/cocteles-y-maridajes/b27d13565fbf48ffc4d24253a582846a.png";
import bannerMezcal from "../../../../images/cocteles/coctel_2b.jpg";
import { nombreCoctel2 } from "../../../../assets/constants";
import videoCoctel2 from "../../../../images/cocteles/mezcal-especial.mp4";
import VideoPlayer from "../../../components/VideoPlayer";

function Coctel2() {
  return (
    <>
      <NavBar />
      <div className="datos-envio">
        <div className="container">
          <div className="row">
            <Link to="/cocteles-y-maridajes">
              <p className="fs-5 texto-oro">
                Volver a C&oacute;cteles y maridajes
              </p>
            </Link>
          </div>

          <div className="row container__imagen_coctel">
            <img src={bannerMezcal} alt="" />
            <p className="texto-negro">{nombreCoctel2}</p>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">INGREDIENTES</h3>
              <ul className="text-start list-container-group">
                <li className="list-item-cocteles">3 oz de jugo de naranja</li>
                <li className="list-item-cocteles">
                  3 oz de concentrado de 7 chiles
                </li>
                <li className="list-item-cocteles">1 dash de jarabe natural</li>
                <li className="list-item-cocteles">
                  1 ½ oz MEZCAL ESPADÍN DON HESIQUIO
                </li>
                <li className="list-item-cocteles">
                  1 rodada de naranja cortada en triángulos
                </li>
                <li className="list-item-cocteles">
                  Chile en polvo para escarchar
                </li>
                <li className="list-item-cocteles">Hielo</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">PREPARACI&Oacute;N</h3>
              <ul
                className="list-group list-group-numbered"
                style={{ textWrap: "balance" }}
              >
                <li className="p-0 ms-4 text-start">
                  Coloque hielo en un shaker. jugo de naranja, concentrado de 7
                  chiles, jarabe natural, mezcal joven espadín DON HESIQUIO y
                  triángulos de naranja
                </li>
                <li className="p-0 ms-4 text-start">
                  Cierre el shaker y agítelo vigorosamente durante 10 segundos
                </li>
                <li className="p-0 ms-4 text-start">
                  En un vaso old fashioned, escarchar medio vaso con chile en
                  polvo y verter el coctel sin colar
                </li>
              </ul>
            </div>
            <div style={{ paddingTop: "2rem" }}>
              <h3 className="px-4">CONCENTRADO DE FRESA Y CHILE (500 ML)</h3>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h4 className="px-4">INGREDIENTES</h4>
              <ul className="text-start list-container-group">
                <li className="list-item-cocteles">300 gramos de fesas</li>
                <li className="list-item-cocteles">250 ml de agua</li>
                <li className="list-item-cocteles">3 chiles de árbol</li>
                <li className="list-item-cocteles">50 g de azúcar</li>
                <li className="list-item-cocteles">
                  120 ml de jugo de naranja
                </li>
                <li className="list-item-cocteles">½ limon</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h4 className="px-4">PREPARACI&Oacute;N</h4>
              <ul
                className="list-group list-group-numbered"
                style={{ textWrap: "balance" }}
              >
                <li className="p-0 ms-4 text-start">
                  Licue 300 g de fresas con 250ml de agua hasta obtener una
                  preparación homogénea sin grumos
                </li>
                <li className="p-0 ms-4 text-start">
                  Cuele y vierta en una olla
                </li>
                <li className="p-0 ms-4 text-start">
                  Caliente a fuego bajo, agregue 3 chiles de árbol e infusionar
                  durante 1 minuto
                </li>
                <li className="p-0 ms-4 text-start">
                  Añada 50 g de azúcar y 120 ml de jugo de naranja, mezcle que
                  el azúcar se disuelva, deje hervir y retire del fuego, deje
                  enfriar a temperatura ambiente
                </li>
                <li className="p-0 ms-4 text-start">
                  Agregue el jugo de medio limón y vierta el concentrado de
                  fresa y chile en un recipiente con tapa. Consérvelo en
                  refrigeración hasta por 1 semana
                </li>
              </ul>
            </div>
          </div>
          <div className="container_video_coctel">
            <div className="row justify-content-center mt-4">
              <div className="col-10">
                <VideoPlayer
                  videoUrl="https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/DonHesiquio2.0/videos/Mezcal+Especial+3.0.mp4"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Coctel2;
