import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./style/global.css";
import "./style/style-landing.css";
import "./style/style-mezcales.css";
import "./style/style-contacto.css";
import "./style/style-elaboracion.css";
import "./style/style-terminos.css";
import "./style/style-presentacion.css";
import "./style/style-comprar.css";
import "./style/style-lista-productos.css";
import "./style/style-carrito.css";
import "./style/style-datos-envio.css";
import "./style/style-envio.css";
import "./style/style-finaliza-compra.css";

//componentes
import Terminos from "./pages/Terminos";
import { Origen } from "./pages/Origen";
import Mezcales from "./pages/Mezcales";
import Elaboracion from "./pages/Elaboracion";
import Contacto from "./pages/Contacto";
import PresentacionQR from "./pages/PresentacionQR";
import { DatosEnvio } from "./pages/DatosEnvio";
import ListaProductos from "./pages/ListaProductos";
import Comprar from "./pages/Comprar";
import { Envio } from "./pages/Envio";
import Carrito from "./pages/Carrito";
import { Pago } from "./pages/Pago";
import FinalizaCompra from "./pages/FinalizaCompra";
import { MyContext, MyProvider } from "./components/context/Context";
import { Eventos } from "./pages/Eventos";
import ScrollToTop from "./components/ScrollToTop";
import CardDatosEnvio from "./pages/CardDatosEnvio";
import Login from "./pages/Login";
import SidebarUsuario from "./pages/components/SidebarUsuario";
import Signup from "./pages/signup/Signup";
import Codigo from "./pages/Codigo/Codigo";
import EdicionNombre from "./pages/EdicionNombre";
import EdicionCorreo from "./pages/EdicionCorreo";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NewPassword from "./pages/NewPassword/NewPassword";
import DatosPersonales from "./pages/DatosPersonales/DatosPersonales";
import CorreoEnviado from "./pages/CorreoEnviado";
import EdicionTelefono from "./pages/EdicionTelefono";
import EdicionPassword from "./pages/EdicionPassword";
import MezcalEspadin from "./pages/TiposMezcal/MezcalEspadin";
import MezcalTobala from "./pages/TiposMezcal/MezcalTobala";
import CodigoDatosPersonales from "./pages/Codigo/CodigoDatosPersonales";
import SuccessEdicionCorreo from "./pages/Codigo/SuccessEdicionCorreo";
import Tienda from "./pages/Tienda/Tienda";
import PantallaExito from "./pages/PantallaExito";
import CodigoTelefono from "./pages/Codigo/CodigoTelefono";
import SidebarUsuarioMobile from "./pages/components/SidebarUsuarioMobile";
import SumarPuntos from "./pages/SumarPuntos/SumarPuntos";
import RegistrarPuntos from "./pages/SumarPuntos/RegistrarPuntos";
import RegistroEnviado from "./pages/SumarPuntos/RegistroEnviado";
import Cocteles from "./pages/Cocteles/Cocteles";
import Maridajes from "./pages/Maridajes/Maridajes";
import Coctel1 from "./pages/Cocteles/recetas/coctel1/Coctel1";
import Coctel2 from "./pages/Cocteles/recetas/coctel2/Coctel2";
import Coctel3 from "./pages/Cocteles/recetas/coctel3/Coctel3";
import CoctelesyMaridajes from "./pages/DatosPersonales/components/CoctelesyMaridajes";
import { getDatosUsuario } from "./components/context/Api";
import EdicionPasswordEmail from "./pages/EdicionPasswordEmail";
import { coctel1, coctel2, coctel3 } from "./assets/constants";

function App() {
  const { getSession, registrado, setRegistrado, setPuntosActuales } = useContext(MyContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!sessionStorage.getItem("pixelFired")) {
      // Inicializa Facebook Pixel
      ReactPixel.init("770394035103308", {}, { debug: true, autoConfig: false });

      // Dispara el evento pageView
      ReactPixel.pageView();

      console.log("React Pixel inicializado y evento pageView disparado");

      // Marca que el evento pageView se ha disparado
      sessionStorage.setItem("pixelFired", "true");
    }
  }, []);

  useEffect(() => {
    console.log("App.js: useEffect getSession");
    getSession()
      .then((data) => {
        console.log(data.email);
        sessionStorage.setItem("token", data.idToken.jwtToken);
        sessionStorage.setItem("emailUser", data.email);
        setRegistrado(true);
        setUserData(data);
        getDatosUsuario(data.email)
          .then((data) => {
           setPuntosActuales(data.puntos);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setRegistrado(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (userData != null) {
      sessionStorage.setItem("loggedIn", "true");
    } else {
      sessionStorage.setItem("loggedIn", "false");
    }
  }, [userData]);

  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route exact path="/" element={<Origen />} />
        <Route exact path="/Mezcales" element={<Mezcales />} />
        <Route exact path="/Contacto" element={<Contacto />} />
        <Route exact path="/Elaboracion" element={<Elaboracion />} />
        <Route exact path="/Terminos" element={<Terminos />} />
        <Route exact path="/PresentacionQR" element={<PresentacionQR />} />
        <Route exact path="/datos-envio" element={<DatosEnvio />} />
        <Route exact path="/envio" element={<Envio />} />
        <Route exact path="/ListaProductos" element={<Tienda />} />
        <Route exact path="/Eventos" element={<Eventos />} />
        <Route exact path="/Comprar" element={<Comprar />} />
        <Route exact path="/CompraExitosa" element={<FinalizaCompra />} />
        <Route exact path="/Carrito" element={<Carrito />} />
        <Route exact path="/pago" element={<Pago />} />
        <Route exact path="/datos-envio-card" element={<CardDatosEnvio />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/SidebarUsuario" element={<SidebarUsuario />} />
        <Route exact path="/SignUp" element={<Signup />} />
        <Route exact path="/ConfirmarCodigo" element={<Codigo />} />
        <Route exact path="/edicionNombre" element={<EdicionNombre />} />
        <Route exact path="/edicionCorreo" element={<EdicionCorreo />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/reset-password-email" element={<EdicionPasswordEmail /> } />
        <Route exact path="/new-password/:email/:token" element={<NewPassword />} />
        <Route exact path="/edicionTelefono" element={<EdicionTelefono />} />
        <Route exact path="/datosPersonales/:section" element={<DatosPersonales />} />
        <Route exact path="/correoEnviado" element={<CorreoEnviado />} />
        <Route exact path="/mezcal-espadin" element={<MezcalEspadin />} />
        <Route exact path="/mezcal-tobala" element={<MezcalTobala />} />
        <Route exact path="/pantalla-exito" element={<PantallaExito />} />
        <Route exact path="/edicionPassword" element={<EdicionPassword />} />
        <Route exact path="/edicionCorreoUser" element={<CodigoDatosPersonales />} />
        <Route exact path="/edicion-correo-success" element={<SuccessEdicionCorreo />} />
        <Route exact path="/codigo-telefono" element={<CodigoTelefono />} />
        <Route exact path="/datos-personales" element={<SidebarUsuarioMobile />} />
        <Route exact path="/sumar-puntos" element={<SumarPuntos />} />
        <Route exact path="/registrar-puntos" element={<RegistrarPuntos />} />
        <Route exact path="/registrar-puntos-success" element={<RegistroEnviado />} />
        <Route exact path="/cocteles" element={<Cocteles />} />
        <Route exact path="/cocteles/:receta" element={<Cocteles />} />
        <Route exact path="/maridajes" element={<Maridajes />} />
        <Route exact path={`/cocteles/${coctel1}`} element={<Coctel1 />} />
        <Route exact path={`/cocteles/${coctel2}`} element={<Coctel2 />} />
        <Route exact path={`/cocteles/${coctel3}`} element={<Coctel3 />} />
        <Route exact path="/cocteles-y-maridajes" element={<CoctelesyMaridajes />} />
      </Routes>
    </Router>
  );
}

export default App;
