import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ModalConfirmacion({ show, handleClose, onConfirm }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ❗</Modal.Title>
      </Modal.Header>
      <Modal.Body>¿Está seguro de que desea eliminar esta direccion de envío ?</Modal.Body>
      <Modal.Footer>
        <Button style={{ border: "none"}} variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button style={{backgroundColor: "#a78721", border: "none" }} variant="primary" onClick={onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmacion;
