import "./style/mis-pedidos.css";
import React from "react";

// hooks
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// assets
import shoppingBag from "./assets/shopping_bag.svg";

function MisPedidos() {
  const [pedidosData, setPedidosData] = useState();
  const [loading, setLoading] = useState(true);
  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getDatosPedidos";

  useEffect(() => {
    fetch(urlLambda, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        idUser: sessionStorage.getItem("idUser"),
        // idUser: 10,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("frefr" + JSON.stringify(data.message));
        const pedidos = data.message;
        setPedidosData(pedidos);
        setLoading((prev) => !prev);
      });
  }, []);
  return (
    <>
      {loading ? (
        <div className="mis-pedidos-main-container">
          <div className="container pt-5 p-lg-5">
            <div className="mis-pedidos-vacio-main-container container">
              <h3>Mis pedidos</h3>
              <p>Consulta el estado de tus compras</p>
              <h3>Cargando...</h3>
            </div>
          </div>
        </div>
      ) : (
        <>
          {pedidosData?.length == 0 ? (
            <div className="mis-pedidos-main-container">
              <div className="container pt-5 p-lg-5 ">
                <div className="mis-pedidos-tabla-container container">
                  <h3>Mis pedidos</h3>
                  <p>Consulta el estado de tus compras</p>
                </div>
                <div className="mis-pedidos-vacio-main-container container">
                  <div className="mis-pedidos-vacio-container">
                    <img
                      className="container mx-auto d-flex align-self-center pt-5"
                      src={shoppingBag}
                    />
                    <h2 className="container text-center pt-5">
                      ¡Empieza a comprar!
                    </h2>
                    <p className="text-center pt-5">
                      Agrega las botellas que te quieras llevar
                    </p>
                    <div className="container d-flex justify-content-center">
                      <Link to="/Mezcales">
                        <button
                          className="btn-lg d-flex align-self-center text-white"
                          style={{ backgroundColor: "#a78721" }}
                        >
                          Conocer Mezcales
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mis-pedidos-main-container overflow-scroll">
              <div className="container p-5">
                <div className="container">
                  <h3>Mis pedidos</h3>
                  <p>Consulta el estado de tus compras</p>
                </div>
                <div className="d-none d-sm-block">
                  <ul className="table-pedidos-title row">
                    <li className="col-sm">PEDIDO</li>
                    <li className="col-sm">FECHA</li>
                    <li className="col-sm">ESTADO DEL PAGO</li>
                    <li className="col-sm">ESTADO DEL PEDIDO</li>
                    <li className="col-sm">TOTAL</li>
                    <li className="col-sm">PUNTOS OBTENIDOS</li>
                  </ul>
                </div>
                {pedidosData?.map((pedido) => {
                  const fecha = new Date(pedido.fecha);
                  const meses = [
                    "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
                  ]
                  const fechaFormateada = `${fecha.getDate()} de ${meses[fecha.getMonth()]} de ${fecha.getFullYear()}`;

                  let idCompraStr = pedido.idCompra.toString();
                  while (idCompraStr.length < 5) {
                    idCompraStr = "0" + idCompraStr
                  }

                  return (
                    <div className="table-container">
                      <ul className="d-xs-block d-sm-none d-md-none d-lg-none row">
                      <li className="column-pedidos col-sm text-center text-secondary">Pedido</li>
                      <li className="column-pedidos col-sm text-center text-secondary">Fecha</li>
                      <li className="column-pedidos col-sm text-center text-secondary">Pago</li>
                      <li className="column-pedidos col-sm text-center text-secondary">Pedido</li>
                      <li className="column-pedidos col-sm text-center text-secondary">Total</li>
                      <li className="column-pedidos col-sm text-center text-secondary">Puntos</li>
                      </ul>
                      <ul className="row">
                        
                        <li className="column-pedidos col-sm overflow-hidden">
                          {/* {(pedido.idCompra < 1000) ? "#000" : "#00"}{pedido.idCompra} */}
                          {/* #{idCompraStr} */}
                          {pedido.ordenCompra !== null ? <p>{pedido.ordenCompra}</p>: <p style={{ color: "grey"}}>Sin n&uacute;mero de pedido</p>}
                        </li>
                        <li className="column-pedidos col-sm">
                          {fechaFormateada}
                        </li>
                        <li className="column-pedidos col-sm">
                          {pedido.estadoPago}
                        </li>
                        <li className="column-pedidos col-sm">
                          {pedido.estadoPedido}
                        </li>
                        <li className="column-pedidos col-sm">
                          {pedido.subTotal}
                        </li>
                        <li className="column-pedidos col-sm">
                          {pedido.puntosObtenidos} puntos
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MisPedidos;
