// css
import "../style/edicion-forms.css";

// components
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";

// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function EdicionPasswordEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();

  console.log(email);

  const urlLambda =
    "https://api.mezcaldonhesiquio.com/";

  const validarCorreo = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${urlLambda}validarCorreo`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.access === true) {
        console.log("usuario verificado");
        await crearTokenPassword(email);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const crearTokenPassword = async (email) => {
    try {
      const response = await fetch(`${urlLambda}crearTokenPassword`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      console.log(response);
      const data = await response.json();
      if (data.access === true) {
        const token = data.token;
        await enviarCorreoResetPassword(email, token);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const enviarCorreoResetPassword = async (email, token) => {
    try {
      const response = await fetch(`${urlLambda}enviarCorreoResetPassword`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: email,
          token: token,
        }),
      });

      const data = await response.json();
      if(data.access === true) {
        navigate(`/correoEnviado`)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="text-center mt-5">
          <h6
            style={{
              fontSize: "1.8rem",
              color: "#a78822",
              fontFamily: "Libre Baskerville",
              fontSize: "2.5rem",
            }}
          >
            Restablecer contrase&ntilde;a
          </h6>
          <p>
            Te enviaremos un correo electrónico para restablecer su
            contrase&ntilde;a.
          </p>
        </div>
        <div className="form-container mt-0">
          <form className="form-content" onSubmit={validarCorreo}>
            <label>Correo electr&oacute;nico</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <button className="form-button" type="submit">
              Enviar
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EdicionPasswordEmail;
