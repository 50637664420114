import React from "react";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import vasoimage from "../images/eventos/vaso.svg";
import { useLocation } from "react-router-dom";
import "../style/eventos.css"
export const Eventos = () => {
  let location = useLocation();
  return (
    <>
      <NavBar />
      <div className="datos-envio ">
        <div className="container">
          <div className="main_eventos row justify-content-center align-items-center  ">
            <div className="d-flex justify-content-center col-12 col-lg-2 ">
              <img src={vasoimage} alt="" />
            </div>
            <div className="col-12 col-lg-4 text-center">
              <h3 className="fs-3">Pr&oacute;ximamente</h3>
              <p>
                Falta poco para que lleguen {location.state.tipo} <br /> que harán
                mas grande tu amor por el mezcal
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
