import { Footer } from "../components/Footer";
import { BreadcrumbTrail } from "../components/BreadcrumbTrail";
import { formatPrice } from "../assets/functions";
//imagenes
import closeNegro from "../images/close-negro.svg";
import bolsaCompra from "../images/shopping_bagxd.svg";

import React, { useEffect, useState, useContext } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { NavBar } from "../components/NavBar";

import { productos, productos2 } from "../assets/constants";

//Componentes
import { OrderSummary } from "../components/OrderSummary";
import { useCost } from "../pages/DatosEnvio/Hooks/useCost";
import Puntos from "./components/Puntos";
import { MyContext } from "../components/context/Context";

export default function Carrito() {
  const { setItemsCarrito, registrado } = useContext(MyContext);
  let itemsLocal = parseInt(localStorage.getItem("totalItems")) ? parseInt(localStorage.getItem("totalItems")) : 0;

  const [totalItems, setTotalItems] = useState(itemsLocal);
  const [numeroItems, setNumeroITems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [puntosObtenidos, setPuntosObtenidos] = useState(0);
  const { costs } = useCost();

  const minusButtonClick = (e) => {
    e.preventDefault();
    setTotalItems((totalItems) => parseInt(totalItems) - 1);
    let numeroItemsTmp = [...numeroItems];
    numeroItemsTmp[e.target.id].items--;
    setNumeroITems(numeroItemsTmp);
    actualizarCarrito();
    actualizaSubTotal(numeroItemsTmp);
  };

  const plusButtonClick = (e) => {
    e.preventDefault();

      setTotalItems((totalItems) => parseInt(totalItems) + 1);
      let numeroItemsTmp = [...numeroItems];
      numeroItemsTmp[e.target.id].items++;
      setNumeroITems(numeroItemsTmp);
      actualizarCarrito();
      actualizaSubTotal(numeroItemsTmp);
  };

  const removeAllItems = (e) => {
    e.preventDefault();
    setTotalItems((totalItems) => totalItems - numeroItems[e.target.id].items);
    let numeroItemsTmp = [...numeroItems];
    numeroItemsTmp[e.target.id] = {
      ...numeroItemsTmp[e.target.id],
      items: 0,
    };
    setNumeroITems(numeroItemsTmp);
    actualizarCarrito();
    actualizaSubTotal(numeroItemsTmp);
  };

  function actualizarCarrito() {
    numeroItems.forEach((x) => {
      localStorage.setItem(x.id, x.items.toString());
    });
    localStorage.setItem("totalItems", totalItems.toString());
    setItemsCarrito(totalItems);
  }

  function aggAlCarritoEventApi() {
    const path = window.location.origin + window.location.pathname;
    const URL = "https://api.mezcaldonhesiquio.com/aggAlCarritoEventApi";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        productos: numeroItems,
        total: subTotal,
        url: path,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Evento enviado con exito a api de conversiones");
      });
  }

  function continuar() {
    actualizarCarrito();
    if (totalItems > 0) {
      aggAlCarritoEventApi();
    }
  }

  function actualizaSubTotal(numeroItemsTmp) {
    let subTotalTmp = 0;
    for (const x of numeroItemsTmp) {
      subTotalTmp += x.items * x.costo;
    }
    setSubTotal(subTotalTmp);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    var numeroItemsTmp = [];
    for (const producto of productos) {
      let itemsCarrito = localStorage.getItem(producto.id);
      if (!itemsCarrito) {
        itemsCarrito = 0;
      } else {
        itemsCarrito = parseInt(itemsCarrito);
      }
      if (itemsCarrito > 0) {
        numeroItemsTmp.push({
          id: producto.id,
          items: itemsCarrito,
          costo: producto.costo,
          imagen: producto.imagenItem,
          nombre: producto.nombreLargo,
        });
      }
    }

    for (const producto of productos2) {
      let itemsCarrito = localStorage.getItem(producto.id);
      if (!itemsCarrito) {
        itemsCarrito = 0;
      } else {
        itemsCarrito = parseInt(itemsCarrito);
      }
      if (itemsCarrito > 0) {
        numeroItemsTmp.push({
          id: producto.id,
          items: itemsCarrito,
          costo: producto.costo,
          imagen: producto.imagenItem,
          nombre: producto.nombre,
        });
      }
    }

    setNumeroITems(numeroItemsTmp);
    var totalItemsTmp = localStorage.getItem("totalItems");
    if (!totalItemsTmp) {
      setTotalItems(0);
    } else {
      parseInt(totalItemsTmp);
    }
    actualizaSubTotal(numeroItemsTmp);
  }, []);

  const calcularPuntos = (productos) => {
    let puntosTotales = 0;

    productos.forEach((producto) => {
      switch (producto.id) {
        case 1:
          puntosTotales += producto.items * 100;
          break;
        case 2:
          puntosTotales += producto.items * 150;
          break;
      }
    });
    setPuntosObtenidos(puntosTotales);

    return puntosTotales;
  };

  useEffect(() => {
    actualizarCarrito();
    if (registrado) {
      calcularPuntos(numeroItems);
    }
  }, [totalItems, numeroItems]);

  return (
    <>
      <NavBar />
      <div className={`${totalItems > 0 ? "_carrito_desktop" : "_carrito_desktop _carrito_desktop_mobile"} `}>
        <div className="carrito-producto">
          <div className="container carrito-productos--container  px-4 ">
            <div className="row">
              <div className="col-2">
                <span className="titulo-carrito">Carrito</span>
              </div>
            </div>
            <BreadcrumbTrail />
            <div className="row">
              <div className="mt-4">
                {totalItems > 0 ? (
                  <div className="contenedor-flex">
                    <div className="row container-blanco col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12">
                      {numeroItems &&
                        numeroItems.map((producto, index) => (
                          <>
                          {numeroItems[index].items > 0 && (
                          <div key={producto.id} className="p-4 productos-card">
                                <div className="carrito__producto row">
                                  <div className="carrito__producto__img--container ">
                                    <div className="carrito__producto__img">
                                      <div>
                                        <img src={producto.imagen} alt="" />
                                      </div>
                                      <div className="carrito__producto__nombre" style={{ textAlign: "left" }}>
                                        <div className="">
                                          <span className="nombre-del-producto ">{producto.nombre}</span>
                                          <div className="carrito__producto__costo-unidad--mobile">
                                            <small className="texto-negro-sans">
                                              {formatPrice(producto.costo)} por unidad
                                            </small>
                                          </div>
                                        </div>
                                        <div className="carrito__producto__costos--mobile">
                                          <div className="carrito__producto__subtotal--mobile">
                                            <span className="texto-negro-sans">
                                              <b>{formatPrice(producto.items * producto.costo)}</b>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="row carrito__producto__quitar--desktop">
                                          <button
                                            type="button"
                                            id={index}
                                            className="boton-quitar-items"
                                            style={{ textAlign: "left" }}
                                            onClick={removeAllItems}
                                          >
                                            Quitar del carrito
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="carrito__producto__cantidad--desktop">
                                    <div className="d-flex flex-row">
                                      <div
                                        className="d-flex flex-row"
                                        style={{
                                          border: "1px solid #E0E0E0",
                                        }}
                                      >
                                        <div className="p-1">
                                          <button
                                            type="button"
                                            id={index}
                                            onClick={minusButtonClick}
                                            className="boton-cantidad-items"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            −
                                          </button>
                                        </div>
                                        <div className="p-1">
                                          <input
                                            type="input"
                                            value={producto.items}
                                            className="input-cantidad-items"
                                            readOnly
                                          />
                                        </div>
                                        <div className="p-1">
                                          <button
                                            type="button"
                                            id={index}
                                            onClick={plusButtonClick}
                                            className="boton-cantidad-items"
                                            style={{ marginRight: "5px" }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="carrito__producto__quitar--mobile">
                                      <button
                                        type="button"
                                        id={index}
                                        className="boton-quitar-items"
                                        style={{ textAlign: "left" }}
                                        onClick={removeAllItems}
                                      >
                                        Quitar del carrito
                                      </button>
                                    </div>
                                  </div>
                                  <div className="carrito__producto__subtotal--desktop">
                                    <span className="texto-negro-sans  fs-5">
                                      {formatPrice(producto.items * producto.costo)}
                                    </span>
                                  </div>
                                </div>
                          </div>
                            )}
                            </>
                        ))}
                    </div>
                    <div className="contenedor_resumen_compra  p-0  h-100 row flex-wrap g-2 justify-content-between align-items-center col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-4 ">
                      <div className="p-0 col-12">
                        <OrderSummary items={costs} />
                      </div>
                      {registrado && (
                        <div className="container-blanco col-sm-12 col-md-12 col-lg-12 p-4">
                          <Puntos />
                        </div>
                      )}

                      <div className="col-12 p-0">
                        <Link to={`${registrado ? "/datos-envio-card" : "/datos-envio"}`}>
                          <button
                            style={{
                              borderStyle: "none",
                              backgroundColor: "#a78721",
                              color: "#ffffff",
                              width: "100%",
                              fontSize: "14px",
                              padding: "10px 0",
                              fontWeight: "300",
                              fontFamily: "Source Sans Pro",
                            }}
                            className="btn "
                            onClick={continuar}
                          >
                            Continuar
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sin-productos-container text-center container-blanco">
                    <img src={bolsaCompra} alt="" />
                    <h1 className="titulo-carrito-sin-productos mt-4">¡Empieza a comprar!</h1>
                    <p className="descripcion-carrito-sin-productos mt-4">Agrega las botellas que te quieras llevar</p>
                    <NavLink to="/ListaProductos" onClick={actualizarCarrito}>
                      <button className="boton-carrito-sin-productos mt-2">Conocer mezcales</button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
