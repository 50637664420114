import React, { useEffect, useState, useContext } from "react";
import { Footer } from "../../components/Footer";
import { BreadcrumbTrail } from "../../components/BreadcrumbTrail";
import { FormDatosEnvio } from "./components/FormDatosEnvio";
import { OrderSummary } from "../../components/OrderSummary";
import { useCost } from "./Hooks/useCost";
import { NavBar } from "../../components/NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import Puntos from "../components/Puntos";
import { MyContext } from "../../components/context/Context";

export const DatosEnvio = () => {
  const { registrado } = useContext(MyContext);
  const [isLocale, setIsLocale] = useState(false);
  const [dataForm, setDataForm] = useState();
  const [continuar, setContinuar] = useState(false);
  const nombre = sessionStorage.getItem("userName");
  const apellidos = sessionStorage.getItem("userLastName");
  const email = sessionStorage.getItem("emailUser");
  const { costs } = useCost(isLocale);
  const navigate = useNavigate();
  const [continuarMobile, setContinuarMobile] = useState(
    window.innerWidth < 992
  );

  const handleClick = () => {
    setContinuar(true);
  };

  useEffect(() => {
    function handleResize() {
      setContinuarMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (dataForm) {
      console.log(dataForm)
      console.log("entra en useEffect");
      localStorage.removeItem("datosEnvio");
      localStorage.setItem(
        "datosEnvio",
        JSON.stringify({
          DatosContactoObj: {
            nombre: dataForm.nombre,
            apellidos: dataForm.apellidos,
            celular: dataForm.celular,
            email: registrado ? email : dataForm.email,
          },
          DireccionEnvioObj: {
            calle: dataForm.calle,
            numExt: dataForm.numExt,
            numInt: dataForm.numInt,
            cp: dataForm.cp,
            colonia: dataForm.colonia,
            ciudad: dataForm.ciudad,
            estado: dataForm.estado,
            foraneo: !isLocale,
            costoEnvio: costs.shippingCost,
          },
          Costs: costs,
        })
      );
      navigate("/envio");
    }
  }, [isLocale, dataForm]);

  return (
    <>
    <NavBar />
      <main className="datos-envio contenedor-flex m-0">
        <section className="container col-12 p-0 ">
          <div className="mt-4 col-12 ">
            <h3>Datos de envío</h3>
          </div>
          <BreadcrumbTrail />
          <br />
          <div className="row justify-content-between flex-wrap ">
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8  mb-2">
              <FormDatosEnvio
                setDataForm={setDataForm}
                setIsLocale={setIsLocale}
                continuar={continuar}
                setContinuar={setContinuar}
              />
            </div>
            <div className="contenedor_resumen_compra m-0 p-0  h-100 d-flex flex-wrap gap-4 g-2 justify-content-center align-items-center col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-0 ">
                <OrderSummary items={costs} />
              </div>
              {registrado && (
                <div className="p-4 container-blanco  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <Puntos />
                </div>
              )}
            </div>
          </div>
          {continuarMobile ? (
            <div className=" row mx-auto mt-4">
              <button
                onClick={handleClick}
                type="submit"
                className="btn-primary--gold mx-auto w-100"
              >
                Continuar
              </button>
            </div>
          ) : (
            <div className=" row mx-auto mt-4">
              <button
                onClick={handleClick}
                type="submit"
                className="btn-primary--gold mx-auto "
              >
                Continuar
              </button>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};
