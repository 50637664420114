import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../../images/loader/Rolling-1s-200px-gold.svg";


function DatosPerfil() {
  const emailAuthUser = sessionStorage.getItem("authEmail");
  const emailUser = sessionStorage.getItem("emailUser");
  const [datosUsuario, setDatosUsuario] = useState(null);
  const [loading, setLoading] = useState(true);

  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getDatosUsuario";
  const getDatosUsuario = async () => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: emailAuthUser || emailUser,
        }),
      });

      if (response.ok) {
        setLoading(false);
        console.log(`Respuesta getDatosUsuario() exitosa`);
        const data = await response.json();
        setDatosUsuario(data.message[0]);
        sessionStorage.setItem("idUser", data.message[0].idUser);
        sessionStorage.setItem("userName", data.message[0].nombres);
        sessionStorage.setItem("userLastName", data.message[0].apellidos);
      } else {
        setLoading(true);
        console.log(`Hubo un error con getDatosUsuario()`);
      }
    } catch (error) {
      console.log(`Hubo un error en front getDatosUsuario()`);
    }
  };

  useEffect(() => {
    console.log(`Llamando a getDatosUsuario()...`);
    getDatosUsuario();
  }, []);

  useEffect(() => {
    console.log(`data de datosUsuario::: ${JSON.stringify(datosUsuario)}`);
  }, [datosUsuario]);

  return (
    <div className="main-container">
      {/* <img src={Loader} height="25px"/> */}

      <div className="datos-personales-main-container">
        <h3>DATOS PERSONALES</h3>
        <div className="dato-container">
          <div className="dato-info-container">
            <h5 className="dato-title">Nombre</h5>
            {/* <p>{datosUsuario?.nombres || <img src={Loader} width="25" />}</p> */}
            <p>
              {datosUsuario?.nombres && datosUsuario?.apellidos ? (
                `${datosUsuario.nombres} ${datosUsuario.apellidos}`
              ) : (
                <img src={Loader} width="25" />
              )}
            </p>
          </div>
          <div className="cambiar-dato-container">
            <NavLink to="/edicionNombre">
              <button className="cambiar-dato" disabled={datosUsuario == null}>
                Cambiar
              </button>
            </NavLink>
          </div>
        </div>
        <div className="dato-container">
          <div className="dato-info-container">
            <h5 className="dato-title">Correo electr&oacute;nico</h5>
            <p>{datosUsuario?.email || <img src={Loader} width="25" />}</p>
          </div>
          <div className="cambiar-dato-container">
            <NavLink to="/edicionCorreo">
              <button className="cambiar-dato" disabled={datosUsuario == null}>
                Cambiar
              </button>
            </NavLink>
          </div>
        </div>
        <div className="dato-container">
          <div className="dato-info-container">
            <h5 className="dato-title">Contraseña</h5>
            {loading ? <img src={Loader} width="25" /> : <p>**********</p>}
          </div>
          <div className="cambiar-dato-container">
            <NavLink to="/reset-password-email">
              <button className="cambiar-dato" disabled={datosUsuario == null}>
                Cambiar
              </button>
            </NavLink>
          </div>
        </div>
        <div className="dato-container">
          <div className="dato-info-container">
            <h5 className="dato-title">N&uacute;mero celular</h5>
            {datosUsuario?.telefono !== null ? (
              <p>{datosUsuario?.telefono || <img src={Loader} width="25" />}</p>
            ) : (
              <p>No hay tel&eacute;fono asociado</p>
            )}
          </div>
          <div className="cambiar-dato-container">
            <NavLink to="/edicionTelefono">
              {datosUsuario?.telefono !== null ? (
                <button
                  className="cambiar-dato"
                  disabled={datosUsuario == null}
                >
                  Cambiar
                </button>
              ) : (
                <button className="cambiar-dato">Agregar</button>
              )}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatosPerfil;
