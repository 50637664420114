import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MyProvider } from "./components/context/Context";
import ReactGA from 'react-ga';

ReactGA.initialize('AW-16577468518');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <MyProvider>
        <App />
    </MyProvider>
  
);




reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

