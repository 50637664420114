import React, { useEffect, useContext, useState } from "react";
import { Footer } from "../../components/Footer";
import { BreadcrumbTrail } from "../../components/BreadcrumbTrail";
import { OrderSummary } from "../../components/OrderSummary";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/NavBar";
import icon from "../../images/icon-datos-envio.svg";
import Puntos from "../components/Puntos";
import { MyContext } from "../../components/context/Context";

export const Envio = () => {
  const datosEnvio = JSON.parse(localStorage.getItem("datosEnvio"));
  console.log(datosEnvio)
  const costoEnvio = "GRATIS";
  const nombre = `${datosEnvio.DatosContactoObj.nombre} ${datosEnvio.DatosContactoObj.apellidos}`;
  const direccion = `${datosEnvio.DireccionEnvioObj.calle}, ${datosEnvio.DireccionEnvioObj.colonia}, ${datosEnvio.DireccionEnvioObj.cp}, ${datosEnvio.DireccionEnvioObj.estado}, México`;
  const { registrado } = useContext(MyContext);
  const [continuarMobile, setContinuarMobile] = useState(
    window.innerWidth < 992
  );

  useEffect(() => {
    function handleResize() {
      setContinuarMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <main className="datos-envio ">
        <div className="container">
          <h3 className="py-4">Forma de envío</h3>
          <BreadcrumbTrail />
          <br />
          <section className="datos-envio__content">
            <form className="datos-envio__form">
              <div className="datos-envio__form--envio--container row">
                <section className="datos-envio__form__container envio">
                  <div className="col-10 d-flex align-items-center gap-2  ">
                    <img src={icon} alt="" width="20px" height="20px" />
                    <div className="d-flex flex-column col-12 w-100 ">
                      <div className=" w-100 texto-negro-sans">
                        Datos de envío
                      </div>
                      <div className="">
                        <span className="">{nombre}</span>
                        <br />
                        <span className="texto-transparente">{direccion}</span>
                      </div>
                    </div>
                  </div>
                  <div className="datos-envio__form--envio__cambio">
                    <Link
                      to="/datos-envio"
                    >
                      Cambiar
                    </Link>
                  </div>
                </section>
              </div>
              <div className="datos-envio__form__select-envio row">
                <h2 className="envio__form__title">Envíos</h2>
                {!datosEnvio.DireccionEnvioObj.foraneo ? (
                  <section className="datos-envio__form__container envio select-envio">
                    <input
                      type="checkbox"
                      id="tresGuerras"
                      className="datos-envio__form__container--checkbox"
                      checked
                    />
                    <label htmlFor="tresGuerras">
                      Entrega local
                      <p>
                        <small>Tiempo de entrega: 12 a 72 horas</small>
                      </p>
                    </label>
                    <small>Gratis</small>
                  </section>
                ) : (
                  <section className="datos-envio__form__container envio select-envio">
                    <input
                      type="checkbox"
                      id="local"
                      className="datos-envio__form__container--checkbox"
                      checked
                    />
                    <label htmlFor="local">
                      Paquetería
                      <p>
                        <small>Tiempo de entrega: 2 a 7 días</small>
                      </p>
                    </label>
                    <small>{costoEnvio}</small>
                  </section>
                )}
              </div>
              {continuarMobile && (
                <div className="row p-0 d-flex flex-wrap gap-2 mt-4">
                  <div className="col-12 p-0 m-0">
                    <OrderSummary items={datosEnvio.Costs} />
                  </div>
                  {registrado && (
                    <div className="container-blanco p-4 col-12  m-0">
                      <Puntos />
                    </div>
                  )}
                </div>
              )}
              <div className="datos-envio__form__buttons">
                <Link
                  to={`${registrado ? "/datos-envio-card" : "/datos-envio"}`}
                >
                  <button className="btn-secondary--black">Regresar</button>
                </Link>
                <Link to="/pago">
                  <button className="btn-primary--gold">Continuar</button>
                </Link>
              </div>
            </form>
            {!continuarMobile && (
              <div className="d-flex flex-wrap gap-2">
                <div className="col-12">
                  <OrderSummary items={datosEnvio.Costs} />
                </div>
                {registrado && (
                  <div className="container-blanco p-4 col-12">
                    <Puntos />
                  </div>
                )}
              </div>
            )}
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};
