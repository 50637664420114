import "./style.css";
import React, { useEffect, useState, useContext } from "react";
import { useCost } from "../DatosEnvio/Hooks/useCost";
import { Footer } from "../../components/Footer";
import { BreadcrumbTrail } from "../../components/BreadcrumbTrail";
import { FormPago } from "./components/FormPago";
import { Ring } from "@uiball/loaders";
import { NavBar } from "../../components/NavBar";
import { MyContext } from "../../components/context/Context";
import { Modal, ModalBody } from "reactstrap";

export const Pago = () => {
  const [loading, setLoading] = useState(true);
  const { registrado } = useContext(MyContext);
  const datosEnvio = JSON.parse(localStorage.getItem("datosEnvio"));
  const isLocal = !datosEnvio.DireccionEnvioObj.foraneo;
  const { costs } = useCost(isLocal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="">
        {loading ? (
          <>
              <div className="pago__loader--container">
                <h3>
                  Estamos procesando su pago.
                  <br />
                  Por favor, espere.
                </h3>
                <Ring size={80} lineWeight={5} speed={2} color="#a78721" />
              </div>
          </>
        ) : null}
        <div className="datos-envio">
          <main className="container">
            <h1 className="datos-envio__title texto-oro">Medio de pago</h1>
            <BreadcrumbTrail />
            <div className="">
              <section className="p-0">
                <FormPago
                  loading={loading}
                  setLoading={setLoading}
                  costs={costs}
                />
              </section>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
};
