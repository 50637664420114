import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../components/context/Context";
import "./styles.css";

function Puntos() {
  const { setChecked, checked, puntosActuales, setPuntosActuales, registrado } = useContext(MyContext);
  const idUser = sessionStorage.getItem("idUser");
  const [fechaVencimiento, setFechaVencimiento] = useState(sessionStorage.getItem("fechaVencimientoPuntos"));
  const day = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const fechaActual = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
  const [puntosVencidos, setPuntosVencidos] = useState(false);
  const email = registrado ? sessionStorage.getItem("emailUser") : "";

  const handleChangeBox = (event) => {
    setChecked(event.target.checked);
  };

  const reiniciarPuntos = () => {
    const URL = "https://api.mezcaldonhesiquio.com/puntosVencidos";
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        idUser: idUser,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        sessionStorage.removeItem("fechaVencimientoPuntos");
      });
  };


  useEffect(() => {
    console.log(fechaVencimiento);
    console.log(fechaActual);
    if (fechaActual >= fechaVencimiento) {
      console.log("Puntos vencidos");
      setChecked(false);
      setPuntosVencidos(true);
      sessionStorage.setItem("puntos", 0);
      setPuntosActuales(0);
      reiniciarPuntos();
      setFechaVencimiento("Tus puntos han vencido");
    }
  }, [fechaVencimiento]);

  useEffect(() => {
    if (checked) {
      console.log(puntosActuales);
    }
  }, []);

  return (
    <div className="row g-2 align-items-center ">
      <span className="texto-negro-sans fs-5 col-6">Puntos actuales</span>
      <span className={`col-6 text-end ${checked ? "text-success" : ""}`}>
        {!checked ? puntosActuales : "Puntos aplicados"}
      </span>
      {puntosVencidos || puntosActuales == 0 ? (
        <span className="fecha-vencimiento-puntos col-12 text-end ">No tienes puntos</span>
      ) : (
        <span className="fecha-vencimiento-puntos col-12 text-end ">tus puntos vencen el {fechaVencimiento}</span>
      )}
      {puntosVencidos || puntosActuales == 0 ? null : (
        <div className="d-flex align-items-center gap-2">
          <div className="col-10 text-end">
            <span className={`${puntosActuales == 0 ? "text-danger" : " texto-negro-sans"}`}>
              Usar puntos para pagar
            </span>
          </div>
          <div className="col-2">
            <input
              checked={checked}
              onChange={handleChangeBox}
              className="react-switch-checkbox"
              id={`react-switch-new`}
              type="checkbox"
              disabled={puntosActuales == 0 || puntosVencidos}
            />
            <label
              className="react-switch-label"
              htmlFor={`react-switch-new`}
              style={{ background: checked && "#a7882178" }}
            >
              <span className={`react-switch-button`} />
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default Puntos;
