import React, { useEffect, useRef, useState, useContext } from "react";
import logo from "../assets/sello-selection.png";
import arrow from "../assets/arrow-carrusel.svg";
import { Link } from "react-router-dom";
import { MyContext } from "../../../components/context/Context";
import logoDH from "../../../images/logo-dorado-white.svg";
import ModalCarrusel from "../../../components/Modal/ModalCarrusel";

export const HeroHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);
  const { registrado } = useContext(MyContext);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 7000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const handleClick = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 7000);
  };

  return (
    <header className="hero__carrusel">
      <div
        className="hero__carrusel--container"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
         <section className="hero promo-mezcal">
          <div></div>
          <div className="hero__overlay">
            <img src={logoDH} />
            <h1 className="section__title">MEZCAL ARTESANAL JOVEN</h1>
            <p className="hero__description"
            style={{ fontSize: "2.3rem", fontFamily: "Libre-Baskerville"}}>
             ¿Ya conoces <span style={{ fontWeight: "bold"}}>nuestro</span> <span style={{ fontStyle: "italic"}}>sistema de puntos</span>?
            </p>
             <ModalCarrusel />
          </div>
        </section>
        <section className="hero tobala text-shadow">
          <div
            className="hero-tobala-video text-center"
            dangerouslySetInnerHTML={{
              __html: `
              <video
              alt="Botella de mezcal tobala"
              playsinline
              muted
              loop
              autoPlay
            >
            <source src="https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/DonHesiquio2.0/videos/prueba.mp4" /></video>
            `,
            }}
          ></div>
          <div className="hero__overlay">
            <h1 className="section__title">MEZCAL DON HESIQUIO TOBALÁ</h1>
            <p className="hero__description">
              Revelación y ganador de la Medalla de Plata en el México Selección
              by CMB YUCATÁN 2023
            </p>

            <Link
              className="button--gold"
              to="/ListaProductos"
              preventScrollReset={false}
            >
              Descubre m&aacute;s
            </Link>
          </div>
        </section>
        <section className="hero espadin">
          <div></div>
          <div className="hero__overlay">
            <h1 className="section__title">MEZCAL DON HESIQUIO ESPADÍN</h1>
            <p className="hero__description">
              Cristalino, con notas suaves de aroma a agave cocido, sutil sabor
              ahumado y con matices cítricos
            </p>

            <Link
              className="button--gold"
              to="/ListaProductos"
              preventScrollReset={false}
            >
              Descubre m&aacute;s
            </Link>
          </div>
        </section>
        <div className="hero puntos hero-container-puntos">
          <div
            className="hero-puntos-video text-center"
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{
              __html: `
              <video
              alt="Botella de mezcal tobala"
              playsinline
              muted
              loop
              autoPlay
            >
            <source src="https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/DonHesiquio2.0/videos/DHvideo2.mp4" /></video>
            `,
            }}
          >
          </div>
          <div className="hero__overlay_puntos">
            <div className="hero_description_puntos">
              <h1 className="section__title mb-4">
                MEZCAL DON HESIQUIO TOBAL&Aacute;
              </h1>
              <p className="hero__description mb-4">
                Cautivador con notas herbales y frutales, finalizando en toques
                tostados y ahumados. Su transparencia revela una excepcional
                franqueza herbal para una experiencia única.
              </p>

              <Link
                className="button--gold"
                to="/ListaProductos"
                preventScrollReset={false}
              >
                Descubre m&aacute;s
              </Link>
            </div>
          </div>
        </div>
      </div>
      <button
        className="button--navigate arrow-left"
        onClick={() => {
          setCurrentIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
          handleClick();
        }}
      >
        <img src={arrow} alt="" />
      </button>
      <button
        className="button--navigate arrow-right"
        onClick={() => {
          setCurrentIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
          handleClick();
        }}
      >
        <img src={arrow} alt="" />
      </button>
    </header>
  );
};
