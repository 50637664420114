import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import arrowPath from "../../images/arrow-compra.svg";
import { MyContext } from "../context/Context";

import "./style.css";

export const BreadcrumbTrail = () => {
  const { registrado } = useContext(MyContext);
  const location = useLocation();
  const [activePaths, setActivePaths] = useState([]);

  let paths = [
    { name: "Carrito", path: "/carrito" },
    { name: "Datos de envío", path: "/datos-envio" }, // Siempre incluir "/datos-envio"
    { name: "Envío", path: "/envio" },
    { name: "Pago", path: "/pago" },
  ];

  if (registrado) {
    paths.splice(2, 0, { name: "Datos guardados", path: "/datos-envio-card" }); // Agregar en la posición correcta
  }

  useEffect(() => {
    const currentPathIndex = paths.findIndex(
      (path) => path.path === location.pathname
    );
    setActivePaths(paths.slice(0, currentPathIndex + 1));
  }, [location, registrado, paths]);

  return (
    <div className="breadcrumb-trail">
      {activePaths.map((path, i) => (
        <div className="breadcrumb-trail__item d-flex gap-2 text-center" key={path.path}>
          <Link to={path.path} className="breadcrumb-trail__link texto-oro">
            <span>{path.name}</span>
          </Link>
          {i < activePaths.length - 1 && (
            <img
              className="breadcrumb-trail__arrow"
              src={arrowPath}
              alt="flecha del camino"
            />
          )}
        </div>
      ))}
    </div>
  );
};
