import "../../style/sumar-puntos.css";

// componentes
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { MyContext } from "../../components/context/Context";

// hooks
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
// imagenes
import clockIcon from "../../images/puntos/clock-icon.svg";
import checkIcon from "../../images/icons/check_icon.svg";

export default function RegistroEnviado() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPuntosActuales } = useContext(MyContext);

  const handleRegresar = () => {
    navigate("/");
  };


  const urlLambda =
  "https://api.mezcaldonhesiquio.com/getDatosUsuario";

  const getDatosUsuario = async () => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("emailUser")
        })
      })

      const data = await response.json();

      console.log(data)
      console.log(data.message[0].puntos)
      const nuevosPuntos = data.message[0].puntos;

      if(nuevosPuntos) {
        setPuntosActuales(nuevosPuntos)
      }

    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDatosUsuario()
  }, [])

  return (
    <>
      <NavBar />
      <div className="sumar-puntos-main-container w-100">
        <div className="container text-center pt-3">
          {location.state.tipo === "numero" ? (
            <>
              <div className="container d-flex justify-content-center pt-5">
                <img src={checkIcon} />
              </div>
              <h1 className="suma-puntos-title">Registro exitoso</h1>
              <p>
                Se ha registrado correctamente la compra, tus puntos se han
                actualizado
              </p>
            </>
          ) : (
            <>
              <div className="container d-flex justify-content-center pt-5">
                <img src={clockIcon} />
              </div>
              <h1 className="suma-puntos-title">Ticket enviado</h1>
              <p>
                Estamos revisando tu informaci&oacute;n. En cuanto terminemos,
                te <br />
                avisaremos del total de tus puntos.
              </p>
            </>
          )}
        </div>
        <div className="container d-flex justify-content-center">
          <button
            className="mx-auto btn-lg"
            style={{ backgroundColor: "#a78721", color: "#fff" }}
            onClick={handleRegresar}
          >
            Ir al inicio
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
