import "../style/edicion-forms.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";

function EdicionNombre() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nombres: "",
    apellidos: "",
    idUser: sessionStorage.getItem("idUser"),
  });

  useEffect(() => {
    const valorUserName = sessionStorage.getItem("userName");
    const valorUserLastName = sessionStorage.getItem("userLastName");

    if (valorUserName || valorUserLastName) {
      setFormData({
        nombres: valorUserName,
        apellidos: valorUserLastName,
        idUser: sessionStorage.getItem("idUser"),
      });
    }
  }, []);

  const urlLambda =
    "https://api.mezcaldonhesiquio.com/editarNombre";

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(`Esta es el data: ${data}`);
        navigate("/datosPersonales/datos");
      } else {
        console.log(`Error en el request: ${response.error}`);
      }
    } catch (error) {
      console.log(`Hubo un error: ${error}`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="form-container">
          <h6>Elige tu nombre</h6>
          <p>Dinos como quieres que te llamemos</p>
          <form className="form-content" onSubmit={handleSubmit}>
            <label>Nombres(s)</label>
            <input
              type="text"
              name="nombres"
              value={formData.nombres}
              onChange={handleChange}
            ></input>
            <label>Apellido(s)</label>
            <input
              type="text"
              name="apellidos"
              value={formData.apellidos}
              onChange={handleChange}
            ></input>
            <button className="form-button" type="submit">
              Guardar
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EdicionNombre;
