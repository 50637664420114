import React, { useState, useEffect, useContext } from "react";
import ReactGA from 'react-ga';
import openpay from "../../../images/openpay-logo.png";
import mc from "../../../images/mastercard.png";
import visa from "../../../images/visa.png";
import ae from "../../../images/americanExpress.png";
import closeNegro from "../../../images/close-negro.svg";
import { Link, useNavigate } from "react-router-dom";
import { formatPrice } from "../../../assets/functions";
import { generaIdOrden } from "../../../assets/functions";
import { getDateForValidate } from "../../../assets/functions";
import { getMensajeError } from "../../../assets/functions";
import { generaDescripcion } from "../../../assets/functions";
import { getStatusFactura } from "../../../assets/functions";
import { openPayKey } from "../../../assets/constants.js";
import { cfdi } from "../../../assets/constants.js";
import { regimenes } from "../../../assets/constants.js";
import { formasPago } from "../../../assets/constants.js";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import ReactPixel from "react-facebook-pixel";
import { productos } from "../../../assets/constants.js";
import { MyContext } from "../../../components/context/Context.js";
import { getDatosUsuario } from "../../../components/context/Api.js";
import icon from "../../../images/icon-datos-envio.svg";
import envioIcon from "../../../images/envio-envio-icon.svg";
import { OrderSummary } from "../../../components/OrderSummary.jsx";
import Puntos from "../../components/Puntos.js";

export const FormPago = ({ loading, setLoading, costs }) => {
  const [facturar, setFacturar] = useState(false);
  const [botonBloqueado, setBotonBloqueado] = useState(false);
  const [modalMensaje, setModalMensaje] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState(false);

  const [numeroItems, setNumeroItems] = useState([]);
  const [estados, setEstados] = useState([]);
  const { registrado, checked, puntosActuales ,descuentoPuntos,productFree,chargueWithPoints,habilitoPago, montoDescuento ,montoProductos ,montoEnvio} = useContext(MyContext);
  const [continuarMobile, setContinuarMobile] = useState(window.innerWidth < 992);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const datosEnvio = JSON.parse(localStorage.getItem("datosEnvio"));
  const [puntosObtenidos, setPuntosObtenidos] = useState(0);
  const total = datosEnvio.Costs.total;
  const costoEnvio = "GRATIS";
  const idUsuario = sessionStorage.getItem("idUser");
  const nombre = `${datosEnvio.DatosContactoObj.nombre} ${datosEnvio.DatosContactoObj.apellidos}`;
  const numeroCliente = datosEnvio.DatosContactoObj.celular;
  const correoCliente = datosEnvio.DatosContactoObj.email;
  const emailUser = sessionStorage.getItem("emailUser");
  const direccion = `${datosEnvio.DireccionEnvioObj.calle}, ${datosEnvio.DireccionEnvioObj.colonia}, ${datosEnvio.DireccionEnvioObj.cp}, ${datosEnvio.DireccionEnvioObj.estado}, México`;
  const tipoEnvio = datosEnvio.DireccionEnvioObj.foraneo ? `Paquetería, ${costoEnvio}` : "Entrega local, GRATIS";
  const [infDePago, setInfDePago] = useState({
    nombre: nombre,
    tarjeta: "",
    direccion: direccion,
  });

  const toggleMensaje = () => setModalMensaje(!modalMensaje);
  const navigate = useNavigate();
  var deviceSessionId = "";
  var facturacionObj = {
    rfc: "",
    razon: "",
    cfdi: "",
    regimenFiscal: "",
    domicioFiscal: "",
    formaPago: "",
  };

  const agregaSlash = (event) => {
    event.preventDefault();
    const regexx = /^[0-9]*\/?[0-9]*$/;
    var valorIngresado = event.target.value;
    var valoringresadoTotal = event.target.value.slice(-1);

    if (regexx.test(valoringresadoTotal)) {
      document.getElementById("fechav").value = event.target.value;
      if (valorIngresado.length === 2 && event.key === "Backspace") {
        //console.log("permite la ejecucion de borrado")
        return;
      }
      if (valorIngresado.length === 2) {
        document.getElementById("fechav").value = document.getElementById("fechav").value + "/";
      }
    } else {
      document.getElementById("fechav").value = valorIngresado.slice(0, -1);
    }
  };

  const validateMonth = (value) => {
    const fechaBase = getDateForValidate();
    const monthBase = parseInt(fechaBase.substring(0, 2));
    const yearBase = parseInt(fechaBase.substring(2, 5));
    const month = parseInt(value.substring(0, 2));
    const year = parseInt(value.substring(3, 5));

    if (!(month > 0 && month <= 12)) {
      return "Mes no valido";
    }
    if (!(year >= fechaBase.substring(2, 4))) {
      return "La tarjeta ha expirado ";
    }
    if (year === yearBase && month <= monthBase) {
      return "La tarjeta ha expirado";
    }
    return true;
  };




  function registroCompraPuntos(montoTotal,idOrdenProducto,datosUsuario,descripcionCompra,direccionEnvio,requiereFactura,facturacionObj,paqueteria,
    precios, descuentoPuntos){
  var url = "https://api.mezcaldonhesiquio.com/generaCompraPorPuntos";


    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        monto: montoTotal,
        idOrden: idOrdenProducto,
        cliente: datosUsuario,
        descripcion: descripcionCompra,
        datosEnvio: direccionEnvio,
        facturar: requiereFactura,
        facturacion: facturacionObj,
        paqueteria: paqueteria,
        datosCompra: precios,
        aplicoPuntos:productFree? "SI":"NO",
        puntosAplicados : productFree ? puntosActuales - descuentoPuntos :0,
        puntosAcomulados: puntosActuales,
        puntosPorCompra: puntosObtenidos,
        puntosGastados: puntosActuales - descuentoPuntos,
        puntosTotales: descuentoPuntos + puntosObtenidos,
        montoProductos: montoProductos,
        montoEnvio: montoEnvio,
        montoDescuento: (puntosActuales - descuentoPuntos)* 0.4,
        emailUser: sessionStorage.getItem("emailUser")
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        
      })
      .catch((err) => {
        console.log(err.message);
        setFacturar(false);
        setLoading(false);
        setBotonBloqueado(false);
        setMensajeAlerta("Error técnico, favor de comunicarse con soporte técnico al teléfono: 5565747493 ");
        toggleMensaje();
      });

    }

    function getAllPuntosTotales(){
      var temp =0;
      if (checked && registrado && chargueWithPoints && descuentoPuntos==0){
        temp =puntosObtenidos;
      }

      else if (checked && registrado && productFree  )
      {
        temp =descuentoPuntos +puntosObtenidos;
      }

      else if (!checked && registrado)
        {
          temp =puntosActuales + puntosObtenidos;
        }
        else if (checked && !registrado){
          temp =0;
        }
        else {
          temp =0;
    
        }
        return temp;
    }

  const onSuccess = (response) => {
    setBotonBloqueado(true);
    const procesoCompleto = 0;
    const transaccionFallidaBanco = 1;
    const falloEnvioCorreoCont = 2;
    const falloEnvioCorreoFull = 3;
    const ErrorTransaccionOpenPay = 4;

    const objetosCompra = JSON.parse(localStorage.getItem("datosEnvio"));

    var datosUsuario = objetosCompra.DatosContactoObj;
    var direccionEnvio = objetosCompra.DireccionEnvioObj;
    var precios = objetosCompra.Costs;
    var idOrdenProducto = generaIdOrden();
    var descripcionCompra = generaDescripcion(precios);
    var paqueteria = direccionEnvio.foraneo ? "Foraneo" : "Local";
    var montoTotal = precios.total.toFixed(2);
    var requiereFactura = getStatusFactura(facturar);
    var temp =getAllPuntosTotales();


    const objectDesgloce ={
      "montoProductos":montoProductos,
      "montoEnvio":  montoEnvio  ,
      "montoDescuento":checked?((puntosActuales - descuentoPuntos)* 0.4).toFixed(2):0.00,
      "montoFinal":montoTotal,
      "aplicaPuntos": checked?"SI":"NO",
      "puntosAcomulados": registrado?puntosActuales:0,
      "puntosPorCompra":puntosObtenidos,
      "puntosGastados":checked?(puntosActuales - descuentoPuntos):0 ,
      "puntosTotales": temp
    }
    localStorage.setItem("objSummary",JSON.stringify(objectDesgloce));

    if (productFree)
    {
      comprarEventoApi();
      registroCompraPuntos( montoTotal,idOrdenProducto,datosUsuario,descripcionCompra,direccionEnvio,requiereFactura,facturacionObj,paqueteria,precios,descuentoPuntos);
      localStorage.setItem ("skipBankOperation",true);
      localStorage.setItem ("idOrden",idOrdenProducto);
      localStorage.setItem ("descripcion",descripcionCompra);
      guardarProductosComprados(2,"/CompraExitosa", idOrdenProducto);
      setLoading(false);
      return;
    }
    
    localStorage.setItem ("skipBankOperation",false);
    var url = "https://api.mezcaldonhesiquio.com/generaCargo3d";

    if (precios.subTotal.costo === 0) {
      setBotonBloqueado(false);
      setLoading(false);
      setMensajeAlerta("Favor de seleccionar un producto");
      toggleMensaje();
      return;
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        token: response.data.id,
        deviceSessionId: deviceSessionId,
        monto: montoTotal,
        idOrden: idOrdenProducto,
        cliente: datosUsuario,
        descripcion: descripcionCompra,
        datosEnvio: direccionEnvio,
        facturar: requiereFactura,
        facturacion: facturacionObj,
        paqueteria: paqueteria,
        datosCompra: precios,
        aplicoPuntos:productFree? "SI":"NO",
        puntosAplicados : productFree ? puntosActuales - descuentoPuntos :0
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // proceso completo
        if (
          data.paymentProcessing &&
          data.url &&
          (data.typeError === procesoCompleto ||
            data.typeError === falloEnvioCorreoCont ||
            data.typeError === falloEnvioCorreoFull)
        ) {
          setLoading(false);
          comprarEventoApi();
          guardarProductosComprados(2, data.url, idOrdenProducto);

          ReactGA.event({
            category: 'conversion',
            action: 'purchase',
            label: 'AW-16577468518/ttkMCIqkhLMZEOaw4OA9',
            value: montoTotal,
            currency: 'MXN',
            transaction_id: idOrdenProducto
          });

          return;
        }
        //no procede la transaccion Genera Error Open Pay
        if (!data.paymentProcessing && data.typeError === ErrorTransaccionOpenPay) {
          comprarEventoApi();
          setLoading(false);
          setBotonBloqueado(false);
          var mensaje = getMensajeError(data.message.error_code);
          setMensajeAlerta(mensaje);
          toggleMensaje();
        }
        //no procede la transaccion Genera Error banco
        if (!data.paymentProcessing && data.typeError === transaccionFallidaBanco) {
          comprarEventoApi();
          setLoading(false);
          setBotonBloqueado(false);
          setMensajeAlerta(data.message);
          toggleMensaje();
        }

        if (data.typeError == 0) {
          guardarProductosComprados(0);
          return;
        }
        if (data.typeError == 1) {
          guardarProductosComprados(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setFacturar(false);
        setLoading(false);
        setBotonBloqueado(false);
        setMensajeAlerta("Error técnico, favor de comunicarse con soporte técnico al teléfono: 5565747493 ");
        toggleMensaje();
      });



  };

  const onError = (response) => {
    setLoading(false);
    setBotonBloqueado(false);
    console.log(response);
    var mensaje = getMensajeError(response.data.error_code);
    const lineasMensaje = mensaje.split("*").map((linea, index) => {
      const contenidoLinea = linea.trim();
      if (contenidoLinea === "") {
        return null;
      }
      return <p key={index}>{contenidoLinea}</p>;
    });

    setMensajeAlerta(lineasMensaje);
    toggleMensaje();
  };

  function toggleFacturar() {
    setFacturar((facturar) => !facturar);
  }

  function addPaymentInfoEventApi() {
    const path = window.location.origin + window.location.pathname;
    const URL = "https://api.mezcaldonhesiquio.com/addPaymentInfoEventApi";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        correoCliente: correoCliente,
        numero: numeroCliente,
        url: path,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Evento enviado con exito a api de conversiones");
      });
  }

  function getEstados() {
    const URL = "https://api.mezcaldonhesiquio.com/getEstados";
    fetch(URL)
      .then((res) => res.json())
      .then((data) => {
        setEstados(data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const calcularPuntos = (productos) => {
    let puntosTotales = 0;

    productos.forEach((producto) => {
      switch (producto.id) {
        case 1:
          puntosTotales += producto.items * 100;
          break;
        case 2:
          puntosTotales += producto.items * 150;
          break;
      }
    });
    setPuntosObtenidos(puntosTotales);
    return puntosTotales;
  };

  useEffect(() => {
    // if (registrado) {
      calcularPuntos(numeroItems);
    // }
  }, [numeroItems]);

  useEffect(() => {
    function handleResize() {
      setContinuarMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function insertarLogPuntos(req, res, descripcion) {
    const URL = "https://api.mezcaldonhesiquio.com/insertarLogs";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        sistema: "PagMezcal",
        proceso: "PagoPuntos",
        tipo: "control",
        xml_in: req,
        xml_out: res,
        descripcion: descripcion,
        usuario: registrado ? emailUser : "",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: index.jsx:89 ~ .then ~ data:", data);
        console.log("Log de carrito insertado con exito");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function guardarProductosComprados(opcion, redirectURL, idOrdenProducto) {
    const datosEnvio2 = JSON.parse(localStorage.getItem("datosEnvio"));

    const today = new Date();
    let body = {};
    let fechaActual =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0");
    let horaActual = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds().toString();
    let fechaCompleta = `${fechaActual} ${horaActual}`;

    if (!registrado) {
      //para usuarios generales
      const URL = "https://api.mezcaldonhesiquio.com/guardarProductosComprados";
      body = {
        detallesCompra: datosEnvio2.Costs,
        idDatosEnvio: datosEnvio2.DireccionEnvioObj.idDatosEnvio,
        fecha: fechaCompleta,
        idCupon: null,
        ordenCompra: idOrdenProducto,
        puntosGastados: 0
      };
      fetch(URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setLoading(false);
          localStorage.removeItem("1");
          localStorage.removeItem("2");
          localStorage.removeItem("3");
          localStorage.removeItem("4");
          localStorage.removeItem("5");
          localStorage.removeItem("6");
          localStorage.removeItem("totalItems");
          window.location.href = redirectURL;
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      //para usuarios registrados
      if (opcion == 0) {
        //Cargo con estado pendiente
        body = {
          idUsuario: idUsuario,
          detallesCompra: datosEnvio2.Costs,
          idDatosEnvio: datosEnvio2.DireccionEnvioObj.idDatosEnvio,
          fecha: fechaCompleta,
          estadoPago: "En proceso",
          estadoPedido: "Pago por confirmar",
          puntosObtenidos: 0,
          puntosGastados: 0,
          ordenCompra: idOrdenProducto,
        };
      } else if (opcion == 1) {
        //cargo rechazado
        body = {
          idUsuario: idUsuario,
          detallesCompra: datosEnvio2.Costs,
          idDatosEnvio: datosEnvio2.DireccionEnvioObj.idDatosEnvio,
          fecha: fechaCompleta,
          estadoPago: "Rechazado",
          estadoPedido: "(-)",
          puntosObtenidos: 0,
          puntosGastados: 0,
          ordenCompra: idOrdenProducto,
        };
      } else {
        //pago completo
        if (checked) {
          body = {
            idUsuario: idUsuario,
            detallesCompra: datosEnvio2.Costs,
            idDatosEnvio: datosEnvio2.DireccionEnvioObj.idDatosEnvio,
            fecha: fechaCompleta,
            estadoPago: "Pagado",
            estadoPedido: "En preparacion",
            puntosObtenidos: puntosObtenidos ,
            puntosGastados:  puntosActuales - descuentoPuntos,
            puntosTotales: puntosActuales - (puntosActuales - descuentoPuntos) ,
            checked: checked,
            ordenCompra: idOrdenProducto,
          };
          insertarLogPuntos(JSON.stringify(body), JSON.stringify(datosEnvio2.Costs), "Puntos usados");
        } else {
          body = {
            idUsuario: idUsuario,
            detallesCompra: datosEnvio2.Costs,
            idDatosEnvio: datosEnvio2.DireccionEnvioObj.idDatosEnvio,
            fecha: fechaCompleta,
            estadoPago: "Pagado",
            estadoPedido: "En preparacion",
            puntosObtenidos: puntosObtenidos ,
            puntosGastados: 0,
            puntosTotales: puntosActuales,
            checked: checked,
            ordenCompra: idOrdenProducto,
          };
        }
      }

      const URL = "https://api.mezcaldonhesiquio.com/guardarProductosComprados";
      fetch(URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          getDatosUsuario(emailUser)
            .then((data) => {
              setLoading(false);
              console.log(data);
              localStorage.removeItem("1");
              localStorage.removeItem("2");
              localStorage.removeItem("3");
              localStorage.removeItem("4");
              localStorage.removeItem("5");
              localStorage.removeItem("6");
              localStorage.removeItem("totalItems");
              window.location.href = redirectURL;
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        });
    }
  }

  function comprarEventoApi() {
    const path = window.location.origin + window.location.pathname;
    const URL = "https://api.mezcaldonhesiquio.com/comprarEventoApi";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        correoCliente: correoCliente,
        numero: numeroCliente,
        url: path,
        productos: numeroItems,
        total: total,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Evento enviado con exito a api de conversiones");
      });
  }

  function onSubmit(data) {
    setLoading(true);
    addPaymentInfoEventApi();
    setBotonBloqueado(true);
    console.log("inicio proceso de pago");
    console.log("inicio proceso de factura");
    console.log(facturar);

    // si en los productos aplicacion puntos y el monto es 0 salta el flujo de OPEN PAY
    if (productFree && checked){
      onSuccess("");
    }

    window.OpenPay.setId(openPayKey[1]);
    window.OpenPay.setApiKey(openPayKey[0]);
    window.OpenPay.setSandboxMode(false);

    deviceSessionId = window.OpenPay.deviceData.setup();
    console.log(deviceSessionId);

    if (facturar) {
      facturacionObj.rfc = data.rfc;
      facturacionObj.razon = data.razonSocial;
      facturacionObj.cfdi = data.cfdi;
      facturacionObj.regimenFiscal = data.regimen;
      facturacionObj.domicioFiscal = data.cpFiscal;
      facturacionObj.formaPago = data.formaPago;
    }
    window.OpenPay.token.create(
      {
        card_number: data.numTarjeta,
        holder_name: data.nombreTarjeta,
        expiration_year: data.fechaVencimientoTarjeta.split("/")[1],
        expiration_month: data.fechaVencimientoTarjeta.split("/")[0],
        cvv2: data.cvvTarjeta,
      },
      onSuccess,
      onError
    );

    console.log(localStorage.getItem("datosEnvio"));
  }

  const handleChange = (e) => {
    let value = e.target.value.replace(/\s+/g, "");

    const re = /^[0-9]*$/;

    if (re.test(value)) {
      setInfDePago((infDePago.tarjeta = value.slice(-4)));
      e.target.value = value;
    } else {
      e.target.value = value.slice(0, -1);
    }
  };

  useEffect(() => {
    getEstados();
    setLoading(false);
  }, []);



  useEffect(() => {

    var numeroItemsTmp = [];
    for (const producto of productos) {
      let itemsCarrito = localStorage.getItem(producto.id);
      if (!itemsCarrito) {
        itemsCarrito = 0;
      } else {
        itemsCarrito = parseInt(itemsCarrito);
      }
      if (itemsCarrito > 0) {
        numeroItemsTmp.push({
          id: producto.id,
          items: itemsCarrito,
          costo: producto.costo,
          imagen: producto.imagenItem,
          nombre: producto.nombreLargo,
        });
      }
    }

    setNumeroItems(numeroItemsTmp);
    console.log(numeroItemsTmp);
    var totalItemsTmp = localStorage.getItem("totalItems");
    if (!totalItemsTmp) {
      // setTotalItems(0);
    } else {
      parseInt(totalItemsTmp);
    }

    

    // actualizaSubTotal(numeroItemsTmp);
  }, []);

  return (
    <>
      <div className="d-flex gap-3">
        <form onSubmit={handleSubmit(onSubmit)} className="datos-envio__form pago m-0 col-lg-8">
          <div className="pago d-flex flex-column gap-4">
            <section className="datos-envio__form__container envio">
              <div className="col-10 d-flex align-items-center gap-2 ">
                <img src={icon} alt="" width="20px" height="20px" />
                <div className="d-flex flex-column col-12 w-100 ">
                  <div className=" w-100 texto-negro-sans">Datos de envío</div>
                  <div className="">
                    <span className="">{nombre}</span>
                    <br />
                    <span className="texto-transparente">{direccion}</span>
                  </div>
                </div>
              </div>
              <div className="datos-envio__form--envio__cambio">
                <Link to={`${registrado ? "/datos-envio-card" : "/datos-envio"}`}>Cambiar</Link>
              </div>
            </section>
            <section className="datos-envio__form__container envio">
              <div className="col-10 d-flex align-items-center gap-2 ">
                <img src={envioIcon} alt="" width="25px" height="25px" />
                <div className="d-flex flex-column col-12 w-100 ">
                  <div className=" w-100 texto-negro-sans">Envío</div>
                  <div className="">
                    <span className="">{tipoEnvio}</span>
                    <br />
                    {!datosEnvio.DireccionEnvioObj.foraneo ? (
                      <span className="texto-transparente">Tiempo de entrega: 12 a 72 horas</span>
                    ) : (
                      <span className="texto-transparente">Tiempo de entrega: 2 a 7 días</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="datos-envio__form--envio__cambio">
                <Link to={`${registrado ? "/datos-envio-card" : "/datos-envio"}`}>Cambiar</Link>
              </div>
            </section>
          </div>
           {habilitoPago &&( 
            <>
            <h3 className="datos-envio__form__title" style={{ marginTop: "43px" }}>
              Pago
            </h3>
           <section className="datos-envio__form__container">
            <img src={openpay} style={{ width: "30%" }} alt="" height="100%" width="100%" />
            <img height="50px" src={mc} alt="" float="right" />
            <img height="50px" src={visa} alt="" float="right" />
            <img height="50px" src={ae} alt="" float="right" style={{ paddingLeft: "0.5rem" }} />

            <h3
              className="texto-negro-sans"
              style={{
                fontSize: "25px",
                marginTop: "26px",
              }}
            >
              Informaci&oacute;n del pago
            </h3>
            <div className="pago__form__group-inputs">
              <div className="pago__form__group-inputs--auto">
                <label>
                  N&uacute;mero de tarjeta
                  <input
                    className={errors.numTarjeta ? "input-error" : ""}
                    type="text"
                    minLength={12}
                    maxLength={16}
                    {...register("numTarjeta", {
                       required: habilitoPago  ? "Este campo es obligatorio":false ,
                      pattern: {
                        value: /^[0-9]{12,16}$/,
                        message: "Ingrese numero de tarjeta válido",
                      },
                      onChange: (e) => {
                        handleChange(e);
                      },
                    })}
                  />
                  {errors.numTarjeta && <p>{errors.numTarjeta.message}</p>}
                  <span>Son 16 números</span>
                </label>
                <label>
                  Nombre del titular
                  <input
                    className={errors.nombreTarjeta ? "input-error" : ""}
                    type="text"
                    {...register("nombreTarjeta", {
                      required: habilitoPago? "Este campo es obligatorio":false,
                      pattern: {
                        value: /[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]/,
                        message: "Ingrese nombre valido",
                      },
                    })}
                  />
                  {errors.nombreTarjeta && <p>{errors.nombreTarjeta.message}</p>}
                  <span>Como aparece en la tarjeta</span>
                </label>
              </div>
              <div className="pago__form__group-inputs--auto">
                <label>
                  Fecha de vencimiento
                  <input
                    className={errors.fechaVencimientoTarjeta ? "input-error" : ""}
                    type="text"
                    id="fechav"
                    maxLength="5"
                    placeholder="MM/AA"
                    onKeyUp={agregaSlash}
                    {...register("fechaVencimientoTarjeta", {
                      required: habilitoPago?  "Este campo es obligatorio":false,
                      pattern: {
                        value: /^[0-9]{2}\/[0-9]{2}$/,
                        message: "Ingrese fecha de vencimiento válido",
                      },
                      validate: {
                        validMonth: validateMonth,
                      },
                    })}
                  />
                  {errors.fechaVencimientoTarjeta && <p>{errors.fechaVencimientoTarjeta.message}</p>}
                </label>
                <label>
                  C&oacute;digo de seguridad
                  <input
                    className={errors.cvvTarjeta ? "input-error" : ""}
                    type="tel"
                    minLength={3}
                    maxLength={4}
                    placeholder="CVV"
                    {...register("cvvTarjeta", {
                      required: habilitoPago?"Este campo es obligatorio":false,
                      pattern: {
                        value: /^[0-9]{3,4}$/,
                        message: "Ingrese codigo CVV válido",
                      },
                      onChange: (e) => {
                        handleChange(e);
                      },
                    })}
                  />
                  {errors.cvvTarjeta && <p>{errors.cvvTarjeta.message}</p>}
                </label>
              </div>
            </div>
          </section>
          </> )}



          {habilitoPago  && (<section className="checkFacturar--input">
            <label id="label--factura" htmlFor="checkFacturar">
              Facturar compra
              <input id="checkFacturar" type="checkbox" onClick={toggleFacturar} />
            </label>
          </section> )}
          {(!facturar) || (habilitoPago)  &&( 
          <section className="datos-envio__form__container">
              <h3 className="datos-envio__form__title">Datos de facturaci&oacute;n</h3>
              <div className="pago__form__group-inputs">
                <label>
                  RFC
                  <input
                    className={errors.rfc ? "input-error" : ""}
                    type="text"
                    maxLength={13}
                    style={{ textTransform: "uppercase" }}
                    {...register("rfc", {
                      pattern: {
                        value: /^([A-Za-z]{3}\d{6}([A-Za-z0-9]{3})?)|([A-Za-z]{4}\d{6}([A-Za-z0-9]{3})?)$/,
                        message: "Ingrese RFC valido",
                      },
                      required: facturar ? "Este campo es obligatorio" : "",
                    })}
                  />
                  {errors.rfc && <p>{errors.rfc.message}</p>}
                </label>
                <label>
                  Nombre o raz&oacute;n social
                  <input
                    className={errors.razonSocial ? "input-error" : ""}
                    type="text"
                    {...register("razonSocial", {
                      required: facturar ? "Este campo es obligatorio" : "",
                    })}
                  />
                  {errors.razonSocial && <p>{errors.razonSocial.message}</p>}
                </label>
                <div className="pago__form__group-inputs--select">
                  <label>
                    Uso del CFDI
                    <select
                      className={errors.cfdi ? "input-error" : ""}
                      {...register("cfdi", {
                        required: facturar ? "Este campo es obligatorio" : "",
                      })}
                    >
                      <option value="">Seleccionar CFDI</option>
                      {cfdi.map((cfdi) => (
                        <option key={cfdi} value={cfdi}>
                          {cfdi}
                        </option>
                      ))}
                    </select>
                    {errors.cfdi && <p>{errors.cfdi.message}</p>}
                  </label>

                  <label>
                    R&eacute;gimen fiscal
                    <select
                      className={errors.regimen ? "input-error" : ""}
                      {...register("regimen", {
                        required: facturar ? "Este campo es obligatorio" : "",
                      })}
                    >
                      <option value="">Seleccionar R&eacute;gimen fiscal</option>
                      {regimenes.map((regimenes) => (
                        <option key={regimenes} value={regimenes}>
                          {regimenes}
                        </option>
                      ))}
                    </select>
                    {errors.regimen && <p>{errors.regimen.message}</p>}
                  </label>
                </div>

                <label>
                  C&oacute;digo postal
                  <input
                    className={errors.cpFiscal ? "input-error" : ""}
                    type="number"
                    {...register("cpFiscal", {
                      pattern: {
                        value: /^\d{5}$/,
                        message: "Ingrese Codigo Postal Valido",
                      },
                      required: facturar ? "Este campo es obligatorio" : "",
                    })}
                  />
                  {errors.cpFiscal && <p>{errors.cpFiscal.message}</p>}
                </label>
                <label>
                  Forma de pago
                  <select
                    className={errors.formaPago ? "input-error" : ""}
                    {...register("formaPago", {
                      required: facturar ? "Este campo es obligatorio" : "",
                    })}
                  >
                    <option value="">Seleccionar Forma de pago</option>
                    {formasPago.map((formasPago) => (
                      <option key={formasPago} value={formasPago}>
                        {formasPago}
                      </option>
                    ))}
                  </select>
                  {errors.formaPago && <p>{errors.formaPago.message}</p>}
                </label>
              </div>
            </section> )}
          
          <br></br>
          {continuarMobile && (
            <div className="d-flex flex-column gap-2 col-xs-12 col-lg-4 mt-4">
              <div>
                <OrderSummary items={costs} />
              </div>
              {registrado && (
                <div className="container-blanco p-4">
                  <Puntos />
                </div>
              )}
            </div>
          )}
          <div className="datos-envio__form__buttons">
            <Link to="/envio">
              <button type="button" className="btn-secondary--black">
                Regresar
              </button>
            </Link>
            <button type="submit" disabled={botonBloqueado} className="btn-primary--gold">
              Pagar
            </button>
          </div>
        </form>
        {!continuarMobile && (
          <div className="d-flex flex-column gap-2 col-xs-12 col-lg-4 mt-4">
            <div>
              <OrderSummary items={costs} />
            </div>
            {registrado && (
              <div className="container-blanco p-4">
                <Puntos />
              </div>
            )}
          </div>
        )}
      </div>

      <Modal isOpen={modalMensaje} toggle={toggleMensaje} modalTransition={{ timeout: 2000 }}>
        <ModalBody>
          <p align="right">
            <a href="#" onClick={toggleMensaje}>
              {" "}
              <img src={closeNegro} width="6%" height="6%" />
            </a>
          </p>
          <center>
            <div style={{ width: "80%" }}>
              <span style={{ align: "center" }} className="titulo-modal-compra ">
                {mensajeAlerta}
              </span>
            </div>
            <br />
            <br />
            <button
              className="btn-rectangulo-principal btn bg-dorado contactano  texto-blanco-proximo"
              type="button"
              style={{ height: "45px" }}
              onClick={toggleMensaje}
            >
              Aceptar
            </button>
          </center>
        </ModalBody>
      </Modal>
    </>
  );
};
