import { Footer } from "../../components/Footer";
import { Restriccion } from "../../components/restriccion";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import LogoDorado from "../../images/logo-puntero.svg";

//CSS

import "./style.css";

import { NavBar } from "../../components/NavBar";
import { HeroHome } from "./components/HeroHome";
import { Link } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";

const urlHomeVideo =
	"https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/videos/Home_Don_Hesiquio_English.mp4";

export function Origen() {
	const [modal, setModal] = useState(false);
	const [activo, setActivo] = useState(sessionStorage.getItem("activo"));

	const handleCloseModal = () => {
		setModal(false);
		sessionStorage.setItem("modalMostrado", "true");
	};

	useEffect(() => {
		console.log(
			"🚀 ~ useEffect ~ sessionStorage:",
			sessionStorage.getItem("modalMostrado")
		);
		if (!sessionStorage.getItem("modalMostrado")) {
			setModal(true);
		}

		if (sessionStorage.getItem("activo") == "true") {
			setActivo(true);
		}
	}, []);

	return (
		<>
			{!activo ? (
				<Restriccion setActivo={setActivo} />
			) : (
				<>
					<main className="layout--home">
						<NavBar />
						<HeroHome />
						<section className="history">
							<h2 className="history__title">El origen</h2>
							<div className="history__description">
								<p>
								Proveniente de un México Ancestral, en mezcal Don Hesiquio mantenemos vivas las tradiciones y nos recuerdan que lo hecho en México, está bien hecho.
								</p>
								<p>
								Con profundo amor a nuestra tierra llevamos a nuestro pueblo más allá de las fronteras, tal como la leyenda del flechador que apunta al sol en busca de nuevos horizontes. 
								</p>
							</div>
						</section>

						<section className="hero elaboracion-section">
						  <div className="hero_overlay_desktop">
							<div className="hero__overlay">
								<h2 className="section__title_2">ASÍ SE ELABORA DON HESIQUIO</h2>
								<p className="hero__description">
								Mezcal Don Hesiquio nacidos de tierras fértiles en Santiago Tilantongo, que son trabajadas por gigantes para producir nuestros propios agaves.
								</p>
								<Link
									className="button--gold"
									to="/Elaboracion"
									preventScrollReset={true}
								>
									Elaboración
								</Link>
							</div>
							<div></div>
						  </div> 
						  <div className="hero_overlay_mobile">
							<div className="hero-mobile-container">
								<h2 className="section__title_2">ASÍ SE ELABORA DON HESIQUIO</h2>
								<p className="hero__description">
									Mezcal Don Hesiquio nace de las tierras fértiles de Santiago
									Tilantongo, que son trabajadas por gigantes para producir
									nuestros propios agaves.
								</p>
								<Link
									className="button--gold"
									to="/Elaboracion"
									preventScrollReset={true}
								>
									Elaboración
								</Link>
							</div>
							<div></div>
						  </div> 
						</section>

						<section className="video__section ">
							<div className="video__container ">
								<VideoPlayer videoUrl={urlHomeVideo} 
									width="100%" 
									height="100%" 
								/>
							</div>
						</section>

						<section className="mision">
							<div className="mision__container">
								<img src={LogoDorado} alt="" width={"80px"} />
								<p>
									Nuestra filosofía es poner en alto a la familia, el respeto al
									ser humano, el amor a nuestra tierra y nuestra cultura, honrar
									el trabajo de campo y principalmente a nuestra gente.
								</p>
							</div>
						</section>

						{modal && (
							<div onClick={handleCloseModal} className="modal1">
								<div className="modal-content1"></div>
							</div>
						)}
						<Footer />
					</main>
				</>
			)}
		</>
	);
}
