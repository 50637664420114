import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import modalPromo from "../../images/carrusel/modal-promo.jpg";
import { MyContext } from "../context/Context";
import carritoIcon from "../../images/carrito.svg";
import successIcon from "./assets-modal/successIcon.svg";

function ModalItemCarrito({ show, productoAgregado, totalItems }) {
  const { openCarritoModal, setOpenCarritoModal } = useContext(MyContext);

  const cerrarModalItemAgregado = () => {
    setOpenCarritoModal(false);
  };

  return (
    <>
      <Modal show={show} onHide={show} centered>
        <Modal.Body>
          <div className="d-flex justify-content-center p-2">
            <img className="animate-ping" src={successIcon} style={{ width: "3rem" }} />
          </div>
          <div className="d-flex justify-content-center" style={{ fontSize: "1.3rem", textAlign: "center"}}>
          <p>¡Agregado correctamente!</p>
            
          </div>
          <div className="d-flex justify-content-center" style={{ fontSize: "1rem", textAlign: "center"}}>
            <p className="fw-bold">{productoAgregado}</p>
            
          </div>
          <div className="d-flex justify-content-center" style={{ color: "grey", paddingBottom: "1rem"}}>
                {totalItems == 1 ? <span className="fs-bold">({totalItems} unidad)</span> : <span className="fs-bold">({totalItems} unidades)</span>}
            </div>
          <div className="d-flex justify-content-center">
            <Button
              variant=""
              className="button--gold"
              onClick={cerrarModalItemAgregado}
            >
              Aceptar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalItemCarrito;
