// css
import "../../style/sumar-puntos.css";

// icons
import addPhoto from "../../images/puntos/add-photo.svg";
import closeIcon from "../../images/icons/close-icon.svg";

// componentes
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import Login from "../Login/index";
import AWS from "aws-sdk";

// hooks
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { darkScrollbar } from "@mui/material";
import { Navbar } from "react-bootstrap";

export default function RegistrarPuntos() {
  const location = useLocation();
  const navigate = useNavigate();

  const [ordenCompra, setOrdenCompra] = useState();
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [archivoNoValido, setArchivoNoValido] = useState(null);

  const urlLambdaAgregarPuntos =
    "https://api.mezcaldonhesiquio.com/agregarPuntos";
  const urlLambdaLogsPuntos =
    "https://api.mezcaldonhesiquio.com/insertarLogs";

  const idUser = sessionStorage.getItem("idUser");

  const agregarPuntos = async (state) => {
    try {
      const response = await fetch(urlLambdaAgregarPuntos, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          ordenCompra: ordenCompra,
          // ordenCompra: "MEZ20240502T173019542548",
          idUser: idUser,
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.registroCompra !== true) {
        console.log(data.message);
        setAlertMessage(data.message);
      } else {
        navigate("/registrar-puntos-success", { state: { tipo: state } });
        console.log("success with...");
      }

    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setOrdenCompra(event.target.value);
    console.log(ordenCompra);
  };

  // sistema, proceso, tipo, xml_in, xml_out, descripcion, usuario

  const logsSumarPuntos = async (descripcion) => {
    try {
      const response = await fetch(urlLambdaLogsPuntos, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          sistema: "PagMezcal",
          proceso: "SumarPuntos",
          tipo: "control",
          xml_in: "{}",
          xml_out: "{}",
          descripcion: descripcion,
          usuario: sessionStorage.getItem("emailUser"),
        }),
      });
      const data = await response.json();
      console.log("this is " + JSON.stringify(data.statusCode));
      if(descripcion === "Sumar puntos con foto") {
        console.log("flujo subir imagen")
      } else {
          agregarPuntos("numero");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // actualiza el estado "file" con el archivo seleccionado
    setImagePreview(URL.createObjectURL(event.target.files[0]));
    let image = URL.createObjectURL(event.target.files[0]);
    console.log(image);
  };

  const sendEmailPuntos = async (urlArchivo, state) => {
    const urlLambda =
      "https://api.mezcaldonhesiquio.com/sendEmailPuntos";
    const response = await fetch(urlLambda, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ urlArchivo }),
    });
    const data = await response.json();
    if (data.statusCode == 200) {
      navigate("/registrar-puntos-success", { state: { tipo: state } });
    }
  };

  const cargarArchivos = async () => {

    AWS.config.update({
      region: "us-west-2",
      credentials: new AWS.Credentials(
        process.env.REACT_APP_ACCESS_KEY_ID,
        process.env.REACT_APP_SECRET_ACCESS_KEY
      ),
    });

    const s3 = new AWS.S3();

    const fileName = file.name;
    const albumPhotosKey =
      encodeURIComponent("registrar-puntos-recursos") + "/";
    const photoKey = albumPhotosKey + fileName;

    let uploadParams = {
      Bucket: "mezcal-don-hesiquio-recursos",
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    };
    console.log("UploadParams:", uploadParams);
    try {
      const stored = await s3.upload(uploadParams).promise();
      console.log("stored:", stored);
      const fileDirection = stored.Location; // <-- se debe de enviar en el template la url guardada
      console.log(fileDirection);
      sendEmailPuntos(fileDirection, "imagen");
    } catch (err) {
      console.log(err);
    }
  };

  const removerImagen = () => {
    setFile(null);
    setImagePreview(null);
  };

  useEffect(() => {
    // console.log("esto es file", file?.type)
    // console.log(typeof file?.type)
    // console.log(file?.type === "image/jpeg")
    // console.log("este es archivo no valido", archivoNoValido)

    if(file?.type === "image/jpeg" || file?.type === "image/jpg" || file?.type === "image/png") {
      console.log("es imagen")
      setArchivoNoValido(false)
    } else {
      console.log("por favor elija un archivo valido")
      setArchivoNoValido(true)
    }
  }, [file])

  return (
    <>
      
      {location.state.registrado ? (
        <>
      {location.state.tipo === "donHesiquio" ? (
        <>
        <NavBar/>
        <div className="sumar-puntos-main-container w-100">
          <div className="container text-center pt-5">
            <h1 className="suma-puntos-title">Suma Puntos</h1>
            <p>
              Ingresa tu n&uacute;mero de pedido de tu compra. Lo encuentras en
              el correo <br /> que te enviamos al de confirmaci&oacute;n tu
              compra.
            </p>
          </div>
          <div className="registrar-puntos-container container">
            <label>
              N&uacute;mero de pedido
              <input type="text" onChange={handleChange} />
            </label>

            <button
              className="registrarpuntos-form-button mx-auto"
              onClick={() => logsSumarPuntos("Sumar puntos numero de pedido")}
            >
              Enviar
            </button>
            {alertMessage ? (
              <div className="alert alert-danger m-2 d-flex justify-content-around">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#3e3e3e"
                  className="bi bi-exclamation-circle alert-icon"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0" />
                </svg>
                <span style={{ paddingLeft: "2rem" }}>{alertMessage}</span>
              </div>
            ) : null}
          </div>
        </div>
        </>
      ) : location.state.tipo === "amazon" ? (
        <>
        <NavBar />
        <div className="sumar-puntos-main-container w-100">
          <div className="container text-center pt-5">
            <h1 className="suma-puntos-title">Suma Puntos</h1>
            <p>
              Ingresa tu n&uacute;mero de pedido de tu compra. Lo encuentras en
              el correo <br /> que te enviamos al de confirmaci&oacute;n tu
              compra.
            </p>
          </div>
          <div className="registrar-puntos-container container">
            <label>
              N&uacute;mero de pedido
              <input type="text" onChange={handleChange} />
            </label>

            <button
              className="registrarpuntos-form-button mx-auto"
              onClick={() => logsSumarPuntos("Sumar puntos numero de pedido")}
            >
              Enviar
            </button>
          </div>
        </div>
        </>
      ) : (
        <>
        <NavBar />
        <div className="sumar-puntos-main-container w-100">
          <div className="container text-center pt-5">
            <h1 className="suma-puntos-title">Suma Puntos</h1>
            <p>
              Sube una foto de tu ticket de compra de La Europea para ganar{" "}
              <br /> puntos. Tus puntos se ver&aacute;n reflejados
              despu&eacute;s de 24 horas.
            </p>
          </div>
          <div className="registrar-puntos-container container">
            <div className="d-flex justify-content-center pt-4 pb-4">
              {imagePreview ? (
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <img
                    className="align-self-end m-2"
                    src={closeIcon}
                    style={{ height: "1rem", cursor: "pointer" }}
                    onClick={removerImagen}
                  />
                  <img src={imagePreview} width="300px" />
                </div>
              ) : (
                <img className="add-photo-icon" src={addPhoto} />
              )}

            
            </div>
            {imagePreview ? (
              <button
                className={`${archivoNoValido ? "subir-foto-input-disabled" : "subir-foto-input"} d-flex align-self-center`}
                onClick={cargarArchivos}
                disabled={archivoNoValido}
              >
                Enviar foto
              </button>
            ) : (
              <>
                <label
                  for="subir-foto-input"
                  className="subir-foto-input d-flex align-self-center"
                  onClick={() => logsSumarPuntos("Sumar puntos con foto")}
                >
                  Subir foto
                </label>
                <input
                  id="subir-foto-input"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={handleFileChange}
                />
              </>
            )}
            {archivoNoValido ? <p style={{ fontSize: "0.85rem", textAlign: "center", marginTop: "1rem"}}>Los archivos permitidos son <span className="fw-bold">.jpg</span>, <span className="fw-bold">.jpeg</span> y <span className="fw-bold">.png</span></p> : null}
          </div>
        </div>
        </>
      )}
      </>
      ): <Login sumarPuntos={true} />} 

      <Footer />
    </>
  );
}
