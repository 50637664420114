import React from "react";
import { NavBarAyuda } from "../components/NavBarAyuda";
import Exito from "../images/exito.svg";
import { Link } from "react-router-dom";

function PantallaExito() {
  return (
    <>
      <NavBarAyuda />
      <div className="datos-envio contenedor_reset_password">
        <div className="container h-100">
          <div className="d-flex flex-column align-items-center justify-content-center h-100 gap-4">
            <div>
              <img src={Exito} width="60px" />
            </div>
            <div className="text-center">
              <h3 className="fs-1">¡Felicidades!</h3>
              <p>se ha confirmado tu compra</p>
            </div>
            <div>
              <button onClick={() => {
                window.location.href = "/";
              }} className="boton-enviar-resetpassword">
                Ir al inicio
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PantallaExito;
