import "./mezcal-product-view.css";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import PurchaseInfo from "./PurchaseInfo";
import { useNavigate } from "react-router-dom";
import Comprar from "../Comprar";

function MezcalProductView(props) {
  const navigate = useNavigate();

  const handleReturnButton = (event) => {
    navigate("/Mezcales");
  };
  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="return-button" onClick={handleReturnButton}>
          <p>Volver a Nuestros Mezcales</p>
        </div>
        <div className="product-view-main">
          <div className="mezcal-product-view-main-container">
            <div className="mezcal-image-container">
              <img src={props.image} />
            </div>
            {/* <div className="pago"></div> */}
            <h2>{props.titulo}</h2>
            <div className="product-description-main">
              <hr />
              <h5>Caracter&iacute;sticas del mezcal</h5>
              <div className="product-description-main-container">
                <div className="column-1-info">
                  <p>
                    <strong>Tipo: </strong>
                    {props.tipo}
                  </p>
                  <p>
                    <strong>Agave: </strong>
                    {props.agave}
                  </p>
                  <p>
                    <strong>Grados de alcohol: </strong>
                    {props.grados}
                  </p>
                  <p>
                    <strong>Capacidad: </strong>
                    {props.capacidad}
                  </p>
                  <p>
                    <strong>Temperatura recomendada de consumo: </strong>
                    {props.temperatura}
                  </p>
                </div>
                <div className="column-2-info">
                  <p>
                    <strong>Categoria: </strong>
                    {props.categoria}
                  </p>
                  <p>
                    <strong>Clase: </strong>
                    {props.clase}
                  </p>
                  <p>
                    <strong>Cocci&oacute;n: </strong>
                    {props.coccion}
                  </p>
                  <p>
                    <strong>Molienda: </strong>
                    {props.molienda}
                  </p>
                  <p>
                    <strong>Fermentaci&oacute;n: </strong>
                    {props.fermentacion}
                  </p>
                  <p>
                    <strong>Destilaci&oacute;n: </strong>
                    {props.destilacion}
                  </p>
                  <p>
                    <strong>No. de destilaciones: </strong>
                    {props.noDestilaciones}
                  </p>
                  <p>
                    <strong>Regi&oacute;n: </strong>
                    {props.region}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mezcal-purchase-container">
            {/* <PurchaseInfo
              mezcal={props.variante}
              nombreProducto={props.nombreProducto}
              descripcion={props.descripcion}
              precio={props.precio}
              costoPuntos={props.costoPuntos}
              puntosObtener={props.puntosObtener}
            /> */}
            {/* <Comprar /> */}
          </div>
        </div>
        <div className="gallery-section">
          <img src={props.galleryImage} />
          <div className="gallery-description">
            <p>{props.galleryDescription}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MezcalProductView;
