import React, { useEffect, useState, useContext } from "react";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { OrderSummary } from "../components/OrderSummary";
import Puntos from "./components/Puntos";
import { useCost } from "./DatosEnvio/Hooks/useCost";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../components/context/Context";
import { BreadcrumbTrail } from "../components/BreadcrumbTrail";
import ModalConfirmacion from "./components/ModalConfirmacion"

import iconTrash from "../images/trash.svg";

function CardDatosEnvio() {
  const { registrado, isLocal,setIsLocal, total } = useContext(MyContext);
  const nombre = sessionStorage.getItem("userName");
  const apellidos = sessionStorage.getItem("userLastName");
  const emailUser = sessionStorage.getItem("emailUser");
  const telefonoUser = sessionStorage.getItem("telefono");
  const [datosEnvio, setDatosEnvio] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [datosSeleccionados, setDatosSeleccionados] = useState();
  const idUsuario = sessionStorage.getItem("idUser");
  const [continuarMobile, setContinuarMobile] = useState(
    window.innerWidth < 992
  );
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(null);
  const { costs } = useCost();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  
  
  const handleClose = () => setModalShow(false);
  const handleShow = (idDatosEnvio) => {
    setDireccionSeleccionada(idDatosEnvio);
    console.log(idDatosEnvio)
    setModalShow(true);
  }
  
  const handleConfirm = () => {
    console.log("Acción confirmada");
    console.log(direccionSeleccionada)
    eliminarDireccionEnvio(direccionSeleccionada)
    handleClose(); 
  };

  function eliminarDireccionEnvio(idDatosEnvio) {
      console.log("eliminando: " + idDatosEnvio);
      const URL =
        "https://api.mezcaldonhesiquio.com/eliminarDireccionEnvio";
      fetch(URL, {
        method: "POST",
        body: JSON.stringify({
          idDatosEnvio: idDatosEnvio,
          idUsuario: idUsuario,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setDatosEnvio(
            datosEnvio.filter((dato) => dato.idDatosEnvio !== idDatosEnvio)
          );
          if (datosEnvio.length == 1) {
            navigate("/datos-envio");
          }
        })
        .catch((err) => {
          console.log(
            "Hubo un problema al obtener sus datos, favor de contactar al equipo de soporte"
          );
          console.log(err);
        });
  }

  function getDatosEnvioRequest() {
    const URL =
      "https://api.mezcaldonhesiquio.com/getDatosEnvio";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        idUsuario: idUsuario,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (JSON.stringify(data.message) == "[]") {
          navigate("/datos-envio");
          return;
        }
        console.log(data);
        const datosActivos = data.message.filter((dato) => dato.activo == 1);
        console.log(datosActivos);
        if (datosActivos.length == 0) {
          navigate("/datos-envio");
          return;
        }
        setDatosEnvio(datosActivos);
      })
      .catch((err) => {
        console.log(
          "Hubo un problema al obtener sus datos, favor de contactar al equipo de soporte"
        );
        console.log(err);
      });
  }

  function handleRadioChange(event, datos) {
    console.log(datos)

    if(datos.estado == "Ciudad de México"){
      console.log("es local")
      setIsLocal(true);
    } else {
      setIsLocal(false);
    }
    setDatosSeleccionados(datos);
    setSelectedId(Number(event.target.value));
  }

  function continuar() {
    console.log(isLocal)
    console.log(total);
    console.log(costs);
    console.log(datosSeleccionados);
    if (registrado) {
      console.log("entra aqui");
      localStorage.removeItem("datosEnvio");
      localStorage.setItem(
        "datosEnvio",
        JSON.stringify({
          DatosContactoObj: {
            nombre: nombre,
            apellidos: apellidos,
            celular: telefonoUser !== "" ? telefonoUser : datosSeleccionados.telefono,
            email: emailUser,
          },
          DireccionEnvioObj: {
            idDatosEnvio: datosSeleccionados.idDatosEnvio,
            calle: datosSeleccionados.calle,
            numExt: datosSeleccionados.numExt,
            numInt: datosSeleccionados.numInt,
            entreCalle: datosSeleccionados.entreCalle,
            ref: datosSeleccionados.ref,
            cp: datosSeleccionados.codigoPostal,
            colonia: datosSeleccionados.nombreColonia,
            ciudad: datosSeleccionados.municipio,
            estado: datosSeleccionados.estado,
            foraneo: !isLocal,
            costoEnvio: costs.shippingCost,
          },
          Costs: costs,
        })
      );
      navigate("/envio", { state: datosSeleccionados });

      return;
    }
    navigate("/datos-envio", { state: datosSeleccionados });
  }

  

  useEffect(() => {
    console.log("obteniendo datos envio");
    getDatosEnvioRequest();

    
  }, []);

  useEffect(() => {
    function handleResize() {
      setContinuarMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <NavBar />
      <div className="datos-envio">
        <div className="datos_envio_container container">
          <div className="my-4 col-12 ">
            <h3>Datos de envío</h3>
          </div>
          <BreadcrumbTrail />
          <br />
          <div className=" contenedor-flex  ">
            <div className="datos_envio_destinatarios row col-xs-12 col-sm-12 col-md-12 col-xl-8 col-lg-7 ">
              {datosEnvio.length == 0 ? (
                <h3 className="text-center">Cargando datos....</h3>
              ) : (
                datosEnvio.map((datos) => (
                  <div
                    key={datos.idDatosEnvio}
                    className="container-blanco d-flex align-items-center gap-4 p-2 mb-4 col-12"
                  >
                    <div className="d-flex flex-column">
                      <label className="custom-input-radio d-flex gap-2">
                        <input
                          type="radio"
                          name="datosEnvioRadio"
                          value={datos.idDatosEnvio}
                          checked={selectedId === datos.idDatosEnvio}
                          onChange={(e) => {
                            handleRadioChange(e, datos);
                          }}
                          className="visually-hidden"
                        />
                        <span className="checkmark"></span>{" "}
                        <div>
                          <span className="texto-negro-sans fs-5">
                            {datos.nombre} {datos.apellidos}
                          </span>{" "}
                          <br />
                          <span className="texto-transparente">
                            {datos.calle}, {datos.codigoPostal},
                            {datos.nombreColonia}, {datos.nombres}
                          </span>
                        </div>
                      </label>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShow(datos.idDatosEnvio)}
                      className="ms-auto p-2"
                    >
                      <img src={iconTrash} alt="" />
                    </div>
                  </div>
                ))
              )}

              {datosEnvio.length == 0 ? null : (
                <>
                  <Link to="/datos-envio">
                    <button className="d-flex gap-2 align-items-center">
                      <span className="texto-oro fs-3">+</span>
                      <span className="texto-negro-sans fw-bold">
                        Enviar a otra direccion
                      </span>
                    </button>
                  </Link>
                  {!continuarMobile && (
                    <div className="ms-auto p-0 col-xs-12 col-lg-3 mt-4 boton-continuar-movil">
                      <button
                        style={{
                          borderStyle: "none",
                          backgroundColor: "#a78721",
                          color: "#ffffff",
                          width: "100%",
                          fontSize: "14px",
                          padding: "10px 0",
                          fontWeight: "300",
                          fontFamily: "Source Sans Pro",
                        }}
                        className="btn mt-lg-0"
                        onClick={continuar}
                      >
                        Continuar
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="contenedor_resumen_compra p-0  h-100 row flex-wrap g-2 justify-content-between align-items-center col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-4 flex-lg-row">
              <div className=" p-0 col-xs-12 col-sm-12 col-md-12 col-lg-12  ">
                <OrderSummary items={costs} />
              </div>
              {registrado && (
                <div className="container-blanco col-sm-12 col-md-12 col-lg-12 p-4">
                  <Puntos />
                </div>
              )}
            </div>
            {continuarMobile && (
              <div className="ms-auto p-0 col-12 col-sm-12 col-lg-3 mt-4">
                <button
                  style={{
                    borderStyle: "none",
                    backgroundColor: "#a78721",
                    color: "#ffffff",
                    width: "100%",
                    fontSize: "14px",
                    padding: "10px 0",
                    fontWeight: "300",
                    fontFamily: "Source Sans Pro",
                  }}
                  className="btn mt-lg-0"
                  onClick={continuar}
                >
                  Continuar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ModalConfirmacion show={modalShow} handleClose={handleClose} onConfirm={handleConfirm} />
    </>
  );
}

export default CardDatosEnvio;
