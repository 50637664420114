// css
import "./under-construction.css";

// assets
import underConstruction from "../../../images/mezcales/contruccion.png";
import dhLogo from "../../../images/mezcales/DHlogo.svg";

function UnderConstruction() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${underConstruction})`,
          backgroundSize: "cover",
          width: "100%",
          height: "65vh",
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center">
            <img src={dhLogo} className="mb-4" style={{ width: "6.5rem" }} />
          </div>

          <div className="under-construction-title container text-white text-center">
            <h2 className="display-4 mb-4">PR&Oacute;XIMAMENTE</h2>
            <p>
              Estamos preparando algo especial para ti. <br /> Mientras,
              continua explorando.{" "}
            </p>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default UnderConstruction;
