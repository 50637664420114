import React, { useEffect } from "react";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../components/context/Context";


function CorreoEnviado() {
  const { registrado } = useContext(MyContext)

  return (
    <>
      <NavBar />
      <div className="datos-envio contenedor_reset_password">
        <div className="container h-100">
          <div className="row mt-4 h-100">
            <div className="d-flex flex-column align-items-center mt-4 gap-2">
              <svg
                className="mb-4"
                width="60"
                height="48"
                viewBox="0 0 60 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.61533 47.3332C3.33439 47.3332 2.24492 46.8842 1.34692 45.9863C0.448972 45.0883 0 43.9988 0 42.7178V5.28184C0 4.00089 0.448972 2.91142 1.34692 2.01342C2.24492 1.11548 3.33439 0.666504 4.61533 0.666504H55.3847C56.6656 0.666504 57.7551 1.11548 58.6531 2.01342C59.551 2.91142 60 4.00089 60 5.28184V42.7178C60 43.9988 59.551 45.0883 58.6531 45.9863C57.7551 46.8842 56.6656 47.3332 55.3847 47.3332H4.61533ZM30 23.5127L2.56408 5.18576V42.7178C2.56408 43.3161 2.75639 43.8076 3.141 44.1922C3.52561 44.5768 4.01706 44.7691 4.61533 44.7691H55.3847C55.9829 44.7691 56.4744 44.5768 56.859 44.1922C57.2436 43.8076 57.4359 43.3161 57.4359 42.7178V5.18576L30 23.5127ZM30 20.5639L56.2693 3.23059H3.81408L30 20.5639ZM2.56408 5.18576V3.23059V42.7178C2.56408 43.3161 2.75639 43.8076 3.141 44.1922C3.52561 44.5768 4.01706 44.7691 4.61533 44.7691H2.56408V5.18576Z"
                  fill="#A78721"
                />
              </svg>
              <h3 className="text-center fs-2">Correo Enviado</h3>
              <p className="text-center texto-negro-sans">
                Te hemos enviado un correo electrónico con un enlace para
                actualizar <br /> su contrase&ntilde;a
              </p>
              {registrado ? (
                <Link to="/" className="boton-enviar-resetpassword">
                  Ir a Home
                </Link>
              ) : (
                <Link to="/login" className="boton-enviar-resetpassword">
                Ir al inicio de sesi&oacute;n
              </Link>
              )}
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CorreoEnviado;
