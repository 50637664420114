const urlLambda =
  "https://api.mezcaldonhesiquio.com/getDatosUsuario";

export const getDatosUsuario = (emailUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ email: emailUser }),
      });

      if (response.ok) {
        console.log(`Respuesta getDatosUsuario() exitosa`);
        const data = await response.json();
        console.log(data.message[0]);
        sessionStorage.setItem("idUser", data.message[0].idUser);
        sessionStorage.setItem("userName", data.message[0].nombres);
        sessionStorage.setItem("userLastName", data.message[0].apellidos);
        sessionStorage.setItem("puntos", data.message[0].puntos);
        sessionStorage.setItem("telefono", data.message[0].telefono);
        const expiracionPuntos = data.message[0].exp_puntos;
        if (expiracionPuntos) {
          const fechaVencimientoPuntos = expiracionPuntos.split("T")[0];
          console.log(fechaVencimientoPuntos);
          sessionStorage.setItem(
            "fechaVencimientoPuntos",
            fechaVencimientoPuntos
          );
        }
        resolve(data.message[0]);
      } else {
        console.log(`Hubo un error con getDatosUsuario()`);
        reject(new Error("La respuesta del servidor no fue OK."));
      }
    } catch (error) {
      console.log(`Hubo un error en front getDatosUsuario():`, error);
      reject(error);
    }
  });
};
