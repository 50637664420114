import espadinMuestra from "../images/mezcales/espadin-flotante.png";
// imagenes para detalle de vista de mezcales
import espadinItem from "../images/mezcales/mezcal-espadin-rectangular.jpg";
import tobalaItem from "../images/mezcales/mezcal-tobala-rectangular.jpg";
import espadinItemMobile from "../images/mezcales/detalle-espadin-tienda.jpg";
import tobalaItemMobile from "../images/mezcales/detalle-tobala-tienda.jpg";
// otras imagenes
import tobalaMuestra from "../images/mezcales/tobala-flotante.png";
import espadinImg from "../images/mezcales/mezcal_espadin.png"
import tobalaImg from "../images/mezcales/mezcal_tobala.png"
import espadinSinFondo from "../images/mezcales/espadin.png";
import tobalaSinFondo from "../images/mezcales/tobala.png";
import medallaTobala from "../images/mezcales/medalla-tobala.png";
import agaveIcon from "../images/mezcales/agave-icon.svg";
import jicara from "../images/tienda/jicara.png"
import gusanos from "../images/tienda/gusanos.png"
import chapulines from "../images/tienda/chapulines.png"
import calaverita from "../images/tienda/calaverita.png"
import mezcalTobalaCrema from "../images/mezcales/mezcal-tobala-crema.png"
import mezcalEspadinCrema from "../images/mezcales/mezcal-espadin-crema.png"
import descripcionMezcalEspadin from "../images/compra/espadin_plato.jpg";
import descripcionMezcalTobala from "../images/compra/tobala_plato.jpg";

// importaciones de imagenes de productos de inventario (no mezcales)
import imagenProductoInventario1 from "../images/mezcales-products/tobala-espadin-kitcocteleria.jpg";
import imagenProductoInventario2 from "../images/mezcales-products/tobala-kitcocteleria.jpg";
import imagenProductoInventario3 from "../images/mezcales-products/gorra.jpg";
import imagenProductoInventario4 from "../images/mezcales-products/kitcocteleria.jpg";

// exportaciones de imagenes de productos de inventario (no mezcales)
export const imagenesProductosInventario = {
  imagenProductoInventario1,
  imagenProductoInventario2, 
  imagenProductoInventario3, 
  imagenProductoInventario4
}

// costos de mezcales, estos costos son los que estan asociados a la logica de calculos generales
export const costoTobala = 1780.00;
export const costoEspadin = 970.00;
// costos de productos de inventario (no mezcales)
export const costoProductoInventario1 = 2699.00;
export const costoProductoInventario2 = 1999.00;
export const costoProductoInventario3 = 250.00;
export const costoProductoInventario4 = 399.00;
// nombres de productos de inventario (no mezcales) para mostrar en UI
export const nombreProductoInventario1 = "Tobalá + Espadín + Kit de coctelería";
export const nombreProductoInventario2 = "Tobalá + Kit de coctelería";
export const nombreProductoInventario3 = "Gorra";
export const nombreProductoInventario4 = "Kit de coctelería";

export const openPayKey=["pk_1933c9ece22f40d2938c5fbecedf2a97","mjx02e6hqtl8tnn8cemo"]
export const estados = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Coahuila",
  "Colima",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Estado de México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];
export const regimenes =["601 - General de Ley Personas Morales",
"603 - Personas Morales con Fines no Lucrativos",
"605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
"606 - Arrendamiento",
"607 - Régimen de Enajenación o Adquisición de Bienes",
"608 - Demás ingresos",
"610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
"611 - Ingresos por Dividendos (socios y accionistas)",
"612 - Personas Físicas con Actividades Empresariales y Profesionales",
"614 - Ingresos por intereses",
"615 - Régimen de los ingresos por obtención de premios",
"616 - Sin obligaciones fiscales",
"620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
"621 - Incorporación Fiscal",
"622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
"623 - Opcional para Grupos de Sociedades",
"624 - Coordinados",
"625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
"626 - Régimen Simplificado de Confianza"];

export const cfdi =["G01 - Adquisición de mercancías",
"G02 - Devoluciones, descuentos o bonificaciones",
"G03 - Gastos en general",
"I01 - Construcciones",
"I02 - Mobilario y equipo de oficina por inversiones",
"I03 - Equipo de transporte",
"I04 - Equipo de cómputo y accesorios",
"I05 - Dados, troqueles, moldes, matrices y herramental",
"I06 - Comunicaciones telefónicas",
"I07 - Comunicaciones satelitales",
"I08 - Otra maquinaria y equipo",
"D01 - Honorarios médicos, dentales y gastos hospitalarios",
"D02 - Gastos médicos por incapacidad o discapacidad",
"D03 - Gastos funerales",
"D04 - Donativos",
"D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
"D06 - Aportaciones voluntarias al SAR",
"D07 - Primas por seguros de gastos médicos",
"D08 - Gastos de transportación escolar obligatoria",
"D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
"D10 - Pagos por servicios educativos (colegiaturas)",
"S01 - Sin efectos Fiscales"];

export const formasPago= ["Tarjeta credito","Tarjeta debito"]

export const imgMezcalEspadin= espadinImg;
export const imgMezcalTobala= tobalaImg;

const formatPuntos = (number) => {
  const conversionPuntos = new Intl.NumberFormat('en-US').format(Math.floor(number / 0.4));
  return conversionPuntos;
}

/*
solo se usa esta funcion para formatear el precio de productos de inventario (no mezcales) con motivos esteticos en UI,
se debe considerar revisar y --usar preferentemente-- a formatPrice() del archivo functions.js
*/
export const formatPrecio = (price) => {
  return parseFloat(price).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
// variables que definen las rutas para los cocteles de mezcales
export const coctel1 = "Mezc-old-Fashioned";
export const coctel2 = "Mezcal-Especial";
export const coctel3 = "Fresco-Mezcal";
// variables para definir el nombre que se desea mostrar en UI para cocteles de mezcales
export const nombreCoctel1 = "Mezc-old Fashioned";
export const nombreCoctel2 = "Mezcal Especial";
export const nombreCoctel3 = "Fresco Mezcal";

export const productos = [
  {
      "id": 1,
      "nombreCorto": "Espadín",
      "nombreLargo": "Don Hesiquio Espadín",
      "nombreTienda": "Mezcal Espadín Don Hesiquio Botella de 750 ml",
      "costo": 970,
      "puntos": formatPuntos(costoEspadin),
      "puntosObtenidos": "100",
      "costoSinDescuento": 970.00,
      "descuentoActual": 20,
      "imagenItem": espadinItem,
      "imagenItemMobile": espadinItemMobile,
      "imagenMezcal": mezcalEspadinCrema,
      "imagenMuestra": espadinMuestra,
      "imagenDescripcion": descripcionMezcalEspadin,
      "imagenMedalla": null,
      "descripcionImagen": "Don Hesiquio Espadín, tiene como principal característica un proceso de destilación tan noble y puro que te ofrecerá una experiencia organoléptica única a tu paladar.",
      "descripcionCorta": "Tiene como principal característica un proceso de destilación " +
        "tan noble y puro que te ofrecerá una experiencia organoléptica única A TU PALADAR. " +
        "Cristalino y con cuerpo, ofrece notas suaves de aroma a agave cocido y sutil sabor " +
        "ahumado, cuenta con matices cítricos, ideal para tomarlo derecho o en un cóctel de " +
        "altura. Su sabor es definido y no lastima ni produce aquella sensación de “quemar” " +
        "al pasar por tu garganta, otorgándote un retrogusto agradable y persistente.",
      "descripcionLarga": "Don Hesiquio® Espadín, tiene como principal característica un " +
        "proceso de destilación tan noble y puro que te ofrecerá una experiencia organoléptica " +
        "única A TU PALADAR. Cristalino y con cuerpo, ofrece suaves notas de aroma a agave " +
        "cocido y sutil sabor ahumado, cuenta con matices cítricos, ideal para tomarlo derecho " +
        "o en un cóctel de altura. Su sabor es definido y no lastima ni produce aquella " +
        "sensación de “quemar” al pasar por tu garganta, otorgándote un retrogusto agradable y persistente.",
      "saberMas": '<p className="letras-saber-mas" align="left" style="white-space:pre-wrap;">El agave Espadín pertenece a la especie A. angustifolia dicha ' +
        'palabra proviene del latín “angusti” que significa estrecho, ' +
        'debido a que sus hojas asemejan a espadas que apuntan al cielo, ' +
        'por su forma, es una de las variedades más sencillas de identificar ' +
        'y distinguir visualmente de otras variedades de maguey. ' +
        '<br /><br /> ' +
        'El agave Espadín es relativamente fácil de encontrar gracias a ' +
        'su amplia distribución por el territorio mexicano. Esta variedad ' +
        'se encuentra desde el nivel del mar y se cultiva en altitudes ' +
        'promedio de los 400 a los 800 msnm. ' +
        '<br /><br /> ' +
        'Existen actualmente muchas variedades tanto cultivadas como ' +
        'silvestres, siendo la especie de agave más cultivada ' +
        'en Oaxaca y muchos otros estados de la cuenca del Pacífico Mexicano, ' +
        'para la producción de mezcal. Es tan noble y benéfico que además ' +
        'de producción de mezcal, se utiliza con fines medicinales, ' +
        'sus flores son comestibles e históricamente sus hojas se han ' +
        'utilizado en la extracción de ixtle (fibra vegetal conocida ' +
        'por su resistencia). ' +
        '<br /><br /> ' +
        'El agave Espadín tarda de 6 a 8 años para alcanzar su madurez y ' +
        'transformarse en mezcal, de ahí que sea una de las variedades con ' +
        'menor tiempo de espera para la producción de mezcal. Es toda una ' +
        'experiencia organoléptica tomarlo derecho, no en vano es una de las ' +
        'variedades más deliciosas y apreciadas por el mezcalero. ' +
        '<br /><br /> ' +
        'Su madurez la alcanza a los diez años.</p>',
      "caracteristicas": [
        {
          title: "Tipo",
          desc: "Mezcal artesanal joven"
        },
        {
          title: "Agave",
          desc: "100% Agave angustifolia (Espadín)"
        },
        {
          title: "Grados de alcohol",
          desc: "42% Vol."
        },
        {
          title: "Capacidad",
          desc: "750 ml"
        },
        {
          title: "Temperatura recomendada de consumo",
          desc: "18°"
        },
        {
          title: "Categoría",
          desc: "Mezcal artesanal"
        },
        {
          title: "Clase",
          desc: "Joven"
        },
        {
          title: "Cocción",
          desc: "Horno cónico de piedra"
        },
        {
          title: "Molienda",
          desc: "En molino de piedra"
        },
        {
          title: "Fermentación",
          desc: "Tinas de sabino y piletas de mampostería"
        },
        {
          title: "Destilación",
          desc: "Alambique de cobre"
        },
        {
          title: "No. De destilaciones",
          desc: "2"
        },
        {
          title: "Región",
          desc: "Mixteca"
        }
      ],
      "caracteristicasSaborMezcal": [
        {
          icon: agaveIcon,
          desc: "Límpido, brillante, untuoso con destellos plata."
        },
        {
          icon: agaveIcon,
          desc: "Notas frutales ligeras, agave cocido y notas terrosas. Intensidad media alta, sabores frutales como plátano y ligeras notas a frutos rojos."
        },
        {
          icon: agaveIcon,
          desc: "Primer ataque floral, maguey maduro, acaramelado, aplatanado. Las notas ligeramente herbales nos acompañan en toda la cata."
        },
        {
          icon: agaveIcon,
          desc: "Citral con vegetal crudo, ligera herbalidad, humo. Textura y astringencia suave, con dulzor natural y fin de boca de olivo y laurel. "
        }
      ]
  },
  {
      "id": 2,
      "nombreCorto": "Tobalá",
      "nombreLargo": "Don Hesiquio Tobalá",
      "nombreTienda": "Mezcal Tobalá Don Hesiquio Botella de 750 ml",
      "costo": 1780,
      "puntos": formatPuntos(costoTobala),
      "puntosObtenidos": "150",
      "costoSinDescuento": 1780.00,
      "descuentoActual": 20,
      "imagenItem": tobalaItem,
      "imagenItemMobile": tobalaItemMobile,
      "imagenMezcal": mezcalTobalaCrema,
      "imagenMuestra": tobalaMuestra,
      "imagenDescripcion": descripcionMezcalTobala,
      "imagenMedalla": medallaTobala,
      "descripcionImagen": "Don Hesiquio Tobalá presenta un color cristalino, nos brinda exquisitas notas ligeras de frutos maduros del campo y un aroma delicioso de mieles y ahumados sutiles.",
      "descripcionCorta": "Presenta un color cristalino, al ser un agave silvestre " +
        "poco común, nos brinda exquisitas notas altas de almendra amarga y maderas " +
        "con un aroma delicioso de mieles y ahumados sutiles. Nuestro mezcal ofrece " +
        "una experiencia organoléptica holística que en sabor y aroma atrapará tu " +
        "paladar por su equilibrio y suavidad.",
      "descripcionLarga": "Don Hesiquio® Tobalá presenta un color cristalino, "+
      "al ser un agave silvestre poco común, "+
      "nos brinda exquisitas notas ligeras de "+
      "frutos maduros del campo y un aroma delicioso "+
      "de mieles y ahumados sutiles. Nuestro mezcal "+
      "ofrece una experiencia organoléptica holística "+
      "que en sabor y aroma atrapará tu paladar por su equilibrio y suavidad.",
      "saberMas": '<p className="letras-saber-mas" align="left" style="white-space:pre-wrap;">"Despierta el espíritu, calma el desamor, ' +
      'estimula la imaginación, borra resentimientos, ' +
      'acompaña en la soledad y hace que el mundo se vea mucho mejor."<br /><br /> ' +
      'Andrés Henestrosa (escritor mexicano) ' +
      '<br /><br /> ' +
      'Su madurez la alcanza a los diez años. ' +
      '<br /><br /> ' +
      'El mezcal Tobalá es conocido por algunos como el ' +
      '“Rey de los Mezcales”. Este mezcal se elabora con ' +
      'maguey Tobalá o <i>Agave potatorum</i> como se le conoce ' +
      'científicamente. ' +
      'Algunos mezcaleros afirman además que el maguey ' +
      'Tobalá es el que menos tarda en fermentar, pues, ' +
      'tras ser cocido en un horno de piedra, con leña de encino o ' +
      'de sauce, solo le toma de ' +
      'tres a cinco días aproximadamente; probablemente a causa de ' +
      'su gran reserva de hidrógeno, dando como resultado un mezcal ' +
      'ancestral con un grado de alcohol que ronda de los 47° a los 50°. ' +
      'A pesar de lo complicado que era encontrar agave Tobalá para la ' +
      'producción de mezcal, en los años ochenta este se convirtió en ' +
      'uno de los favoritos en las fiestas y más consumidos en México, ' +
      'en gran parte debido a su extrañeza y a lo impactante de su esencia. ' +
      'Debido a esta gran demanda por querer probar los destilados del ' +
      'agave Tobalá y por falta de un organismo que regulara y cuidara ' +
      'el consumo de este maguey, ¡casi se extingue! ' +
      'Al día de hoy es un agave muy cuidado por los pobladores y los ' +
      'maestros mezcaleros de las diferentes regiones del país. ' +
      '<br /><br /> ' +
      'Generalmente se recomienda como tentempié acompañado de carnes frías, ' +
      'carnes blancas a la parrilla y quesos con corteza suave.</p>',
      "caracteristicas": [
        {
          title: "Tipo",
          desc: "Mezcal artesanal joven"
        },
        {
          title: "Agave",
          desc: "100% Agave potatorum (Tobalá)"
        },
        {
          title: "Grados de alcohol",
          desc: "44% Vol."
        },
        {
          title: "Capacidad",
          desc: "750 ml"
        },
        {
          title: "Temperatura recomendada de consumo",
          desc: "18°"
        },
        {
          title: "Categoría",
          desc: "Mezcal artesanal"
        },
        {
          title: "Clase",
          desc: "Joven"
        },
        {
          title: "Cocción",
          desc: "En molino de piedra"
        },
        {
          title: "Molienda",
          desc: "Tinas de sabino y piletas de mampostería"
        },
        {
          title: "Fermentación",
          desc: "Horno cónico de piedra"
        },
        {
          title: "Destilación",
          desc: "Alambique de cobre"
        },
        {
          title: "No. De destilaciones",
          desc: "2"
        },
        {
          title: "Región",
          desc: "Mixteca"
        }
      ],
      "caracteristicasSaborMezcal": [
        {
          icon: agaveIcon,
          desc: "Notas de hoja de tabaco seco, tabaco fermentado, cuero, discreto y floral al mismo tiempo, con nardos y flor de azar."
        },
        {
          icon: agaveIcon,
          desc: "Perfil frutal, durazno, ligero láctico."
        },
        {
          icon: agaveIcon,
          desc: "Expresión herbal, cítrico, mineral. Entrada dulce, fruta madura, sensación herbal media, final tostado y ahumado."
        },
        {
          icon: agaveIcon,
          desc: "Gran cuerpo, brillante, limpio y transparente. Muy herbal, intensa hoja santa y hierbabuena, orégano freso. Franqueza herbal y gran fin de boca."
        }
      ]
  }
]

export const productos2 = [
  {
      "id": 3,
      "nombre": nombreProductoInventario1,
      "costo": costoProductoInventario1,
      "costoSinDescuento": costoProductoInventario1,
      "puntos": "800",
      "imagenItem": imagenProductoInventario1,
      "imagenMuestra": imagenProductoInventario1,
      "descripcionCorta": `La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.`,
      "descripcionLarga": "La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.",
  },
  {
      "id": 4,
      "nombre": nombreProductoInventario2,
      "costo": costoProductoInventario2,
      "costoSinDescuento": costoProductoInventario2,
      "puntos": "1000",
      "imagenItem": imagenProductoInventario2,
      "imagenMuestra": imagenProductoInventario2,
      "descripcionCorta": `La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.`,
      "descripcionLarga": "La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.", 
  },
  {
      "id": 5,
      "nombre": nombreProductoInventario3,
      "costo": costoProductoInventario3,
      "costoSinDescuento": costoProductoInventario3,
      "puntos": "2500",
      "imagenItem": imagenProductoInventario3,
      "imagenMuestra": imagenProductoInventario3,
      "descripcionCorta": `La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.`,
      "descripcionLarga": "La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso.”",
  },
  {
      "id": 6,
      "nombre": nombreProductoInventario4,
      "costo": costoProductoInventario4,
      "costoSinDescuento": costoProductoInventario4,
      "puntos": "2500",
      "imagenItem": imagenProductoInventario4,
      "imagenMuestra": imagenProductoInventario4,
      "descripcionCorta": `La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.`,
      "descripcionLarga": "La boca ancha de la jícara permite percibir el aroma del mezcal antes de darle el “beso”.",
  }

]