import "./style.css";
import React, { useContext, useState, useEffect } from "react";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { useForm } from "react-hook-form";
import UserPool from "../../userPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../../components/context/Context";
import useDigitInput from "react-digit-input";

function CodigoDatosPersonales() {
  const { onLogin, getSession } = useContext(MyContext);
  const [error, setError] = useState();
  const [errorClass, setErrorClass] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [value, onChange] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange,
  });

  function finalCheck() {
    let codigo = value;
    console.log(codigo);
    if (codigo !== "" && codigo.length === 6) {
      let regEx = /^[0-9]{1,6}$/;
      if (regEx.test(codigo)) {
        handleEmailVerification(codigo);
      } else {
        setError(<p>C&oacute;digo no v&aacute;lido</p>);
        setErrorClass(true);
      }
    } else {
      setError(<p>C&oacute;digo no v&aacute;lido</p>);
      setErrorClass(true);
    }
  }

  const handleEmailVerification = (code) => {
    getSession().then(({ user, email }) => {
      user.verifyAttribute("email", code, {
        onSuccess: (data) => {
          console.log("Verification success:", data);
          editEmailDataBase();
          navigate("/edicion-correo-success");
        },
        onFailure: (err) => {
          console.error("Verification failure:", err);
          setError(<p>C&oacute;digo no v&aacute;lido</p>);
        },
      });
    });
  };

  const editEmailDataBase = () => {
    const apiUrl =
      "https://api.mezcaldonhesiquio.com/editarCorreo";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        correo: sessionStorage.getItem("editMail"),
        idUser: sessionStorage.getItem("idUser"),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Respuesta en editEmailDataBase() exitosa --> ${data}`);
      })
      .catch((error) => {
        console.log(`Hubo un error con editEmailDataBase() --> ${error}`);
      });
  };

  return (
    <>
      <NavBar />
      <div className="datos-envio ">
        <div className="container">
          <div className="row justify-content-center ">
            <div className=" text-center row gap-4">
              <h3>Verifica tu correo</h3>
              <p>
                Como medida de seguridad, ingresa el código de 6 dígitos <br />
                que enviamos al nuevo correo.
              </p>
              <div className="container-codigo container-blanco col-lg-5 col-md-8 col-sm-12">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  className="col-12 col-md-12 align-self-center text-center"
                >
                  <div className="row">
                    <div
                      className="d-flex justify-content-center col-xl-12 col-lg-7 col-md-11 col-sm-12 col-12 text-center"
                      style={{
                        paddingRight: "2rem",
                        paddingLeft: "2rem",
                        margin: "0 auto",
                      }}
                    >
                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="firstDigit"
                        tabIndex="-1"
                        autoFocus
                        {...digits[0]}
                      />

                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="secondDigit"
                        {...digits[1]}
                      />

                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="thirdDigit"
                        {...digits[2]}
                      />

                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="fourthDigit"
                        {...digits[3]}
                      />

                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="fifthDigit"
                        {...digits[4]}
                      />

                      <input
                        className={`${errorClass ? "errorClass" : "digits"}  `}
                        inputMode="decimal"
                        id="lastDigit"
                        {...digits[5]}
                        tabIndex="-1"
                      />
                    </div>
                  </div>
                  <div
                    className="row justify-content-center"
                    style={{ marginBottom: "1rem", marginTop: "2rem" }}
                  >
                    <div className="col-xl-7 col-lg-7 col-md-11 col-sm-12 col-12 text-center">
                      <div className="row mx-auto">
                        {!submitted ? (
                          <button
                            className="span-verificar-codigo"
                            id="spanVerificarCodigoOperador"
                            tabIndex="-1"
                            onClick={finalCheck}
                          >
                            Verificar
                          </button>
                        ) : (
                          <button
                            className="span-verificar-codigo-disabled"
                            id="spanVerificarCodigoOperador"
                            tabIndex="-1"
                            style={{ cursor: "not-allowed" }}
                          >
                            Espera
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <div className=" px-4 col-8 my-2 mx-auto text-start">
                  <button className="mt-2 texto-oro ">
                    Reenviar c&oacute;digo
                  </button>
                </div>

                {error && (
                  <div className=" contenedor-error-codigo">
                    {" "}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className=""
                    >
                      <path
                        d="M8.5 13.5H9.5V8H8.5V13.5ZM9 6.57693C9.17437 6.57693 9.32053 6.51795 9.43848 6.4C9.55641 6.28205 9.61537 6.13589 9.61537 5.96152C9.61537 5.78718 9.55641 5.64102 9.43848 5.52307C9.32053 5.40512 9.17437 5.34615 9 5.34615C8.82563 5.34615 8.67948 5.40512 8.56153 5.52307C8.44359 5.64102 8.38463 5.78718 8.38463 5.96152C8.38463 6.13589 8.44359 6.28205 8.56153 6.4C8.67948 6.51795 8.82563 6.57693 9 6.57693ZM9.00335 18C7.7588 18 6.58872 17.7638 5.4931 17.2915C4.39748 16.8192 3.44444 16.1782 2.63397 15.3685C1.82352 14.5588 1.18192 13.6066 0.70915 12.512C0.236383 11.4174 0 10.2479 0 9.00335C0 7.7588 0.236158 6.58872 0.708475 5.4931C1.18081 4.39748 1.82183 3.44444 2.63153 2.63398C3.44123 1.82353 4.39337 1.18192 5.48795 0.709151C6.58255 0.236384 7.75212 0 8.99665 0C10.2412 0 11.4113 0.236158 12.5069 0.708475C13.6025 1.18081 14.5556 1.82182 15.366 2.63152C16.1765 3.44122 16.8181 4.39337 17.2908 5.48795C17.7636 6.58255 18 7.75212 18 8.99665C18 10.2412 17.7638 11.4113 17.2915 12.5069C16.8192 13.6025 16.1782 14.5556 15.3685 15.366C14.5588 16.1765 13.6066 16.8181 12.512 17.2909C11.4174 17.7636 10.2479 18 9.00335 18ZM9 17C11.2333 17 13.125 16.225 14.675 14.675C16.225 13.125 17 11.2333 17 9C17 6.76667 16.225 4.875 14.675 3.325C13.125 1.775 11.2333 1 9 1C6.76667 1 4.875 1.775 3.325 3.325C1.775 4.875 1 6.76667 1 9C1 11.2333 1.775 13.125 3.325 14.675C4.875 16.225 6.76667 17 9 17Z"
                        fill="#1E1E1C"
                      />
                    </svg>
                    {error}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CodigoDatosPersonales;
