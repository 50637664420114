import "./datos-personales.css";

import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { MyContext } from "../../components/context/Context";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarUsuario from "../components/SidebarUsuario";
import DatosPerfil from "./components/DatosPerfil";
import MisPedidos from "./components/MisPedidos";
import HistorialCompra from "./components/HistorialCompra";
// import SumarPuntos from "./components/SumarPuntos";
import SumarPuntosComponent from "./components/SumarPuntosComponent";
// import SumarPuntos from "../SumarPuntos/SumarPuntos";
import CoctelesyMaridajes from "./components/CoctelesyMaridajes";

function DatosPersonales() {
  const { registrado } = useContext(MyContext);
  const [componentToRender, setComponentToRender] = useState(<DatosPerfil />);
  let { section } = useParams();

  useEffect(() => {
    switch (section) {
      case "historial-compra":
        setComponentToRender(<HistorialCompra />);
        break;
      case "mis-pedidos":
        setComponentToRender(<MisPedidos />);
        break;
      default:
        setComponentToRender(<DatosPerfil />);
    }
  }, [section]); // Esto se ejecutará cada vez que el parámetro 'section' cambie.

  return (
    <>
      <NavBar />
      <div className="class-container">
        <div className="hide-on-mobile">
          <SidebarUsuario />
        </div>
        {componentToRender}
      </div>
      <Footer />
    </>
  );
}

export default DatosPersonales;
