import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import "./Style.css";
import "./StyleMenuNegro.css";
import { Modal, ModalBody } from "reactstrap";

//Imagenes
import img_logo_dorado from "../images/logo-dorado-black.svg";
import casa from "../images/home.svg";
import closeNegro from "../images/close-negro.svg";
import europea from "../images/europea.svg";
import amazon from "../images/amazon.svg";
import carrito from "../images/carrito.svg";
import { NavBar } from "./NavBar";
//componentes
const ContenidoH = styled.header`
  background-image: linear-gradient(
    to bottom,
    #fff,
    rgba(255, 255, 255, 0.4) 67%,
    rgba(255, 255, 255, 0) 99%
  );

  position: fixed;
  z-index: 1000;
  width: 100%;
  @media (max-width: 1280.98px) {
    height: 6.7rem;
  }

  @media (min-width: 1280.98px) {
    height: 9.7rem;
  }
`;

const urlAmazon = "https://www.amazon.com.mx/s?me=A3G4UIDIYI991K&ref=sf_seller_app_share_new";
const urlEuropea = "https://www.laeuropea.com.mx/catalogsearch/result/?q=don+hesiquio+";


//funciones
function haber(e) {
  e.preventDefault();

  if ($("#menu-navegador").hasClass("menu-navegador")) {
    $(".menu-navegador").removeClass("menu-navegador").addClass("bg-dorado");
    $("#dibujo-inicio").css("display", "none");
    $("#letra-inicio").css("display", "block");
    $("#dibujo-carrito").css("display", "none");
    $("#items-carrito").css("display", "none");
    $("#letra-carrito").css("display", "block");
    $("#contenido-menu-1").css("display", "block");
    $("#contenido-menu-2").css("display", "none");
  } else if ($("#menu-navegador").hasClass("bg-dorado")) {
    $(".bg-dorado").removeClass("bg-dorado").addClass("menu-navegador");
    $("#dibujo-inicio").css("display", "block");
    $("#letra-inicio").css("display", "none");
    $("#dibujo-carrito").css("display", "block");
    $("#items-carrito").css("display", "block");
    $("#letra-carrito").css("display", "none");
    $("#contenido-menu-1").css("display", "none");
    $("#contenido-menu-2").css("display", "block");
  }
  if ($("#logo-inicio").hasClass("Rectngulo-12")) {
    $(".Rectngulo-12").removeClass("Rectngulo-12").addClass("oculto");
  } else if ($("#logo-inicio").hasClass("oculto")) {
    $(".oculto").removeClass("oculto").addClass("Rectngulo-12");
  }

  if ($("#nombreMenuUno").hasClass("texto-negro")) {
    $(".texto-negro")
      .removeClass("texto-negro")
      .addClass("texto-blanco-center");
  } else if ($("#nombreMenuUno").hasClass("texto-blanco-center")) {
    $(".texto-blanco-center")
      .removeClass("texto-blanco-center")
      .addClass("texto-negro");
  }

  if ($("#navbarInicio").hasClass("left")) {
    $(".left").removeClass("left").addClass("nueva");
  } else if ($("#navbarInicio").hasClass("nueva")) {
    $(".nueva").removeClass("nueva").addClass("left");
  }
}

export function HeaderWhite() {
  const [activo, setActivo] = useState(true);
  // Modal open state
  const [modal, setModal] = useState(false);

  // Toggle for Modal
  const toggle = (e) => {
    e.preventDefault();
    setModal(!modal);
  };
  // Modal open state
  const [modalProximo, setModalProximo] = useState(false);

  // Toggle for Modal
  const toggleProximo = (e) => {
    e.preventDefault();
    setModalProximo(!modalProximo);
  };
  let actual = window.pageYOffset;
  window.onscroll = function () {
    let adelante = window.pageYOffset;
    if (actual >= adelante) {
      setActivo(true);
    } else {
      setActivo(false);
    }
    actual = adelante;
    $(window).scroll(function (event) {
      var scrollTop = $(window).scrollTop();
      if (scrollTop < 115) {
        setActivo(true);
      }
    });
  };
  function dirigirEuropea(e) {
    e.preventDefault();
    window.open(urlEuropea, "_blank");
  }
  function dirigirAmazon(e) {
    e.preventDefault();
    //toggleProximo(e);
    window.open(urlAmazon, '_blank');
  }
  useEffect(() => {
    if (
      sessionStorage.getItem("activo") == "false" ||
      sessionStorage.getItem("activo") == null ||
      sessionStorage.getItem("activo") == ""
    ) {
      window.location.href = "/";
    }
  });

  return activo ? (
    <ContenidoH>
      <NavBar />
      <Modal isOpen={modal} toggle={toggle} modalTransition={{ timeout: 2000 }}>
        <ModalBody>
          <p align="right">
            <NavLink to="#" onClick={toggle}>
              <img src={closeNegro} width="6%" height="6%" />
            </NavLink>
          </p>
          <center>
            <span className="titulo-compra">Disfruta ya</span>
            <br />
            <br />
            <div style={{ width: "65%" }}>
              <span className="texto-compra">
                Adquiere tu botella de Don Hesiquio® en nuestra tienda
              </span>
            </div>
          </center>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ">
                <NavLink
                  to="#"
                  style={{ cursor: "hand" }}
                  onClick={dirigirEuropea}
                >
                  <img src={europea} width="100%" height="100%" />
                </NavLink>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ">
                <div className="icono-amazon">
                  <NavLink
                    to="#"
                    title="Próximamente..."
                    style={{ cursor: "hand" }}
                    onClick={dirigirAmazon}
                  >
                    <img src={amazon} width="80%" height="80%" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <center>
            <div>
              <span className="texto-compra">
                Comun&iacute;cate al n&uacute;mero 56 3458 0002 para ventas
              </span>
            </div>
          </center>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalProximo}
        toggle={toggleProximo}
        modalTransition={{ timeout: 2000 }}
      >
        <ModalBody>
          <p align="right">
            <NavLink to="#" style={{ cursor: "hand" }} onClick={toggleProximo}>
              <img src={closeNegro} width="6%" height="6%" />
            </NavLink>
          </p>
          <center>
            <div style={{ width: "65%" }}>
              <span className="titulo-proximo">Pr&oacute;ximamente...</span>
            </div>
            <br />
            <br />
            <button
              className="btn-rectangulo-principal btn bg-dorado contactano  texto-blanco-proximo"
              type="button"
              style={{ height: "45px" }}
              onClick={toggleProximo}
            >
              Aceptar
            </button>
          </center>
        </ModalBody>
      </Modal>
    </ContenidoH>
  ) : (
    <></>
  );
}
