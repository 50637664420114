import React from "react";
import imgLogoBlanco from "../../images/logo-blanco.svg";
import facebookIcon from "../../images/Facebook.svg";
import instagramIcon from "../../images/Instagram.svg";
import whatsappIcon from "../../images/Whatsapp.svg";
import "./style.css";

const socialMediaUrls = {
	facebook: "https://www.facebook.com/profile.php?id=100087593805690",
	instagram: "https://www.instagram.com/mezcaldonhesiquio/",
	whatsapp: "https://wa.me/5634580002",
};

export function Footer() {
	return (
		<>
		<footer className="footer">
			<div className="footer__center">
				<img src={imgLogoBlanco} alt="Logo" />
			</div>
			<div className="footer__divider">
				<div className="footer__contact-info">
					<span className="footer__title">¿Tienes alguna duda? ¡Llámanos!</span>
					<a
						href={socialMediaUrls.whatsapp}
						target="_blank"
						className="footer__social-media-link _whatsApp"
					>
						<img
							src={whatsappIcon}
							alt="WhatsApp"
							className="footer__social-media-icon"
						/>
						<span>5634580002</span>
					</a>
					<span className="footer__title">Horario de atención telefónico:</span>
					<p>Lunes a Viernes de 09:00 am a 07:00 pm</p>
					{/* <div>Correo: contacto@mezcaldonhesiquio.com</div> */}
				</div>
				<div className="footer__corporate-offices">
					<span className="footer__title">Oficinas corporativas:</span>
					<p>Miguel Angel de Quevedo 8, Desp 108, Col. Ex hacienda Guadalupe Chimalistac, C.P 01050, &Aacute;lvaro Obregón, CDMX.</p>
					<div className="footer__social-media--container">
						<a
							href={socialMediaUrls.facebook}
							target="_blank"
							className="footer__social-media-link"
						>
							<img
								src={facebookIcon}
								alt="Facebook"
								className="footer__social-media-icon"
							/>
						</a>
						<a
							href={socialMediaUrls.instagram}
							target="_blank"
							className="footer__social-media-link"
						>
							<img
								src={instagramIcon}
								alt="Instagram"
								className="footer__social-media-icon"
							/>
						</a>
					</div>
				</div>
			</div>
			<div className="footer__center">
				<div>©2024 Mezcal Don Hesiquio®</div>
			</div>
		</footer>
		</>
	);
}
