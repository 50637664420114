import "../../style/nuestros-mezcales.css";

function NuestrosMezcales() {
  return (
    <>
      <div className="main-container-nuestros-mezcales">
        <div className="main-banner-nuestros-mezcales">
          <div className="main-banner-nuestros-mezcales-mobile">
            <div className="container-nuestros-mezcales">
              <div className="description-nuestros-mezcales">
                <h5>NUESTROS MEZCALES</h5>
                <p>
                Caracterizados por ser suaves y agradables, que encantan a todos los paladares.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NuestrosMezcales;
