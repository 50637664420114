import "./style.css";
import { useState, useContext, useEffect } from "react";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { MyContext } from "../../components/context/Context";
import { useNavigate } from "react-router-dom";

function SuccessEdicionCorreo() {
  const { logout } = useContext(MyContext);

  useEffect(() => {
    const logoutSession = () => {
      setTimeout(() => {
        logout();
        sessionStorage.clear();
        window.location.href = "/Login";  
      }, 5000);
    };

    logoutSession();
  }, []);

  return (
    <>
      <NavBar />
      <div className="success-message-main-container">
        <div className="success-message-container">
          <h3>¡Correo actualizado!</h3>
          <p>
            El correo ha sido validado <br /> a continuaci&oacute;n se
            cerrar&aacute; su sesi&oacute;n
          </p>
          <p>Favor de iniciar sesi&oacute;n nuevamente</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SuccessEdicionCorreo;
