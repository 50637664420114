import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { MyContext } from "../../../components/context/Context";
import Loader from "../../../images/loader/Rolling-1s-200px-gold.svg"

export const FormDatosEnvio = ({
  setDataForm,
  setIsLocale,
  continuar,
  setContinuar,
}) => {
  const [guardarDatos, setGuardarDatos] = useState(false);
  const [dataColonias, setDataColonias] = useState([]);
  const { registrado } = useContext(MyContext);
  const idUsuario = sessionStorage.getItem("idUser");
  const emailUser = sessionStorage.getItem("emailUser");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [loading, setLoading] = useState();

  function guardarDatosEnvioRequest(data) {
    console.log(data);
    const coloniaEncontrada = dataColonias.find(
      (colonia) => colonia.nombreColonia == data.colonia
    );
    console.log(coloniaEncontrada);
    const URL =
      "https://api.mezcaldonhesiquio.com/guardarDatosEnvio";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        idUsuario: idUsuario,
        nombres: data.nombre,
        apellidos: data.apellidos,
        correo: registrado ? emailUser : data.email,
        telefono: data.celular,
        calle: data.calle,
        numExt: data.numExt,
        numInt: data.numInt,
        idColonia: coloniaEncontrada.idColonia,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Datos envio guardado con exito" + JSON.stringify(data));
      })
      .catch((err) => {
        alert(
          "Hubo un problema al guardar sus datos, favor de contactar al equipo de soporte"
        );
        console.log(err);
      });
  }

  const fetchCodigoPostalData = async (cp) => {
    const url =
      "https://api.mezcaldonhesiquio.com/datosEnvio";

    try {
      setLoading(true)
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          codigoPostal: cp,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.results);
        setDataColonias(data.results);
        clearErrors("cp")
        clearErrors("ciudad")
        setLoading(false)
      } else {
        console.error(
          "Error al obtener datos de envío:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.log(`Hubo un error ${error}`);
    }
  };

  const initialState = {
    DatosContactoObj: {
      nombre: "",
      apellidos: "",
      celular: "",
      email: "",
    },
    DireccionEnvioObj: {
      calle: "",
      numExt: "",
      numInt: "",
      entreCalle: "",
      ref: "",
      cp: "",
      colonia: "",
      ciudad: "",
      estado: "",
      foraneo: false,
      costoEnvio: "",
    },
  };

  let Data = JSON.parse(localStorage.getItem("datosEnvio"))
    ? JSON.parse(localStorage.getItem("datosEnvio"))
    : initialState;
  console.log(Data);
  const [previousData, setpreviousData] = useState(Data);

  useEffect(() => {
    setpreviousData(Data);
  }, []);

  useEffect(() => {
    console.log("continuar");

    if (continuar) {
      handleSubmit(onSubmit)();
    }
    setContinuar(false);
  }, [continuar]);

  useEffect(() => {
    if (dataColonias.length > 0) {
      setValue("ciudad", dataColonias[0].nombreMunicipio);
      setValue("estado", dataColonias[0].nombreEstado);
    }
  }, [dataColonias, setValue]);

  const [stateValue, setStateValue] = useState(
    previousData.DireccionEnvioObj.estado
  );

  function onSubmit(data) {
    console.log("datos de envio:");
    console.log(data)
    if (data.estado === "Ciudad de México") {
      setIsLocale(true);
    } else {
      setIsLocale(false);
    }
    if (guardarDatos) {
      guardarDatosEnvioRequest(data);
    }
    setDataForm(data);
  }

  function changeState(event) {
    if (event.target.value === "Ciudad de México") {
      setIsLocale(true);
    } else {
      setIsLocale(false);
    }
    setStateValue(event.target.value);
  }

  function handleChangeBox(e) {
    setGuardarDatos(e.target.checked);
    console.log(e.target.checked);
  }

  const handleCodigoPostalChange = async (event) => {
    const nuevoCodigoPostal = event.target.value;
    const codigoPostal = event.target.value;
    if (nuevoCodigoPostal.length === 5) {
      console.log(event.target.value);
      fetchCodigoPostalData(codigoPostal);
    }
  };

  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)} className="datos-envio__form">
          <section className="datos-envio__form__container">
            <div className="datos-envio__form__group-inputs">
              <label>
                Nombre(s)
                <input
                  className={errors.nombre ? "input-error" : ""}
                  type="text"
                  defaultValue={previousData.DatosContactoObj.nombre}
                  {...register("nombre", {
                    required: "Ingresa tu(s) nombre(s)",
                  })}
                />
                {errors.nombre && <p>{errors.nombre.message}</p>}
              </label>
              <label>
                Apellido(s)
                <input
                  className={errors.apellidos ? "input-error" : ""}
                  type="text"
                  defaultValue={previousData.DatosContactoObj.apellidos}
                  {...register("apellidos", {
                    required: "Ingresa tu(s) apellidos(s)",
                  })}
                />
                {errors.apellidos && <p>{errors.apellidos.message}</p>}
              </label>
              <label>
                Correo electrónico
                {registrado ? (
                  <input
                  className={errors.email ? "input-error" : ""}
                  type="email"
                  defaultValue={emailUser}
                  disabled
                  value={emailUser}
                  placeholder="ejemplo@correo.com"
                />
                ) : (
                  <input
                  className={errors.email ? "input-error" : ""}
                  type="email"
                  defaultValue={previousData.DatosContactoObj.email}
                  {...register("email", {
                    required: "Ingresa un correo electrónico",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Dirección de correo electrónico inválida",
                    },
                  })}
                  placeholder="ejemplo@correo.com"
                />
                )}
                {errors.email && <p>{errors.email.message}</p>}
              </label>
              <label>
                Número celular
                <input
                  className={errors.celular ? "input-error" : ""}
                  type="tel"
                  defaultValue={previousData.DatosContactoObj.celular}
                  {...register("celular", {
                    required: "Ingresa un número celular",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Ingrese un número de celular válido",
                    },
                  })}
                  onInput={(e) => {
                    e.preventDefault();
                    const maxLength = 10;
                    const inputValue = e.target.value;
                    if (inputValue.length > maxLength) {
                      e.target.value = inputValue.slice(0, maxLength);
                    }
                  }}
                />
                {errors.celular && <p>{errors.celular.message}</p>}
              </label>
            </div>

            <div className="datos-envio__form-group-inputs--auto">
              <label className="input--calle">
                Calle
                <input
                  className={errors.calle ? "input-error" : ""}
                  type="text"
                  defaultValue={previousData.DireccionEnvioObj.calle}
                  {...register("calle", {
                    required: "Ingresa la calle y el número",
                  })}
                />
                {errors.calle && <p>{errors.calle.message}</p>}
              </label>
              <label className="input--numExt">
                N&uacute;mero exterior
                <input
                  className={errors.numExt ? "input-error" : ""}
                  type="text"
                  defaultValue={previousData.DireccionEnvioObj.numExt}
                  {...register("numExt", {
                    required: "Ingresa el número exterior",
                  })}
                />
                {errors.numExt && <p>{errors.numExt.message}</p>}
              </label>
              <label className="input--numInt">
                N&uacute;mero interior
                <input
                  type="text"
                  defaultValue={previousData.DireccionEnvioObj.numInt}
                  placeholder="(Opcional)"
                />
              </label>
            </div>
            

            <div className="datos-envio__form__group-inputs">
              <label>
                C&oacute;digo postal
                <input
                  className={errors.cp ? "input-error" : ""}
                  id="codigoPostal"
                  {...register("cp", { required: "Ingresa el código postal",
                  pattern: {
                    value: /^[0-9]{5}$/,
                    message: "Ingresa un código postal válido"
                  },
                   })}
                  onInput={(e) => {
                    e.preventDefault();
                    const maxLength = 5;
                    const inputValue = e.target.value;
                    if (inputValue.length > maxLength) {
                      e.target.value = inputValue.slice(0, maxLength);
                    }
                  }}
                  type="number"
                  onChange={handleCodigoPostalChange}
                />
                {errors.cp && <p>{errors.cp.message}</p>}
              </label>
              <label>
                Colonia
                {errors.colonia && <p>{errors.colonia.message}</p>}
                <select
                  {...register("colonia", { required: true })}
                  disabled={dataColonias.length <= 0}
                >
                  <option value="" disabled selected>
                    Seleccione colonia
                  </option>
                  {dataColonias.map((colonia) => (
                    <option
                      key={colonia.idColonia}
                      value={colonia.nombreColonia}
                    >
                      {colonia.nombreColonia}
                    </option>
                  ))}
                </select>
                {loading ? <img src={Loader} style={{ width: "1rem"}} /> : null}
              </label>
              <label>
                Ciudad
                <input
                  disabled={true}
                  className={errors.ciudad ? "input-error" : ""}
                  type="text"
                  // defaultValue={previousData.DireccionEnvioObj.ciudad}
                  value={
                    dataColonias.length > 0
                      ? dataColonias[0].nombreMunicipio
                      : ""
                  }
                  {...register("ciudad", {
                    required: "Ingresa una ciudad",
                  })}
                />
                {loading ? <img src={Loader} style={{ width: "1rem"}} /> : null}
              </label>
              <label>
                Estado
                <input
                  disabled={true}
                  className={errors.ciudad ? "input-error" : ""}
                  {...register("estado", {
                    required: "Selecciona un estado",
                  })}
                />
                {loading ? <img src={Loader} style={{ width: "1rem"}} /> : null}
              </label>
              {registrado && (
                <div className="row align-items-center">
                  <label className="col-6 texto-negro-sans fs-6">
                    Guardar datos de envio
                  </label>
                  <input type="checkbox" onChange={handleChangeBox} />
                </div>
              )}
            </div>
          </section>
          <div className="datos-envio__form__buttons">
            {/* <Link to="/Carrito">
          <button type="button" className="btn-secondary--black">
            Regresar
          </button>
        </Link> */}
          </div>
        </form>
      </div>
    </>
  );
};
