import styled from "styled-components";
import { FaPhone } from "react-icons/fa";
import { FaLink } from "react-icons/fa";

const Portada = styled.div`
background-color: black;
  background-size: cover;
  position:relative;

`;

export default function PresentacionQR() {
    return(
        <>
            <Portada className="presentacion-container">
                <div className="mezcal-presentacion">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-11 col-md-10 col-lg-8 col-xl-6 col-xxl-4">
                                <div className="contenedor-profile-card">
                                    <div className="contenedor-card-body">
                                        <h3 className="card-title">Mezcal Don Hesiquio</h3>
                                        <hr/>
                                        <p className="card-sub-title">
                                            <FaPhone style={{marginRight: 10+'px'}}/>
                                            <strong>Número de Teléfono</strong>
                                        </p>
                                        <a className="card-datos" href="tel:56 3458 0002">56 3458 0002</a>
                                        <p className="card-sub-title">
                                            <FaLink style={{marginRight: 10+'px'}}/>
                                            <strong>Sitio Web</strong>
                                        </p>
                                        <a className="card-datos" href="https://www.mezcaldonhesiquio.com/">https://www.mezcaldonhesiquio.com/</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Portada>
        </>
    )
}