import { Footer } from "../components/Footer";
import { Header } from "../components/MenuNegro";
import ReactPlayer from "react-player";
// data
import { elaboracionData } from "../data/elaboracionData";
// styles
import "../style/elaboracion.css";
// components
import React, { useState, useEffect } from "react";
import { NavBar } from "../components/NavBar";
import VideoPlayer from "./components/VideoPlayer";

export default function Mezcales() {
  const urlProcesoVideo =
  "https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/videos/Elaboracion_Don_Hesiquio.mp4";

  return (
    <>
      <NavBar />
      <div className="elaboracion-section">
        <div className="elaboracion-main-img-container"><div className="back-shadow-mobile"><div className="title-mobile"><p>CULTIVADOS POR GIGANTES</p></div></div></div>
        <ul id="elaboracion-unordered-list">
          {elaboracionData.map((element, index) => (
            <div
              className={
                index % 2 == 0
                  ? "description-container"
                  : "description-container2"
              }
            >
              <div className="description-icon-container">
                <img src={element.icon} />
              </div>
              <li>
                <h3>{element.title}</h3>
                <p>{element.description}</p>
              </li>
            </div>
          ))}
        </ul>
        <VideoPlayer videoUrl={urlProcesoVideo} 
          width="100%" 
          height="100%" 
        />
        <div className="bottom-separation"></div>
      </div>
      <Footer />
    </>
  );
}
