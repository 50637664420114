// css
import "../style/nuestros-mezcales.css";
import blueCheck from "../images/mezcales/blue-check.svg";

import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ListaProductos from "./ListaProductos";
import NuestrosMezcales from "./NuestrosMezcales/NuestrosMezcales";

function Mezcales() {
  const navigate = useNavigate();
  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getDatosMezcal";
  const [datosMezcal, setDatosMezcal] = useState();
  const [dataEspadin, setDataEspadin] = useState();
  const [dataTobala, setDataTobala] = useState();
  const [loading, setLoading] = useState(false);

  const handleClickItem = (event) => {
    if (event.target.className === "espadin-img-containenr") {
      navigate("/mezcal-espadin");
      console.log("mezcal espadin");
    } else {
      navigate("/mezcal-tobala");
      console.log("mezcal tobala");
    }
  };

  const getDatosMezcal = async () => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          idProducto: 1,
        }),
      });
      const data = await response.json();
      setDatosMezcal(data.message[0]);
      const mezcalEspadinData = data.message.find(
        (element) => element.idProducto === 1
      );
      setDataEspadin(mezcalEspadinData);
      const mezcalTobalaData = data.message.find(
        (element) => element.idProducto === 2
      );
      setDataTobala(mezcalTobalaData);

      console.log(`Response de getDatosMezcal: ${response}`);
      console.log(response)
    } catch (error) {
      console.log(`Error request getDatosMezcal: ${error}`);
    }
  };

  useEffect(() => {
    getDatosMezcal();
  }, []);

  return (
    <>
      <NavBar />
      <NuestrosMezcales />
      <ListaProductos />
      <Footer />
    </>
  );
}

export default Mezcales;
