import React, { useState, useRef, useEffect } from "react";
import "../style/style-modal-contact.css";
import iconClose from "../images/close-negro.svg"
import { AnimatePresence, motion } from "framer-motion";

export const useModalContact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const closeRef = useRef(null);

  const openModal = () => {
    console.log("hola hola hola");
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (closeRef?.current?.contains(event.target)) {
      closeModal();
    }
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const ModalContact = ({ children }) => {
    return (
      <AnimatePresence>
        <motion.div
          className="modal-contact__background"
          initial={{ opacity: 0, display: "none" }}
          animate={isOpen ? { opacity: 1, display: "flex" } : {}}
          exit={isOpen ? {} : { opacity: 0, display: "none" }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div className="modal-contact__container" ref={modalRef}>
            <>
              <div className="modal-contact__close">
                <button
                  ref={closeRef}
                  type="button"
                  className="modal-contact__close--button"
                  onClick={closeModal}
                >
                  <img src={iconClose} alt="close" width="30px" />
                </button>
              </div>
              <div className="modal-contact__content">
                <article>
                  <h2>Hola, estamos para servirte</h2>
                  <span>
                    Si deseas comprar nuestros mezcales, por favor comunícate
                    con nosotros al número: 56 3458 0002 a través de llamada
                    telefónica o por WhatsApp. <br />
                    Te proporcionaremos la cuenta bancaria y la CLABE
                    interbancaria para realizar el depósito. <br />
                  </span>
                  <span>
                    ¡Muchas gracias y disfruta del mezcal Don Hesiquio!
                  </span>
                </article>
                <footer className="modal-contact__buttons">
                  <a href="https://wa.me/5634580002" target="_blank">
                    <button
                      className="btn-primary--base"
                      style={{ backgroundColor: "#02a698" }}
                    >
                      WhatsApp
                    </button>
                  </a>
                  <a href="tel:5634580002">
                    <button
                      className="btn-primary--base"
                      style={{ backgroundColor: "#209edb" }}
                    >
                      Llamar
                    </button>
                  </a>
                </footer>
              </div>
            </>
          </div>
        </motion.div>
        {/* )} */}
      </AnimatePresence>
    );
  };

  return [ModalContact, openModal, closeModal];
};
