import MezcalProductView from "./MezcalProductView";
import tobalaImg from "../../images/mezcales/tobala.png";
import { useState, useEffect } from "react";

function MezcalTobala() {
  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getDatosMezcal";
  const [datosMezcal, setDatosMezcal] = useState();
  const idMezcalTobala = 2;

  const getDatosMezcal = async () => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          idProducto: idMezcalTobala,
        }),
      });
      const data = await response.json();
      setDatosMezcal(data.message[0]);

      console.log(`Response de getDatosMezcal: ${response}`);
    } catch (error) {
      console.log(`Error request getDatosMezcal: ${error}`);
    }
  };

  useEffect(() => {
    getDatosMezcal();
  }, []);
  return (
    <>
      <MezcalProductView
        nombreProducto={datosMezcal?.nombreProducto}
        descripcion={datosMezcal?.descripcion}
        precio={datosMezcal?.precio}
        costoPuntos={datosMezcal?.costoPuntos}
        puntosObtener={datosMezcal?.puntosObtener}
        image={tobalaImg}
        galleryImage={""}
        variante="Mezcal Tobal&aacute; Don Hesiquio Botella de 750 ml"
        tipo="Mezcal artesanal joven"
        agave="100% Agave potatorum (Tobal&aacute;)"
        grados="44% Vol."
        capacidad="750 ml"
        temperatura="18°"
        categoria="Mezcal artesanal"
        clase="Joven"
        coccion="Horno c&oacute;nico de piedra"
        molienda="En molino de piedra"
        fermentacion="Tinas de sabino y piletas de mampostería"
        destilacion="Alambique de cobre"
        noDestilaciones="2"
        region="Mixteca"
        galleryDescription={`Don Hesiquio Tobalá presenta un color cristalino, al ser un agave silvestre
            poco común, nos brinda exquisitas notas ligeras de frutos maduros del
            campo y un aroma delicioso de mieles y ahumados sutiles. Nuestro mezcal
            ofrece una experiencia organoléptica holística que en sabor y aroma
            atrapará tu paladar por su equilibrio y suavidad.`}
      />
    </>
  );
}

export default MezcalTobala;
