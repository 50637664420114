import React, { useEffect } from "react";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import { Link, useParams } from "react-router-dom";
import limon from "../../images/datosPersonales/cocteles-y-maridajes/coctel-limon.png";
import rojo from "../../images/datosPersonales/cocteles-y-maridajes/coctel-rojo.png";
import mandarina from "../../images/datosPersonales/cocteles-y-maridajes/coctel-mandarina.png";
import coctel1a from "../../images/cocteles/coctel_1a.jpg"
import coctel2a from "../../images/cocteles/coctel_2a.jpg"
import coctel2b from "../../images/cocteles/coctel_2b.jpg"
import coctel3a from "../../images/cocteles/coctel_3a.jpg"
import coctel3b from "../../images/cocteles/coctel_3b.jpg"
import { coctel1, coctel2, coctel3, nombreCoctel1, nombreCoctel2, nombreCoctel3 } from "../../assets/constants";

import "./cocteles.css";

function Cocteles() {
  

  const params = useParams();

  useEffect(() => {
    console.log(params)
  }, [params]);

  return (
    <>
      <NavBar />
      <div className="datos-envio container__main">
        <div className="container">
          <div className="row gap-4">
            <h3 className="titulo_dektop">C&oacute;cteles</h3>
            <Link to="/cocteles-y-maridajes">
              <p className="texto-oro fs-5">Volver a C&oacute;cteles y maridajes</p>
            </Link>
            <h3 className="titulo_mobile">C&oacute;cteles</h3>

          </div>
          <div className="row justify-content-center container__main_cocteles">
            <Link to={`/cocteles/${coctel1}`} className="container_imagen_cocteles col-12 col-md-4 col-lg-4 mt-4">
              <img className="img-fluid rounded" src={coctel1a} alt="Coctel sabor tutifruti" />
              <p className="texto-negro">{nombreCoctel1}</p>
            </Link>
            <Link to={`/cocteles/${coctel2}`} className="container_imagen_cocteles col-12 col-md-4 col-lg-4 mt-4">
              <img className="img-fluid rounded" src={coctel2a} alt="Coctel sabor limon" />
              <p className="texto-negro">{nombreCoctel2}</p>
            </Link>
            <Link to={`/cocteles/${coctel3}`} className="container_imagen_cocteles col-12 col-md-4 col-lg-4 mt-4">
              <img className="img-fluid rounded" src={coctel3a} alt="Coctel sabor mandarina" />
              <p className="texto-negro">{nombreCoctel3}</p>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Cocteles;
