import MezcalProductView from "./MezcalProductView";
import image from "../../images/mezcales-products/espadin-product.png";
import galleryImage from "../../images/mezcales-products/espadin-product-gallery.png";
import { mezcalesProductsData } from "../../data/mezcalesProducts";
import PurchaseInfo from "./PurchaseInfo";
import "./mezcal-product-view.css";
import { useState, useEffect } from "react";

function MezcalEspadin() {
  const urlLambda =
    "https://api.mezcaldonhesiquio.com/getDatosMezcal";
  const [datosMezcal, setDatosMezcal] = useState();
  const idMezcalEspadin = 1;

  const getDatosMezcal = async () => {
    try {
      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          idProducto: idMezcalEspadin,
        }),
      });
      const data = await response.json();
      setDatosMezcal(data.message[0]);

      console.log(`Response de getDatosMezcal: ${response}`);
    } catch (error) {
      console.log(`Error request getDatosMezcal: ${error}`);
    }
  };

  useEffect(() => {
    getDatosMezcal();
  }, []);
  return (
    <>
      <MezcalProductView
        nombreProducto={datosMezcal?.nombreProducto}
        descripcion={datosMezcal?.descripcion}
        precio={datosMezcal?.precio}
        costoPuntos={datosMezcal?.costoPuntos}
        puntosObtener={datosMezcal?.puntosObtener}
        image={image}
        galleryImage={galleryImage}
        variante="Mezcal Espad&iacute;n Don Hesiquio Botella de 750 ml"
        tipo="Mezcal artesanal joven"
        agave="100% Agave angustifolia (Espad&iacute;n)"
        grados="44% Vol."
        capacidad="750 ml"
        temperatura="18°"
        categoria="Mezcal artesanal"
        clase="Joven"
        coccion="Horno cónico"
        molienda="En molino de piedra"
        fermentacion="Tinas de sabino y piletas de mampostería"
        destilacion="Alambique de cobre"
        noDestilaciones="2"
        region="Mixteca"
        galleryDescription={`Don Hesiquio Espadín, tiene como principal característica un proceso de destilación tan noble y puro que te ofrecerá una experiencia organoléptica única A TU PALADAR.
            Cristalino y con cuerpo, ofrece notas suaves de aroma a agave cocido y sutil sabor ahumado, cuenta con matices cítricos, ideal para tomarlo derecho o en un cóctel de altura. Su sabor es definido y no lastima ni produce aquella sensación de “quemar” al pasar por tu garganta, otorgándote un retrogusto agradable y persistente.`}
      />
      {/* <div className="pago"></div> */}

      {/* {Object.keys(mezcalProperty).map((key, index) =>
            key == "noDestilaciones" ? (
              <p key={key}>
                <strong>N&uacute;mero de destilaciones:</strong>{" "}
                {mezcalProperty["noDestilaciones"]}
              </p>
            ) : (
              <p key={key}>
                <strong>{key}:</strong> {mezcalProperty[key]}
              </p>
            )
          )} */}
    </>
  );
}

export default MezcalEspadin;
