import React, { useRef, useContext, useState } from "react";
import { formatPrice } from "../assets/functions";
import { useLocation } from "react-router-dom";
import { ReactComponent as CuponIcon } from "../images/form/cuppon@2x.svg";
import { MyContext } from "./context/Context";

export const OrderSummary = ({ items }) => {
  const { pathname } = useLocation();
  const [cupon, setCupon] = useState("");
  const { total, descuentoPuntos, registrado } = useContext(MyContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();
  const email = registrado ? sessionStorage.getItem("emailUser") : "";

  const handleIconClick = () => {
    inputRef.current.focus();
  };

  const handeChangeCupon = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setCupon(value);
      setErrorMessage("");
    }
  };

  const handleSubmitCupon = (e) => {
    e.preventDefault();
    console.log(cupon);
    setErrorMessage("Cupón no válido");
    insertarLogCupon(
      JSON.stringify({ cupon: cupon }),
      JSON.stringify({ statusCode: 200, error: "Cupón no válido" }),
      "Cupón no válido"
    );
  };

  function insertarLogCupon(req, res, descripcion) {
    const URL = "https://api.mezcaldonhesiquio.com/insertarLogs";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        sistema: "PagMezcal",
        proceso: "IngresarCupon",
        tipo: "control",
        xml_in: req,
        xml_out: res,
        descripcion: descripcion,
        usuario: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: index.jsx:89 ~ .then ~ data:", data);
        console.log("Log de carrito insertado con exito");
      }).catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="order-summary">
      <section className="order-summary__container">
        <h5>Resumen de compra</h5>
        <hr />
        <div className="total-productos-container">
          <p className="p-0 m-0">Total de productos: </p>
          {/* <span>{items.espadin.espadinItems + items.tobala.tobalaItems}</span> */}
          <span>{localStorage.getItem("totalItems")}</span>
        </div>
        <article className="order-summary__subtotal">
          <div className="order-summary__subtotal--costs">
            <span>
              <b>Subtotal</b>
            </span>
            <span className="subtotal-summary-price">{formatPrice(items.subTotal.costo)}</span>
          </div>
          <form onSubmit={handleSubmitCupon} className="summary-cupon-container row d-flex align-items-center ">
            <div onClick={handleIconClick} className="col-1">
              <CuponIcon style={{ fill: "red" }} />
            </div>
            <div className="col-10">
              <input
                style={{ cursor: "pointer" }}
                ref={inputRef}
                type="text"
                placeholder="Ingresar cupón"
                className="form-control ingresar-cupon-input "
                onChange={handeChangeCupon}
                maxLength={24}
                autoFocus={focus}
              />
            </div>
            <div className="col-12">{errorMessage && <span className="text-danger col-12">{errorMessage}</span>}</div>
          </form>
          <div className="order-summary__subtotal--costs">
            <span>
              <b>Envío</b>
            </span>
            <span className="order-envio-calculo-espec">Se calcula adelante</span>
          </div>
        </article>
        <hr />
        <article className="order-summary__total">
          <div className="order-summary__subtotal--costs">
            <span>
              <b>Total</b>
            </span>
            <span className="order-summary__total__price">
              {pathname === "/.datos-envio__content" ? formatPrice(total) : formatPrice(total)}
            </span>
          </div>
        </article>
      </section>
    </div>
  );
};
