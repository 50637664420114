import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import img_logo_dorado from "../images/logo-dorado-black.svg";
import carritoIcon from "../images/carrito.svg";
import menuButton from "../images/bars-solid-black.svg";
import closeButton from "../images/close-blanco.svg";
import { useModalContact } from "../hooks/useModalContact";
import { MyContext, MyProvider } from "./context/Context";
import iconPerson from "./imagesNavbar/iconPerson.svg";
import campanaNotificacionAct from "./imagesNavbar/campanaNotificacionAct.svg";
import campanaNotificacionVis from "./imagesNavbar/campanaNotificacionVis.svg";
import homeIcon from "../images/icons/home_.png";
import iconoMixologia from "../images/mixologia.svg";
import puntosIcon from "../images/icons/points.svg";

import NotificacionesRapidas from "./NotificacionesRapidas";

import "./StyleNavBar.css";
import { hover } from "@testing-library/user-event/dist/hover";

export const NavBar = ({ carrito = true, activeModal = false }) => {
  const { itemsCarrito, registrado, logout, puntosActuales } =
    useContext(MyContext);

  const [ModalContact, openModal, closeModal] = useModalContact();
  const [menuMovil, setMenuMovil] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [notificaciones, setNotificaciones] = useState([]);
  const idUsuario = sessionStorage.getItem("idUser");
  const [notificacionesVistas, setNotificacionesVistas] = useState(
    false || sessionStorage.getItem("notificacionesVistas") == "true"
      ? true
      : false
  );
  const [loading, setLoading] = useState(false);

  const [totalNotificaciones, setTotalNotificaciones] = useState();

  const navigate = useNavigate();
  const divRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [notificacionesDropdown, setNotificacionesDropdown] = useState(false);
  const [continuarMobile, setContinuarMobile] = useState(
    window.innerWidth < 992
  );

  const apellidoUsuario =
    sessionStorage.getItem("userLastName") !== null
      ? sessionStorage.getItem("userLastName").split(" ")[0]
      : "";

  const salir = () => {
    logout();
    sessionStorage.clear();
    sessionStorage.setItem("activo", true);
    window.location.href = "/Login";
  };

  const getNotificacionesUsuario = () => {
    const URL =
      "https://api.mezcaldonhesiquio.com/notificacionesUsuario";

    fetch(URL, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        idUsuario: idUsuario,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const noVistas = data.notificaciones.filter((notificacion => notificacion.vista == 0))
        if(noVistas.length > 0) {
          sessionStorage.setItem("notificacionesVistas", false)
          setTotalNotificaciones(noVistas)
        }
        const notificacionesOrdenadas = data.notificaciones.sort((a, b) => {
          return new Date(b.fecha) - new Date(a.fecha);
        });
        console.log(notificacionesOrdenadas);
        
        if (continuarMobile) {
          setNotificaciones(notificacionesOrdenadas.slice(0, 1));
          setTotalNotificaciones(noVistas)
          return;
        }
        setNotificaciones(notificacionesOrdenadas);
        const notificacionesNoLeidas = data.notificaciones.filter(
          (notificacion) => notificacion.vista == 0
        );
        if (notificacionesNoLeidas.length > 0) {
          setNotificacionesVistas(false);
        } else {
          setNotificacionesVistas(true);
          sessionStorage.setItem("notificacionesVistas", true);
        }
      });
  };

  const cleanTotalNotificaciones = () => {
    setTotalNotificaciones({})
  }

  useEffect(() => {
    getNotificacionesUsuario();
    setNombreUsuario(sessionStorage.getItem("userName"));
    if (activeModal) {
      openModal();
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      setContinuarMobile(window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Función para manejar el clic fuera del modal
    const handleClickOutside = (event) => {
      const modal = document.querySelector(".dropdown-menu");
      const modal2 = document.querySelector(".dropdown-menu2");
      if (modal && !modal.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (modal2 && !modal2.contains(event.target)) {
        setNotificacionesDropdown(false);
      }
    };

    // Agrega el evento de clic al documento
    document.addEventListener("mousedown", handleClickOutside);

    // Limpieza: remueve el evento de clic del documento al desmontar
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setMenuMovil(false);
      }
    }

    // Agregar el event listener cuando el componente se monta
    document.addEventListener("mousedown", handleClickOutside);
    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <MyProvider>
        <header className="header--nav">
          <ModalContact />
          <section className="menu-navegacion--container">
            <nav id="menu-navegador" className="menu-navegacion navegacion">
              <div className="menu-navegacion__img-container">
                <Link to="/">
                  <img
                    id="logo-inicio"
                    src={img_logo_dorado}
                    alt="Logo Don Hesiquio"
                  />
                </Link>
              </div>
              <div
                ref={divRef}
                className={`menu-navegacion__links${
                  menuMovil ? " active" : ""
                }`}
              >
                {menuMovil ? (
                  <div
                    className="button-menu--movil--close"
                    onClick={() => setMenuMovil((prev) => !prev)}
                  >
                    <img src={closeButton} alt="icon menu movil" width={38} />
                  </div>
                  
                ) : null}
                {menuMovil ? (
                  <>
                  <NavLink className="nav-link oculto-ipad" to="/">
                  <img
                    src={homeIcon}
                    style={{ width: "1.8rem", height: "1.8rem" }}
                  />
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Mezcales"
                  onClick={() => setMenuMovil((prev) => !prev)}
                >
                  <span>Nuestros mezcales</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Elaboracion"
                  onClick={() => setMenuMovil((prev) => !prev)}
                >
                  <span>Elaboraci&oacute;n</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Eventos"
                  onClick={() => setMenuMovil((prev) => !prev)}
                  state={{
                    tipo: "los mejores eventos",
                  }}
                >
                  <span>Eventos</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/ListaProductos"
                  onClick={() => setMenuMovil((prev) => !prev)}
                >
                  <span>Tienda</span>
                </NavLink>
                <NavLink
                  className="nav-link cocteles-maridajes-link p-0"
                  to="/cocteles-y-maridajes"
                  onClick={() => setMenuMovil((prev) => !prev)}
                  state={{
                    tipo: "los mejores cócteles y maridajes",
                  }}
                >
                  <span
                    className="cocteles-maridajes-link p-2"
                    style={menuMovil ? { color: "white" } : null}
                  >
                    C&oacute;cteles y maridajes
                    {/* <img src={iconoMixologia} style={{ fill: "white"}} /> */}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill=""
                      xmlns="http://www.w3.org/2000/svg"
                      className="cocteles-icon"
                    >
                      <path
                        d="M7.00004 20.5V19.5H11.5V13.0385L4.34619 5V4H19.6539V5L12.5 13.0385V19.5H17V20.5H7.00004ZM7.45004 7H16.55L18.35 5H5.65004L7.45004 7ZM12 12.1L15.6596 8H8.34044L12 12.1Z"
                        fill={menuMovil ? "#ffffff" : "#a78721"}
                      />
                    </svg>
                  </span>
                </NavLink>
                <NavLink
                  to="/sumar-puntos"
                  className="nav-link d-flex"
                  onClick={() => setMenuMovil((prev) => !prev)}
                >
                  <span>Sumar Puntos</span>
                  <img src={puntosIcon} style={{ marginLeft: "0.5rem" }} />
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Contacto"
                  onClick={() => setMenuMovil((prev) => !prev)}
                >
                  <span>Contacto</span>
                </NavLink>
                  <NavLink
                    className="nav-link"
                    to={registrado ? "/datosPersonales/datos" : "/Login"}
                    onClick={() => setMenuMovil((prev) => !prev)}
                  >
                    <span>Amigos de Don Hesiquio</span>
                  </NavLink>
              
                </>
                ) : null}
                {!menuMovil ? (
                  <>
                <NavLink className="nav-link oculto-ipad" to="/">
                  <img
                    src={homeIcon}
                    style={{ width: "1.8rem", height: "1.8rem" }}
                  />
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Mezcales"
                >
                  <span>Nuestros mezcales</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Elaboracion"
                >
                  <span>Elaboraci&oacute;n</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Eventos"
                  state={{
                    tipo: "los mejores eventos",
                  }}
                >
                  <span>Eventos</span>
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/ListaProductos"
                >
                  <span>Tienda</span>
                </NavLink>
                <NavLink
                  className="nav-link cocteles-maridajes-link p-0"
                  to="/cocteles-y-maridajes"
                  state={{
                    tipo: "los mejores cócteles y maridajes",
                  }}
                >
                  <span
                    className="cocteles-maridajes-link p-2"
                    style={menuMovil ? { color: "white" } : null}
                  >
                    C&oacute;cteles y maridajes
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill=""
                      xmlns="http://www.w3.org/2000/svg"
                      className="cocteles-icon"
                    >
                      <path
                        d="M7.00004 20.5V19.5H11.5V13.0385L4.34619 5V4H19.6539V5L12.5 13.0385V19.5H17V20.5H7.00004ZM7.45004 7H16.55L18.35 5H5.65004L7.45004 7ZM12 12.1L15.6596 8H8.34044L12 12.1Z"
                        fill={menuMovil ? "#ffffff" : "#a78721"}
                      />
                    </svg>
                  </span>
                </NavLink>
                <NavLink
                  to="/sumar-puntos"
                  className="nav-link d-flex"
                >
                  <span>Sumar Puntos</span>
                  <img src={puntosIcon} style={{ marginLeft: "0.5rem" }} />
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="/Contacto"
                >
                  <span>Contacto</span>
                </NavLink>
              
                </>
                ) : null}
              </div>
              <div
                className="menu-navegacion__links--carrito "
                style={!carrito ? { transform: "translateX(40px)" } : null}
              >
                {registrado ? (
                  <ul className=" p-0 m-0">
                    <li
                      className="nav-item border-0"
                      onClick={() => {
                        if (continuarMobile) {
                          navigate("/datos-personales");
                          return;
                        }
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                    >
                      <div className="d-flex justify-content-end align-items-center gap-2 nav-menu">
                        {nombreUsuario && !continuarMobile ? (
                          <span className="texto-oro oculto-ipad">
                            {nombreUsuario}
                          </span>
                        ) : null}
                        <span>
                          <img src={iconPerson} alt="" />
                        </span>
                        {isDropdownOpen && (
                          <div className="dropdown-menu container-blanco text-center">
                            <div className="d-flex flex-column ">
                              <div className="p-2">
                                <NavLink
                                  to="/datosPersonales/datos"
                                  className="nav-link d-flex align-items-center gap-2 datos-personales-link"
                                >
                                  <img src={iconPerson} alt="" />

                                  <span>{nombreUsuario}</span>
                                </NavLink>
                              </div>
                            </div>
                            <NavLink
                              to="/datosPersonales/historial-compra"
                              className=""
                            >
                              <h3 className="fs-6 text-start texto-oro dropdown-item ">
                                {puntosActuales} puntos
                              </h3>
                            </NavLink>
                            <hr />
                            <div className="row gap-2">
                              <hr />
                              <NavLink
                                to="/datosPersonales/mis-pedidos"
                                className=""
                              >
                                <span className="dropdown-item">
                                  Mis pedidos
                                </span>
                              </NavLink>
                              <NavLink
                                to="/datosPersonales/historial-compra"
                                className=""
                              >
                                <span className="dropdown-item">
                                  Historial de compra
                                </span>
                              </NavLink>
                            </div>
                            <hr />
                            <button
                              onClick={salir}
                              className="dropdown-item text-start"
                            >
                              Cerrar sesi&oacute;n
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                ) : (
                  <NavLink className="nav-link d-flex gap-1" to="/Login">
                    {continuarMobile ? null : (
                      <span className="icon-movil ">
                        Amigos de Don Hesiquio
                      </span>
                    )}
                    <img src={iconPerson} alt="" />
                  </NavLink>
                )}
                {registrado ? (
                  <ul className="contenedor_notificaciones_nav p-0 m-0">
                    <li
                      className="nav-item2 border-0"
                      onClick={() =>
                        setNotificacionesDropdown(!notificacionesDropdown)
                      }
                    >
                      <div className="d-flex gap-2 nav-menu2">
                        {notificacionesVistas || sessionStorage.getItem("notificacionesVistas") == null ? (
                          <span className="">
                            <img
                              src={campanaNotificacionVis}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        ) : (
                          <span className="">
                            <img
                              src={campanaNotificacionAct}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        )}
                        {notificacionesDropdown && (
                          <div className="dropdown-menu2">
                            <NotificacionesRapidas
                              notificaciones={notificaciones}
                              setNotificacionesVistas={setNotificacionesVistas}
                              totalNotificaciones={totalNotificaciones}
                              cleanTotalNotificaciones={cleanTotalNotificaciones}
                            />
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                ) : null}
                <div
                  className="button-menu--movil "
                  onClick={() => setMenuMovil((prev) => !prev)}
                  style={menuMovil ? { display: "none" } : null}
                >
                  <img src={menuButton} alt="icon menu movil" width={38} />
                </div>
                <div className="menu-navegacion__links--carrito__link ">
                  <button
                    onClick={() => navigate("/Carrito")}
                    style={!carrito ? { opacity: 0 } : null}
                    disabled={!carrito}
                  >
                    <span>
                      {itemsCarrito > 0 && (
                        <span
                          id="items-carrito"
                          className="texto-negro menu-letra"
                        >
                          {itemsCarrito}
                        </span>
                      )}
                      <img
                        src={carritoIcon}
                        id="dibujo-carrito"
                        alt="Cart"
                        width={"30px"}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </nav>
          </section>
        </header>
      </MyProvider>
    </>
  );
};
