import { MyContext } from "../../components/context/Context";
import React, { useState, useEffect, useContext } from "react";

import "../../style/sidebar-usuario.css";
import iconoProfile from "../../images/profile.svg";
import iconoPuntos from "../../images/points.svg";
import iconoMixologia from "../../images/mixologia.svg";
import iconShoppingBag from "../../images/shopping_bag.svg";
import iconHistory from "../../images/history.svg";
import iconAccount from "../../images/account_24.svg";
import { Link } from "react-router-dom";

function SidebarUsuario() {
  const { puntosActuales, logout, registrado } = useContext(MyContext);

  const salir = () => {
    logout();
    sessionStorage.clear();
    sessionStorage.setItem("activo", true);
    window.location.href = "/Login";
  };

  const menuItems = [
    {
      path: "/datos",
      name: "Datos Personales",
      icon: iconoProfile,
    },
    {
      path: "/mis-pedidos",
      name: "Mis pedidos",
      icon: iconShoppingBag,
    },
    {
      path: "/historial-compra",
      name: "Historial de compra",
      icon: iconHistory,
    },
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const handleClickItems = (index) => {
    console.log(index + "selected");
    setSelectedItem(index);
  };

  return (
    <>
    {registrado ? (

      <div className="main-div">
        <div className="account-section">
          <img src={iconAccount} />
          <h5>
            {sessionStorage.getItem("userName")}{" "}
            {sessionStorage.getItem("userLastName")}
          </h5>
        </div>
        <div className="puntos-section">
          <Link to="/datosPersonales/historial-compra">
          <p>{puntosActuales} puntos</p>
          </Link>
        </div>
        <hr />
        {menuItems.map((element, index) => (
          <Link to={"/datosPersonales" + element.path} key={index}>
            <div
              key={index}
              className={`sidebar-items-list ${
                selectedItem == index ? "selected" : ""
              }`}
              onClick={() => handleClickItems(index)}
            >
              <img src={element.icon} />
              <h5>{element.name}</h5>
            </div>
          </Link>
        ))}
        <hr />
        <div className="sidebar-logout" onClick={salir}>
          <h5>Cerrar sesión</h5>
        </div>
      </div>
    ) : null}
    </>
  );
}

export default SidebarUsuario;
