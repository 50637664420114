
import { Footer } from "../components/Footer";

import styled from "styled-components";
import React, { useState } from "react";
//imagenes
import pergamino from "../images/mezcales/pergamino.png";
import img_logo_dorado from "../images/logo-dorado-black.svg";
//Componentes
const Portada = styled.div`
background: url(${pergamino});
  background-size: cover;
  position:relative;

`;


export default function Contacto() {



    return (
        <>

            <Portada className="termino-container">
                <center> <img src={img_logo_dorado} alt="" height="100%" className="logo-termino" /></center>

                <div className="mezcal-terminos ">
                    <div className="container"  >
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 " >
                                <div className="contenedor-texto-termino">
                                    <div className="contenedor-titulo-termino">
                                        <span className="titulo-termino-max">
                                            T&eacute;rminos y condiciones
                                        </span>
                                    </div>
                                    <br /><br />
                                    <p align="left">
                                        <span className="titulo-termino">
                                            1. CONDICI&Oacute;N DE ACEPTACI&Oacute;N DE LOS T&Eacute;RMINOS

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            El uso de este sitio web está condicionado a la aceptaci&oacute;n
                                            de estos t&eacute;rminos y condiciones. Si
                                            el usuario no acepta completamente los t&eacute;rminos y
                                            condiciones deber&aacute; de abstenerse de
                                            entrar; la estancia en el sitio se considerar&aacute; como aceptaci&oacute;n de estos.


                                        </span>
                                        <br /><br />
                                        <span className="titulo-termino">
                                            2. DERECHO DE MODIFICAR EL CONTRATO

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            PRODUCTORA DON HESIQUIO® S, A DE C, V. en adelante referida como “LA EMPRESA” se
                                            reserva el derecho de modificar a discreci&oacute;n los t&eacute;rminos y condiciones y/o el aviso de
                                            privacidad, modificaciones que ser&aacute;n aplicables al momento de su publicaci&oacute;n, por lo que se
                                            recomienda al usuario revisarlos constantemente al entrar al sitio web.


                                        </span>
                                        <br /><br />
                                        <span className="titulo-termino">
                                            3. LICENCIA

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            Por virtud de la celebraci&oacute;n de este convenio, “LA EMPRESA” otorga y concede al Usuario el
                                            derecho no exclusivo, revocable y no transferible de ver y usar www.mezcaldonhesiquio.com
                                            de conformidad con los términos y condiciones que se estipulan en este convenio. Para los
                                            efectos de este, las partes acuerdan que por usuario se entender&aacute; a cualquier persona de
                                            cualquier naturaleza que ingrese a
                                        </span><span className="texto-termino-dorado"> www.mezcaldonhesiquio.com</span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            El usuario solo podr&aacute; imprimir y/o copiar cualquier informaci&oacute;n contenida en el sitio para uso
                                            personal, no comercial. Cualquier uso comercial de la informaci&oacute;n est&aacute; estrictamente
                                            prohibido, a menos que “LA EMPRESA” otorgue su consentimiento previamente y por escrito.

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">

                                            En caso de que “LA EMPRESA” considere que el usuario ha violado los t&eacute;rminos y condiciones,
                                            el acceso al sitio podr&aacute; ser suspendido, terminado o restringido.
                                        </span>
                                        <br /><br />
                                        <span className="titulo-termino">
                                            4. MENORES DE EDAD

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">

                                            Para hacer uso del Portal y de los Servicios, los usuarios deber&aacute;n de contar con la mayor&iacute;a de
                                            edad requerida del pa&iacute;s donde visualizan la p&aacute;gina, en caso contrario deber&aacute; de abstenerse


                                        </span>
                                        <br /><br />
                                        <span className="titulo-termino">
                                            5. RECOLECCIÓN DE INFORMACI&Oacute;N

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            “LA EMPRESA” recolecta y almacena datos como: Datos de identificaci&oacute;n y facturaci&oacute;n como
                                            su nombre completo, datos de contacto y RFC. Aunque no es obligación del usuario brindar los datos correctos, 
                                            su inexactitud o falsedad puede causar da&ntilde;os a sus derechos, los nuestros o de terceros.
                                            </span>
                                            <br /><br />
                                            <span className="texto-termino">
                                            Los datos recolectados ser&aacute;n utilizados con fines de procesamiento de:
                                            </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            *Compras y facturaci&oacute;n;
                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            *Publicidad, ofertas y/o promociones.
                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            “LA EMPRESA” es responsable de resguardar los datos proporcionados y solo ser&aacute;n usados
                                            para los fines mencionados, los datos ser&aacute;n almacenados durante el tiempo necesario para
                                            cumplir con el prop&oacute;sito para el que se han recopilado o para cumplir con requisitos
                                            reglamentarios o legales, una vez transcurrido este lapso ser&aacute;n eliminados.
                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            Cualquier cambio en el manejo de estos ser&aacute; notificado 7 d&iacute;as antes de su modificaci&oacute;n.

                                        </span>
                                        <br /><br />
                                        <span className="titulo-termino">
                                            5.1 ACCESO A TUS DATOS PERSONALES

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                            Tienes derecho a poder acceder, rectificar, cancelar u oponerte al tratamiento que se les da a
                                            tus datos personales. Para el ejercicio de cualquiera de tus derechos ARCO, deber&aacute;s de
                                            presentar tu solicitud al correo
                                        </span><span className="texto-termino-dorado"> contacto@mezcaldonhesiquio.com </span><span className="texto-termino">
                                            donde se te brindar&aacute; el formulario
                                            y el procedimiento correspondiente.
                                        </span>
                                        <br /><br />

                                        <span className="titulo-termino">
                                            6. CONTACTO

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">

                                            Si tienes preguntas o inquietudes relacionadas con estos t&eacute;rminos y condiciones comun&iacute;cate
                                            con nosotros por medio del correo:
                                        </span><span className="texto-termino-dorado"> contacto@mezcaldonhesiquio.com </span><br /><br />
                                        <span className="titulo-termino">
                                            7. PAGO

                                        </span>
                                        <br /><br />
                                        <span className="texto-termino">
                                        Las transacciones serán efectuadas mediante la pasarela de Openpay
                                        </span>


                                    </p>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </Portada>

            <Footer />
        </>);
}
