import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { NavBarAyuda } from "../../components/NavBarAyuda";
import { Link, useParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { MyContext } from "../../components/context/Context";
import { useNavigate } from "react-router-dom";
import ModalCarga from "../components/ModalCarga";
import showPassword from "../../images/showPassword.svg";
import hidePassword from "../../images/hidePassword.svg";

function NewPassword() {
  const { email, token } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { getSession } = React.useContext(MyContext);
  const [viewPassword, setViewPassword] = useState({
    password1: false,
    password2: false,
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    caracteresEspeciales: false,
  });
  const [validationMessage, setValidationMessage] = useState(false);

  const onSubmit = (data) => {
    if (password !== data.password2) {
      console.log("Contraseñas diferentes");
      setError(<>Las contrase&ntilde;as no coinciden</>);
      return;
    }
   
    confirmResetPassword(data);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Establecer los criterios de la contraseña
    setPasswordCriteria({
      length: newPassword.length >= 8 && newPassword.length <= 64,
      lowercase: /[a-z]/.test(newPassword),
      uppercase: /[A-Z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      caracteresEspeciales: /[@#$&?*]/.test(newPassword),
    });
  };

  const handleViewPassword = (id) => {
    if (id === "svg1") {
      setViewPassword({ ...viewPassword, password1: !viewPassword.password1 });
    } else {
      setViewPassword({ ...viewPassword, password2: !viewPassword.password2 });
    }
  };

  const validarTokenPassword = () => {
    setLoading(true);
    const URL = `https://api.mezcaldonhesiquio.com/validarTokenPassword`;
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ email: email, token: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log(data);

        if (data.access == true) {
          console.log("Token válido");
        } else {
          console.log("Token inválido");
          setShow(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        navigate(`/login`);
      });
  };
  const confirmResetPassword = (data) => {
    setLoading(true);
    const URL = `https://api.mezcaldonhesiquio.com/confirmResetPassword`;
    fetch(URL, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ email: email, newPassword: password }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        navigate(`/login`);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    validarTokenPassword();
    console.log("token", token, "email", email);
  }, []);

  return (
    <>
      <NavBarAyuda />
      <div className="contenedor_reset_password datos-envio">
        <div className="container h-100 d-flex align-items-center">
          <div className="row container-blanco col-5 mx-auto p-4">
            <h3 className="text-center">Restablece tu contrase&ntilde;a</h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column gap-4"
            >
              <div className="form-group">
                <label htmlFor="password">Nueva contrase&ntilde;a</label>
                <div className="d-flex align-items-center col-12 contenedor-password ">
                  <input
                    type={viewPassword.password1 ? "text" : "password"}
                    className="form-control custom-input"
                    id="password1"
                    aria-describedby="passwordHelp"
                    onChange={handlePasswordChange}
                    onFocus={() => setValidationMessage(true)}
                    onBlur={() => setValidationMessage(false)}
                  />
                  {viewPassword.password1 ? (
                    <img
                      src={showPassword}
                      style={{ transform: "translateY(2px)" }}
                      className="view-password"
                      onClick={() => handleViewPassword("svg1")}
                    />
                  ) : (
                    <img
                      src={hidePassword}
                      alt=""
                      className="view-password"
                      onClick={() => handleViewPassword("svg1")}
                    />
                  )}
                </div>
                {errors.password1 && (
                  <div className="alert alert-danger mt-2">
                    {errors.password1.message}
                  </div>
                )}
              </div>
              {validationMessage && (
                <div className="row contenedor-texto-validacion">
                  <p className="texto-validacion">
                    Tu contrase&ntilde;a debe contener lo siguiente:
                  </p>
                  <p className="texto-validacion">
                    Entre 8 y 64 caracteres{" "}
                    {passwordCriteria.length ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo una minúscula{" "}
                    {passwordCriteria.lowercase ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo una mayúscula{" "}
                    {passwordCriteria.uppercase ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo 1 número {passwordCriteria.number ? "✅" : "❌"}
                  </p>
                  <p className="texto-validacion">
                    Mínimo un caracter especial (@, #, $, &, ?, *){" "}
                    {passwordCriteria.caracteresEspeciales ? "✅" : "❌"}
                  </p>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="password">Confirma tu contrase&ntilde;a</label>
                <div className="d-flex align-items-center col-12 contenedor-password">
                  <input
                    type={viewPassword.password2 ? "text" : "password"}
                    className="form-control custom-input"
                    id="password2"
                    aria-describedby="passwordHelp"
                    {...register("password2", { required: "Campo requerido" })}
                  />
                  {viewPassword.password2 ? (
                    <img
                      src={showPassword}
                      style={{ transform: "translateY(2px)" }}
                      className="view-password"
                      onClick={() => handleViewPassword("svg2")}
                    />
                  ) : (
                    <img
                      src={hidePassword}
                      alt=""
                      className="view-password"
                      onClick={() => handleViewPassword("svg2")}
                    />
                  )}
                </div>
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="row">
                <button
                  type="submit"
                  className="col-6 mx-auto boton-enviar-resetpassword px-4 py-3"
                >
                  Continuar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalCarga open={loading} />
      <Modal show={show} centered>
        <Modal.Header className="text-center">
          <Modal.Title
            className="alert alert-danger fw-bold mt-2 w-100"
            role="alert"
          >
            Enlace Caducado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          El enlace para cambiar la contraseña ha expirado o ya se ha utilizado.
          Por favor, solicita un nuevo enlace si es necesario.
          <Link
            to="/reset-password"
            className="boton-enviar-resetpassword w-50 mx-auto mt-4 text-center"
          >
            Solicitar nuevo enlace
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NewPassword;
