import img1 from "../images/contacto/01.png"
import img2 from "../images/contacto/02.png"
import img3 from "../images/contacto/03.png"
import img4 from "../images/contacto/04.png"
import img5 from "../images/contacto/05.png"
import img6 from "../images/contacto/06.png"

export const elaboracionData = [
    {
        icon: img1,
        title: 'Jima',
        description: `La jima es el proceso de cosecha de las piñas de agave. Una vez madura, a la planta de agave se le quitan las pencas y se corta la raíz para extraerla del suelo, obteniendo piñas de hasta 90 kilogramos cada una, que contiene los nutrientes y azúcares.`
    },
    {
        icon: img2,
        title: 'Cocción',
        description: `Las piñas se colocan en un horno natural, excavando en el suelo en forma circular y recubierto de piedra. Para cocinar las piñas, se utilizan rocas calentadas hasta el rojo vivo.
        
        Después de cuatro días, las piñas están cocidas.`
    },
    {
        icon: img3,
        title: 'Molienda',
        description: `Las piñas cocinadas se colocan en un Tahona, un molino de piedra circular de tracción animal, donde se pisan y machucan para extraer el jugo de las piñas.`
    },
    {
        icon: img4,
        title: 'Fermentación',
        description: `El jugo obtenido, se coloca en tinas de Sabino y piletas de mampostería, donde se fermenta de forma natural para convertir los azúcares en alcohol.`
    },
    {
        icon: img5,
        title: 'Destilación',
        description: `En la destilación, se separa el líquido fermentado de materiales sólidos mediante diferentes puntos de ebullición. 
        
        Para obtener un mezcal de alta calidad se realiza una doble destilación en alambiques de cobre.`
    },
    {
        icon: img6,
        title: 'Envasado',
        description: `Nuestro mezcal se envasa en botellas de vidrio para no modificar su sabor. El tapón que sella nuestro mezcal es elegido con cuidado para no alterar el contenido y así, poder disfrutar de él sin alteraciones.`
    },
];