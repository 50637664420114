// css
import "../style/contacto.css";
import React, { useState, useContext } from "react";

//Componentes
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { MyContext } from "../components/context/Context";

export default function Contacto() {
  const [errores, setErrores] = useState({});
  const {registrado} = useContext(MyContext);
  const email = registrado ? sessionStorage.getItem("emailUser") : ""; 

  function validarFormulario() {
    const erroresGenerados = {};

    if (document.getElementById("nombre").value == "") {
      erroresGenerados.nombre = "Ingresa tu nombre.";
    }

    if (document.getElementById("correo").value == "") {
      erroresGenerados.correo = "Ingresa un correo electrónico.";
    }

    if (document.getElementById("mensaje").value == "") {
      erroresGenerados.mensaje = "Escribe un mensaje.";
    }

    if (document.getElementById("asunto").value == "") {
      erroresGenerados.asunto = "Ingresa un asunto";
    }

    setErrores(erroresGenerados);
    console.log(erroresGenerados);
    if (Object.keys(erroresGenerados).length == 0) {
      return true;
    }
  }

  function insertarLogContacto(req, res, descripcion) {
    const URL = "https://api.mezcaldonhesiquio.com/insertarLogs";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        sistema: "PagMezcal",
        proceso: "Contacto",
        tipo: "control",
        xml_in: req,
        xml_out: res,
        descripcion: descripcion,
        usuario: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: index.jsx:89 ~ .then ~ data:", data);
        console.log("Log de carrito insertado con exito");
      });
  }

  function enviarCorreo(e) {
    e.preventDefault();

    let mensaje = document.getElementById("mensaje").value;
    let correo = document.getElementById("correo").value;
    let nombre = document.getElementById("nombre").value;
    console.log(mensaje, correo, nombre)

    const url =
      "https://api.mezcaldonhesiquio.com/sendEmailForm";
    if (!validarFormulario()) {
      console.log("campos vacios, no se puede enviar");
    } else {
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          nombre: nombre,
          email: correo,
          mensaje: mensaje,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.sendMail === true) {
            document.getElementById("mensaje").value = "";
            document.getElementById("correo").value = "";
            document.getElementById("nombre").value = "";
          } else {
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }

  return (
    <>
      <NavBar />
      <div className="contacto-main-container">
        <div className="contacto-title-container">
          <h3>¿Podemos ayudarte?</h3>
          <p>Déjanos tus datos de contacto y te llamaremos</p>
        </div>
        <div className="form-container-contacto">
          <form className="form-context">
            <label>Nombre completo</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              className={errores.nombre ? "input-warning" : null}
            />
            {errores.nombre && <p className="input-error">{errores.nombre}</p>}

            <label>Correo eléctronico</label>
            <input
              type="text"
              id="correo"
              name="correo"
              className={errores.correo ? "input-warning" : null}
            />
            {errores.correo && <p className="input-error">{errores.correo}</p>}

            <label>Asunto</label>
            <input
              type="text"
              id="asunto"
              name="asunto"
              className={errores.asunto ? "input-warning" : null}
            />
            {errores.asunto && <p className="input-error">{errores.asunto}</p>}

            <label>Mensaje</label>
            <textarea
              id="mensaje"
              name="mensaje"
              className={errores.mensaje ? "input-textarea-warning" : null}
            />
            {errores.mensaje && (
              <p className="input-error">{errores.mensaje}</p>
            )}

            <button onClick={enviarCorreo}>Enviar</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
