import { Footer } from "../components/Footer";
import React, { useState, useEffect, useContext } from "react";
import exito from "../images/compra/exito.png";
import inicio from "../images/compra/inicio.png";
import error from "../images/compra/error.png"
import closeNegro from "../images/close-negro.svg";
import { Link } from "react-router-dom";
import img_logo_dorado from "../images/logo-dorado-black.svg";
import { Ring } from "@uiball/loaders";
import { getMensajeError } from "../assets/functions";
import { Modal, ModalBody } from "reactstrap";
import { NavBar } from "../components/NavBar";
import { MyContext } from "../../src/components/context/Context.js";
import { useNavigate } from "react-router-dom";

export default function FinalizaCompra() {
  const navigate = useNavigate();

  const { productFree, setUserRegistroPuntos } = useContext(MyContext);
  const param = new URLSearchParams(window.location.search).get('id');
  const [loading, setLoading] = useState(false);
  const [numOrden, setNumOrden] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [resultImage,setResultImage] =useState(exito);
  const [mensajePost,setMensajePost] =useState("Se ha enviado un correo con los detalles de compra");
  const [pagoValido,setPagoValido] =useState(false);
  const [mensajeProceso,setMensateProceso]=useState('');
  const [modalMensaje, setModalMensaje] = useState(false);
  const [mensajeAlerta, setMensajeAlerta] = useState(false);
  const [puntosPorCompra, setPuntosPorCommpra] = useState()
  const toggleMensaje = () => setModalMensaje(!modalMensaje);


  const handleClick = () => {
    console.log('Se hizo clic en el enlace');
    localStorage.remove("skipBankOperation"); 
    localStorage.remove("idOrden");
    localStorage.remove("descripcion");
    localStorage.remove("objSummary")

  };

  const handleRegistrarUser = () => {
    setUserRegistroPuntos(true);
    navigate("/signup")
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const objSummary = JSON.parse(localStorage.getItem("objSummary"));

    if (localStorage.getItem("skipBankOperation") === "true") {
      setLoading(false);  
      setResultImage(exito)
      setPagoValido(true); 
      setMensateProceso("Compra Por Puntos");
      setNumOrden(localStorage.getItem ("idOrden"));
      setDescripcion(localStorage.getItem ("descripcion"));
      return;
    }

    var url ="https://api.mezcaldonhesiquio.com/getEstadoCargo";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
       idTransaccion: param,
       montoProductos:objSummary.montoProductos,
       montoEnvio:objSummary.montoEnvio,
       montoDescuento:objSummary.montoDescuento,
       montoFinal:objSummary.montoFinal,
       aplicaPuntos:objSummary.aplicaPuntos,
       puntosAcomulados:objSummary.puntosAcomulados,
       puntosPorCompra:objSummary.puntosPorCompra,
       puntosGastados:objSummary.puntosGastados,
       puntosTotales:objSummary.puntosTotales
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.paymentComplete && data.sentEmail ) {
         setLoading(false);  
         setResultImage(exito)
         setPagoValido(true); 
         setMensateProceso(data.message)
         setNumOrden(data.idOrden)
         setDescripcion(data.descripcion)
         setPuntosPorCommpra(data.puntosPorCompra)
        }
        if (data.paymentComplete && !data.sentEmail ) {
          setLoading(false);
          setResultImage(exito)  
          setPagoValido(true);  
          setMensateProceso(data.message)
          setNumOrden(data.idOrden)
          setDescripcion(data.descripcion)
          setMensajePost("Nos comunicaremos contigo a la brevedad")
         }

        if (!data.paymentComplete && data.sentEmail ) {
          setLoading(false);  
          setResultImage(error) 
          setMensateProceso(data.message)
          setNumOrden(data.idOrden)
          setDescripcion(data.descripcion)
         }
         if (!data.paymentComplete && !data.sentEmail ) {
          setLoading(false);   
          setResultImage(error)
          setNumOrden(data.idOrden)
          setDescripcion(data.descripcion)
          setMensateProceso(data.message)
          setMensajePost("Nos comunicaremos contigo a la brevedad")
         }
         if (!data.paymentComplete && !data.sentEmail && data.openPayFail) {
          setLoading(false);
          setResultImage(error);
          setMensateProceso(getMensajeError(data.message))
          setMensajePost("Nos comunicaremos contigo a la brevedad")
         }
      })
      .catch((err) => {
        setLoading(false);
        setResultImage(error);
        setMensajeAlerta(
          "Error técnico, favor de comunicarse con soporte técnico al teléfono: 5565747493 "
        );
        toggleMensaje();
        setMensajePost("Nos comunicaremos contigo a la brevedad")
      });
  }, []);

  return (
    <>
    <NavBar/>
    <div>
      {loading ? (
        <div className="pago__loader--container">
          <h3>
          <br />
            Validando Transacción Bancaria.
            <br />
             Por favor, espere.
          </h3>
          <Ring size={80} lineWeight={5} speed={2} color="#a78721" />
        </div>
      ) : null}
      <div className="fcompra_desktop">
        <div className="container-mensaje-finaliza-compra">
          <div className="">
            <div className="forma-finaliza-compra">
              <img src={resultImage} alt="Descripción de la imagen" width="250px" />
              {pagoValido?(
              <>
              <h1 className="titulo-contacto texto-contacto">¡ Felicidades !</h1><h3 className="titulo-contacto texto-contacto">{mensajeProceso}</h3>
              {sessionStorage.getItem("idUser") !== null ? null : (
                <>
                {puntosPorCompra !== 0 ? (
                <>
                  <h3>Esta compra te pudo haber generado {puntosPorCompra} puntos. <br /> ¡Registrate y reclamalos!</h3>
                  <button 
                  onClick={handleRegistrarUser}
                  style={{ 
                    backgroundColor: "#a78822",
                    height: "3rem",
                    width: "12rem",
                    color: "#ffffff",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}>Obtener puntos </button>
                </>) : null}
                </>
              )}
              </>
              ):(<><h1 className="titulo-contacto texto-contacto">{mensajeProceso}</h1></>)}
              <br></br>
              <div className="mb-3">
                <label className=" texto-negro menu-letra">
                  Descripci&oacute;n de la compra: {descripcion}
                </label>
              </div>

              <div className="mb-3">
                <label className=" texto-negro menu-letra">
                  N&uacute;mero de Pedido : {numOrden}
                </label>
              </div>
              <div className="mb-3">
                <label className=" texto-negro menu-letra">
                  {mensajePost}
                </label>
              </div>
              <Link to="/"  onClick={handleClick}>
                <img
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  className="img-flecha"
                  src={inicio}
                  alt=""
                  height="40"
                  width="170"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </div>
      <Modal
        isOpen={modalMensaje}
        toggle={toggleMensaje}
        modalTransition={{ timeout: 2000 }}
      >
        <ModalBody>
          <p align="right">
            <a href="#" onClick={toggleMensaje}>
              {" "}
              <img src={closeNegro} width="6%" height="6%" />
            </a>
          </p>
          <center>
            <div style={{ width: "80%" }}>
              <span
                style={{ align: "center" }}
                className="titulo-modal-compra "
              >
                {mensajeAlerta}
              </span>
            </div>
            <br />
            <br />
            <button
              className="btn-rectangulo-principal btn bg-dorado contactano  texto-blanco-proximo"
              type="button"
              style={{ height: "45px" }}
              onClick={toggleMensaje}
            >
              Aceptar
            </button>
          </center>
        </ModalBody>
      </Modal>
    </>
    
  );
}
