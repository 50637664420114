import ReactPlayer from "react-player";
import "../../style/elaboracion.css";
import { useState } from "react";


export default function VideoPlayer(props) {

    
const [playing, setPlaying] = useState(false);
const [mouseOverPlayer, setMouseOverPlayer] = useState(false);

  function handlePlaying() {
    setPlaying((prev) => !prev);
    // console.log(playing);
  }

  function handleMouseMovement() {
    setMouseOverPlayer((prev) => !prev);
    // console.log(mouseOverPlayer)
  }

  function handleMouseLeave() {
    setMouseOverPlayer((prev) => !prev);
    // console.log(mouseOverPlayer)
  }

  return (
    <>
      <div
        className="media-player"
        onMouseEnter={handleMouseMovement}
        onMouseLeave={handleMouseLeave}
      >
        <ReactPlayer
          url={props.videoUrl}
          controls
          width={props.width}
          height={props.height}
          playing={playing}
        />
        {/* <div className={!mouseOverPlayer ? "back-media-hide" : "back-media"}> */}
        <div
          className={!mouseOverPlayer ? "play-icon-hide" : "play-icon"}
          onClick={handlePlaying}
          style={{
            width: "10rem",
            backgroundColor: "#fff",
            opacity: "60%",
            borderRadius: "50%",
            padding: "2rem",
            cursor: "pointer",
          }}
        >
          {!playing ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-7 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                clip-rule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                clip-rule="evenodd"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
}
