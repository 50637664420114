import "../style/edicion-forms.css";
import { useState, useContext, useEffect } from "react";
import { MyContext } from "../components/context/Context";
import { NavBar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../images/icons/eye_pass.svg";
import eyeIconShow from "../images/icons/eye_show.svg";

function EdicionPassword() {
  // estados para asignar valores de inputs
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    caracteresEspeciales: false,
  });
  const [validationMessage, setValidationMessage] = useState(false);

  // estados para setear errores
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState("");
  const [passError, setPassError] = useState("");
  const [matchError, setMatchError] = useState("");
  const [passErrorBool, setPassErrorBool] = useState(false);
  const { getSession, onLogin } = useContext(MyContext);
  const navigate = useNavigate();

  // estados para mostrar password
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(currentPassword + " / " + newPassword);

    let errorMessage = [];
    let notMatch = [];

    if (!/[!@#$%&*()?]/.test(newPassword)) {
      errorMessage.push(
        <>Debe de tener al menos un car&aacute;cter especial</>
      );
    }
    if (newPassword.length < 8) {
      errorMessage.push(<>Debe de tener al menos 8 caracteres</>);
    }
    if (!/[A-Z]/.test(newPassword)) {
      errorMessage.push(<>Debe de tener al menos una may&uacute;scula</>);
    }
    if (newPassword !== confirmPassword) {
      notMatch.push(<>Las contrase&ntilde;as no coinciden </>);
    }

    setErrors(
      errorMessage.map((error) => <p style={{ marginBottom: "0" }}>{error}</p>)
    );
    setMatchError(notMatch.map((error) => <p className="error">{error}</p>));

    if (newPassword === confirmPassword && errorMessage.length === 0) {
      setError(false);
      getSession().then(({ user, email }) => {
        onLogin(email, currentPassword)
          .then(() => {
            user.changePassword(
              currentPassword,
              newPassword,
              (error, results) => {
                if (error) {
                  console.error(error);
                } else {
                  console.log(results);
                  navigate("/datosPersonales/datos");
                  setPassErrorBool(false);
                }
              }
            );
          })
          .catch((error) => {
            console.log("eiofheoifeoij", error);
            setPassError(<>La contrase&ntilde;a introducida no es correcta</>);
            setPassErrorBool(true);
          });
      });
    } else {
      console.log("Contraseñas no coinciden");
      setError(true);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    // Establecer los criterios de la contraseña
    setPasswordCriteria({
      length: newPassword.length >= 8 && newPassword.length <= 64,
      lowercase: /[a-z]/.test(newPassword),
      uppercase: /[A-Z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      caracteresEspeciales: /[@#$&?*]/.test(newPassword),
    });
  };

  const handleShowPassword = (icon) => {
    if (icon === 1) {
      setShowPass1((e) => !e);
    } else if (icon === 2) {
      setShowPass2((e) => !e);
    } else {
      setShowPass3((e) => !e);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="form-container">
          <h6 style={{ fontSize: "1.8rem" }}>Contrase&ntilde;a</h6>
          <p>Mant&eacute;n tu cuenta segura</p>
          <form className="form-content" onSubmit={handleSubmit}>
            <label className="pass-labels">Contrase&ntilde;a anterior</label>
            <div className="password-inputs">
              <input
                type={showPass1 ? "text" : "password"}
                value={currentPassword}
                onChange={(event) => setCurrentPassword(event.target.value)}
              ></input>
              <img
                className="eye-icon"
                src={showPass1 ? eyeIcon : eyeIconShow}
                onClick={() => handleShowPassword(1)}
              />
            </div>
            <label className="pass-labels">Nueva contrase&ntilde;a</label>
            <div className="password-inputs">
              <input
                type={showPass2 ? "text" : "password"}
                value={newPassword}
                onChange={handlePasswordChange}
                onFocus={() => setValidationMessage(true)}
                onBlur={() => setValidationMessage(false)}
              ></input>
              <img
                className="eye-icon"
                src={showPass2 ? eyeIcon : eyeIconShow}
                onClick={() => handleShowPassword(2)}
              />
            </div>
            {validationMessage && (
              <div className="contenedor-texto-validacion my-2">
                <p className="texto-validacion">
                  Tu contrase&ntilde;a debe contener lo siguiente:
                </p>
                <p className="texto-validacion">
                  Entre 8 y 64 caracteres{" "}
                  {passwordCriteria.length ? "✅" : "❌"}
                </p>
                <p className="texto-validacion">
                  Mínimo una minúscula{" "}
                  {passwordCriteria.lowercase ? "✅" : "❌"}
                </p>
                <p className="texto-validacion">
                  Mínimo una mayúscula{" "}
                  {passwordCriteria.uppercase ? "✅" : "❌"}
                </p>
                <p className="texto-validacion">
                  Mínimo 1 número {passwordCriteria.number ? "✅" : "❌"}
                </p>
                <p className="texto-validacion">
                  Mínimo un caracter especial (@, #, $, &, ?, *){" "}
                  {passwordCriteria.caracteresEspeciales ? "✅" : "❌"}
                </p>
              </div>
            )}
            <label className="pass-labels">Confirma tu contrase&ntilde;a</label>
            <div className="password-inputs">
              <input
                type={showPass3 ? "text" : "password"}
                onChange={(event) => setConfirmPassword(event.target.value)}
              ></input>
              <img
                className="eye-icon"
                src={showPass3 ? eyeIcon : eyeIconShow}
                onClick={() => handleShowPassword(3)}
              />
            </div>
            {passErrorBool ? (
              <p className="error" style={{ fontSize: "1.1rem" }}>
                {passError}
              </p>
            ) : null}
            {/* {error ? (
              <p className="error">Las contraseñas no coinciden</p>
            ) : null} */}
            {matchError}
            <button className="form-button" type="submit">
              Guardar
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EdicionPassword;
