// css
import "./maridajes.css";

import React from "react";

// components
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import UnderConstruction from "../components/UnderConstruction/UnderConstruction";

function Maridajes() {
  return (
    <>
      <NavBar />
      <UnderConstruction />
      <Footer />
    </>
  );
}

export default Maridajes;
