import React, { useState, useEffect, useRef, useContext } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Footer } from "../../components/Footer";
import { NavBar } from "../../components/NavBar";
import { MyContext } from "../../components/context/Context";
import UserPool from "../../userPool";
import { Link, useNavigate } from "react-router-dom";
import { getDatosUsuario } from "../../components/context/Api";
import ModalCarga from "../components/ModalCarga";
import showPassword from "../../images/showPassword.svg";
import hidePassword from "../../images/hidePassword.svg";

import "./style.css";
import { set } from "react-hook-form";

function Login(props) {
  const { onLogin, setUserRegistroPuntos, userRegistroPuntos } = useContext(MyContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const emailInputRef = useRef(null);

  const handleResetPassword = () => {
    console.log("Reset password");
    navigate("/reset-password");
  };

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };
  function insertarLogLogin(req, res, descripcion) {
    const URL = "https://api.mezcaldonhesiquio.com/insertarLogs";
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({
        sistema: "PagMezcal",
        proceso: "IniciarSesion",
        tipo: "control",
        xml_in: req,
        xml_out: res,
        descripcion: descripcion,
        usuario: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("🚀 ~ file: index.jsx:89 ~ .then ~ data:", data);
        console.log("Log de carrito insertado con exito");
      });
  }
  
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    console.log("Intentando iniciar sesión con:", email, password);
    onLogin(email, password)
      .then((data) => {
        console.log(data);
        sessionStorage.setItem("loggedIn", "true");
        sessionStorage.setItem("emailUser", email);
        insertarLogLogin(JSON.stringify({ email, password }), JSON.stringify(data), "Login exitoso");
        getDatosUsuario(email)
          .then((data) => {
            console.log(data);
            setTimeout(() => {
              setLoading(false);
              if(props.sumarPuntos || userRegistroPuntos) {
                window.location.href = "/sumar-puntos"
                setUserRegistroPuntos(false)
              } else {
                window.location.href = "/"
              }
            }, 500);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError("error al obtener datos de usuario");
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError("Correo electrónico o contraseña incorrectos.");
        insertarLogLogin(JSON.stringify({ email, password }), JSON.stringify(error), "Error al iniciar sesión");
      });
  };

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  return (
    <>
      <NavBar />
      <div className="login-main-container p-4 mb-4">
        <div className="login-title-container">
          <h3>Identif&iacute;cate, amante del mezcal</h3>
        </div>
        <div className="login-form-container">
          <form className="login-form-context" onSubmit={handleLoginSubmit}>
            {error && (
              <div className="alert alert-danger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-exclamation-circle alert-icon"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0" />
                </svg>
                <span className="alert-text">{error}</span>
              </div>
            )}
            <label>Correo electr&oacute;nico</label>
            <input
              ref={emailInputRef}
              type="email"
              className="form-control"
              id="emailInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Contrase&ntilde;a</label>
            <div className="d-flex align-items-center col-12 contenedor-password">
              <input
                type={viewPassword ? "text" : "password"}
                className="form-control"
                id="passwordInput"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {viewPassword ? (
                <img
                  src={showPassword}
                  style={{ transform: "translateY(2px)" }}
                  className="view-password"
                  onClick={handleViewPassword}
                />
              ) : (
                <img src={hidePassword} alt="" className="view-password" onClick={handleViewPassword} />
              )}
            </div>
            <button type="submit">Ingresar</button>
            <label onClick={handleResetPassword} className="btn-link">
              Olvid&eacute; mi contrase&ntilde;a
            </label>
          </form>
        </div>
        <div className="create-container">
          <Link to="/signup" className="btn-link">
            <button>Crear cuenta</button>
          </Link>
        </div>
      </div>
      <ModalCarga open={loading} />
      <Footer />
    </>
  );
}

export default Login;
