import React from "react";
import { NavBar } from "../../../../components/NavBar";
import { Footer } from "../../../../components/Footer";
import { Link } from "react-router-dom";

import reproductor from "../../../../images/datosPersonales/cocteles-y-maridajes/b27d13565fbf48ffc4d24253a582846a.png";
import bannerMezcal from "../../../../images/cocteles/coctel_3b.jpg";
import { nombreCoctel3 } from "../../../../assets/constants";
import videoCoctel3 from "../../../../images/cocteles/fresco-mezcal.mp4";
import VideoPlayer from "../../../components/VideoPlayer";

function Coctel3() {
  return (
    <>
      <NavBar />
      <div className="datos-envio">
        <div className="container">
          <div className="row">
            <Link to="/cocteles-y-maridajes">
              <p className="fs-5 texto-oro">
                Volver a C&oacute;cteles y maridajes
              </p>
            </Link>
          </div>

          <div className="row container__imagen_coctel">
            <img src={bannerMezcal} alt="" />
            <p className="texto-negro">{nombreCoctel3}</p>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">INGREDIENTES</h3>
              <ul className="text-start list-container-group">
                <li className="list-item-cocteles">
                  2 oz de infusión de pepino y perejil
                </li>
                <li className="list-item-cocteles">½ oz de jugo de limón</li>
                <li className="list-item-cocteles">1 oz jarabe de agave</li>
                <li className="list-item-cocteles">
                  2 gotas de serrano bitters
                </li>
                <li className="list-item-cocteles">
                  1 ½ oz de MEZCAL ESPADÍN DON HESIQUIO
                </li>
                <li className="list-item-cocteles">
                  Chile en polvo para escarchar
                </li>
                <li className="list-item-cocteles">
                  Lamina de pepino para decorar
                </li>
                <li className="list-item-cocteles">Hielo</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h3 className="px-4">PREPARACI&Oacute;N</h3>
              <ul
                className="list-group list-group-numbered"
                style={{ textWrap: "balance" }}
              >
                <li className="p-0 ms-4 text-start">
                  En un shaker, coloque hielo, infusión de pepino y perejil,
                  jugo de limón, jarabe de agave, gotas de serrano y MEZCAL
                  ESPADÍN DON HESIQUIO
                </li>
                <li className="p-0 ms-4 text-start">
                  Cierre y agite vigorosamente
                </li>
                <li className="p-0 ms-4 text-start">
                  Vierta en un vaso highball con medio lado escarchado con chile
                  en polvo y decore con una lámina de pepino
                </li>
              </ul>
            </div>

            <div style={{ paddingTop: "2rem" }}>
              <h3 className="px-4">INFUSION PEPINO Y PEREJIL (500 ML)</h3>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h4 className="px-4">INGREDIENTES</h4>
              <ul className="text-start list-container-group">
                <li className="list-item-cocteles">
                  300 g de pepino con cascara
                </li>
                <li className="list-item-cocteles">50 g de perejil</li>
                <li className="list-item-cocteles">300 ml de agua</li>
              </ul>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <h4 className="px-4">PREPARACI&Oacute;N</h4>
              <ul
                className="list-group list-group-numbered"
                style={{ textWrap: "balance" }}
              >
                <li className="p-0 ms-4 text-start">
                  Licue los 300 g de pepino con cascara y 50 g de perejil en 300
                  ml de agua hasta tener una preparación homogénea y sin grumos
                </li>
                <li className="p-0 ms-4 text-start">
                  Cuele y vierta en un recipiente con tapa. Refrigere y deje
                  reposar por 24 h. Consérvelo en refrigeración hasta por 1
                  semana
                </li>
              </ul>
            </div>
          </div>
          <div className="container_video_coctel">
            <div className="row justify-content-center mt-4">
              <div className="col-10">
                <VideoPlayer
                  videoUrl="https://mezcal-don-hesiquio-recursos.s3.us-west-2.amazonaws.com/DonHesiquio2.0/videos/Fresco+Mezcal+3.0.mp4"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Coctel3;
