import React from 'react'
import { useContext } from 'react';
import { MyContext } from '../../../components/context/Context';

// css
import "../../../style/sumar-puntos.css";

// hooks
import { Link } from "react-router-dom";

// imagenes
import logoDonHesiquio from "../../../images/logo-dorado-black.svg";
import logoEuropea from "../../../images/europea.svg";
import logoAmazon from "../../../images/amazon.svg";

export default function SumarPuntosComponent() {
  const { registrado } = useContext(MyContext)
  return (
    <>
    <div className="sumar-puntos-main-container w-100">
        <div className="container text-center pt-5">
          <h1 className="suma-puntos-title">Suma Puntos</h1>
          <p>¿D&oacute;nde hiciste tu compra?</p>
        </div>
        <div className="container d-flex justify-content-center flex-wrap">
          <Link to="/registrar-puntos" state={{ tipo: "donHesiquio", registrado: registrado }}>
            <div className="origen-container">
              <img src={logoDonHesiquio} />
            </div>
          </Link>
          <Link to="/registrar-puntos" state={{ tipo: "europea", registrado: registrado }}>
            <div className="origen-container">
              <img src={logoEuropea} />
            </div>
          </Link>
          {/* <Link to="/registrar-puntos" state={{ tipo: "amazon" }}>
            <div className="origen-container">
              <img src={logoAmazon} />
            </div>
          </Link> */}
        </div>
      </div>
    </>
  )
}
