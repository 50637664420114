import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./StyleNavBar.css";
import estrellaPuntos from "./imagesNavbar/estrellaPuntos.svg";
import TobalaImage from "./imagesNavbar/imagen (3).png";
import EspadinImage from "./imagesNavbar/imagen (4).png";
import calaverita from "../images/tienda/calaverita.png";
import gusanos from "../images/tienda/gusanos.png";
import jicara from "../images/tienda/jicara.png";
import chapulines from "../images/carrito.svg";

// importaciones de nombres de productos generales 
import 
{ 
  nombreProductoInventario1, 
  nombreProductoInventario2, 
  nombreProductoInventario3, 
  nombreProductoInventario4 
} 
from "../assets/constants";
// importacion de objeto de imagenes de productos generales
import { imagenesProductosInventario } from "../assets/constants";
// importacion de icono de bolsa para notificaciones
import bagIcon from "../images/shopping_bag.svg"

import { MyContext } from "./context/Context";

function NotificacionesRapidas({ notificaciones, setNotificacionesVistas, totalNotificaciones, cleanTotalNotificaciones }) {
  const idUsuario = sessionStorage.getItem("idUser");
  const { puntosActuales } = useContext(MyContext);
  const [notificacionesRecientes, setNotificacionesRecientes] = useState([]);
  const navigate = useNavigate();

  const formatearFecha = (fecha) => {
    let fechaFormateada = new Date(fecha);
    let dia = fechaFormateada.getDate();
    let mes = fechaFormateada.getMonth() + 1;
    let año = fechaFormateada.getFullYear();
    return `${dia}/${mes}/${año}`;
  };

  const handleClick = () => {
    navigate("/datosPersonales/historial-compra");
  };

  const marcarNotificacionesResiduales = async (arrayNotificaciones) => {
    try {
      const urlLambda = "https://api.mezcaldonhesiquio.com/notificacionesLeidas";

      const response = await fetch(urlLambda, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          idUsuario: idUsuario, 
          idCompras: arrayNotificaciones,
        })
      });
      const data = await response.json();
      if(data.statusCode == 200) {
        sessionStorage.setItem("notificacionesVistas", true)
        setNotificacionesVistas(true);
        cleanTotalNotificaciones()
      }
      console.log(data)
    } catch(error) {
      console.error(`Error en marcarNotificacionesResiduales(), ${error}`)
    }
  }

  useEffect(() => {
    const notificacionesNoLeidas = notificaciones.filter((notificacion) => notificacion.vista == 0);
    console.log(notificacionesNoLeidas);
    if (notificacionesNoLeidas.length >= 3) {
      setNotificacionesRecientes(notificacionesNoLeidas);
    } else {
      setNotificacionesRecientes(notificaciones.slice(0, 3));
    }
  }, []);

  useEffect(() => {
    console.log("este es totalNotificaciones: " + JSON.stringify(totalNotificaciones))
    
    
    if(totalNotificaciones?.length > 0) {
      let noLeidas = [];
      for(let i = 0; i < totalNotificaciones.length; i++) {
        noLeidas.push(totalNotificaciones[i].idCompra)
      }
      console.log(noLeidas);
      marcarNotificacionesResiduales(noLeidas)
    } 

    return () => {

    }
  }, [])

  return (
    <div className="dropdown-menu2 container-blanco">
      <div className="p-4 text-start">
        <span className="fw-bold fs-5">Notificaciones</span>
      </div>
      <hr />
      {notificacionesRecientes.length == 0 ? (
        <div className="p-4 text-start">
          <span>No tienes notificaciones recientes</span>
        </div>
      ) : (
        <div className="contenedor-notificaciones-mobile d-flex flex-column gap-2 px-4 ">
          {notificacionesRecientes.map((notificacion, index) => (
            <React.Fragment key={notificacion.idCompra}>
              {notificacion.puntosGanados > 0 && (
                <div
                  onClick={handleClick}
                  className={`contenedor_notificaciones d-flex align-items-center gap-2  ${
                    notificacion.vista == 0 ? "notificacion_no_Leida" : ""
                  }`}
                >
                  <div>
                    <img src={estrellaPuntos} alt="Ganaste puntos" style={{ width: "44px" }} />
                  </div>
                  <div className="text-start p-2">
                    <div className="d-flex justify-content-between">
                      <span className="texto-negro-sans fw-bold">Ganaste puntos</span>
                      <span className="notificacion-fecha">{formatearFecha(notificacion.fecha)}</span>
                    </div>
                    <p className="p-0 m-0">{`Tu compra te dio ${notificacion.puntosGanados} puntos, tienes en total ${notificacion.puntosTotales} puntos.`}</p>
                  </div>
                </div>
              )}

              {notificacion.productos.map((producto, productoIndex) => (
                <div
                  key={productoIndex}
                  onClick={handleClick}
                  className={`contenedor_notificaciones d-flex align-items-center gap-2 col-12 ${
                    notificacion.vista == 0 ? "notificacion_no_Leida" : ""
                  }`}
                >
                  <div>
                    <img
                      src={
                        producto.nombreProducto == "Mezcal Espadin"
                          ? EspadinImage
                          : producto.nombreProducto == "Mezcal Tobala" 
                          ? TobalaImage
                          : producto.nombreProducto == nombreProductoInventario1
                          ? imagenesProductosInventario.imagenProductoInventario1
                          : producto.nombreProducto == nombreProductoInventario2
                          ? imagenesProductosInventario.imagenProductoInventario2
                          : producto.nombreProducto == nombreProductoInventario3
                          ? imagenesProductosInventario.imagenProductoInventario3
                          : producto.nombreProducto == nombreProductoInventario4 
                          ? imagenesProductosInventario.imagenProductoInventario4
                          : bagIcon
                      }
                      alt={producto.nombreProducto}
                      style={{ width: "44px" }}
                    />
                  </div>
                  <div className="text-start w-100 p-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="texto-negro-sans fw-bold">Compraste</span>
                      <span className="notificacion-fecha">{formatearFecha(notificacion.fecha)}</span>
                    </div>
                    <p className="p-0 m-0">{`${producto.nombreProducto}`}</p>
                  </div>
                </div>
              ))}

              {notificacion.puntosGastados > 0 && (
                <div
                  onClick={handleClick}
                  className={`contenedor_notificaciones d-flex align-items-center gap-2 ${
                    notificacion.vista == 0 ? "notificacion_no_Leida" : ""
                  }`}
                >
                  <div>
                    <img src={estrellaPuntos} alt="Usaste tus puntos" style={{ width: "44px" }} />
                  </div>
                  <div className="text-start p-2">
                    <div className="d-flex justify-content-between">
                      <span className="texto-negro-sans fw-bold">Usaste tus puntos</span>
                      <span className="notificacion-fecha">{formatearFecha(notificacion.fecha)}</span>
                    </div>
                    <p className="p-0 m-0">{`Pagaste con ${notificacion.puntosGastados} puntos. Sigue comprando mezcal para seguir ganando puntos.`}</p>
                  </div>
                </div>
              )}

              {index < notificacionesRecientes.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </div>
      )}
      <hr />
      <div className="">
        <button className="boton-dorado" onClick={handleClick}>
          Ver mas
        </button>
      </div>
    </div>
  );
}

export default NotificacionesRapidas;
